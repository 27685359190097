import { getPredictions } from '@/api/basecamp.api';
import { showErrorMessage } from '@/shared/messages';
import type { IFetchPredictionsParams } from '@condo/domain';

function getInitialState() {
    return {
        predictions: [],
        total: 0,
    };
}

// initial state
const state = getInitialState();

const getters = {
    all(state) {
        return state.predictions;
    },
    count(state) {
        return state.total;
    },
    bestPrediction(state) {
        if (!state.predictions.length) {
            return;
        }
        return state.predictions.reduce((prev, next) => (prev.listedMarginNoOperation > next.listedMarginNoOperation ? prev : next), {});
    },
    // parameterized getter, honestly, idk whether it is a good practice or not
    getSprengnetterDataByType: state => type =>
        state.predictions.map(prediction => prediction.externalData?.find(item => item.source === 'Sprengnetter' && item.type === type)?.data),
};

const actions = {
    async getPredictions({ commit }, options: { estateId: number; params?: IFetchPredictionsParams }) {
        const { estateId, params } = options;

        return getPredictions(estateId, params)
            .then(({ predictions, total }) => {
                commit('setPredictions', { predictions, total });

                return predictions;
            })
            .catch(error => {
                commit('setPredictions', { predictions: [], total: 0 });
                showErrorMessage({ message: `Error during fetching predictions: ${error.message}` });
            });
    },
};

const mutations = {
    setPredictions(state, { predictions, total }: { predictions: []; total: number }) {
        state.predictions = predictions;
        state.total = total;
    },
};

export const predictionsStore = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
