import { EligibleEstatePurchasingStates, type IProcessRequests, LoanOfferType, PortfolioStatus } from '@condo/domain';

export enum FrontEndPortfolioEvents {
    DrawdownUpdated = 'DrawdownUpdated',
    PortfolioStatusChanged = 'PortfolioStatusChanged',
}

export const portfolioEvents = [FrontEndPortfolioEvents.DrawdownUpdated, FrontEndPortfolioEvents.PortfolioStatusChanged];

export const portfolioStatus = (status: PortfolioStatus): string => {
    switch (status) {
        case PortfolioStatus.Active:
            return 'is-clink';
        case PortfolioStatus.Draft:
            return 'is-link';
        case PortfolioStatus.Ready:
            return 'is-info';
        case PortfolioStatus.Tendering:
        case PortfolioStatus.Approved:
            return 'is-success-dark';

        case PortfolioStatus.Closed:
            return 'is-success';
        case PortfolioStatus.New:
            return 'is-grey';
        default:
            return 'is-grey';
    }
};

export const loanOfferTypeColorHelper = (loanOfferType: LoanOfferType) => {
    switch (loanOfferType) {
        case LoanOfferType.Incoming:
            return 'is-clink';
        case LoanOfferType.Outgoing:
            return 'is-green-dark';
        default:
            return 'is-grey';
    }
};

export const eligibleRequestParams: Partial<IProcessRequests['Querystring']> = {
    orderBy: 'process.processId',
    order: 'DESC',
    page: 1,
    size: 150,
    relations: ['estate', 'investment', 'estate.location', 'events'],
};

export const eligibleEstatesFilter = {
    isFinished: false,
    withoutPortfolio: true,
    processState: EligibleEstatePurchasingStates,
};

export interface IEligibleFilterObj {
    estate: null | number;
    street: null | string;
    quarter: string[];
    zipcode: string[];
    status: PortfolioStatus[];
    handoverDate: Date;
    prices: [number, number];
    financingNeed: [number, number];
}
