import {
    createRenovationInspection,
    fetchEstateDefects,
    fetchInspectionById,
    fetchInspectionQuestions,
    fetchRenovationInspections,
    getCatalogue,
    getConstructionPlan,
    getConstructionPlanPositions,
    getConstructionPlans,
    getConstructionPlansProgress,
    getXktData,
    processConstructionPlanEvent,
    processDefectEvent,
    sendConstructionPlanReport,
    sendRenovationInspectionReport,
    updateConstructionPlansProgress,
    updateRenovationInspection,
} from '@/api/basecamp.api';
import type { UiConstructionPosition } from '@/components/construction/entities/ui-construction-position';
import {
    type ConstructionCraft,
    type ConstructionPlanEvents,
    type ConstructionPlanSearchCriteria,
    type DefectType,
    type ICatalogueItem,
    type ICondoEvent,
    type ICondoFile,
    type IConstructionPlan,
    type IConstructionPlanReportParams,
    type IConstructionProgress,
    type IConstructionSiteReportParams,
    type IDefect,
    type IDefectEventRequest,
    type IInspectionQuestion,
    type IRenovationInspection,
    ReviewProcess,
} from '@condo/domain';
import type { ActionTree, GetterTree, MutationTree } from 'vuex';

export interface IRenovationStore {
    plans: IConstructionPlan[];
    currentPlan: IConstructionPlan;
    catalogue: ICatalogueItem[];
    catalogueTotalCount: number;
    milestones: IConstructionProgress[];
    defects: IDefect[];
    inspectionQuestions?: IInspectionQuestion[];
    inspections: IRenovationInspection[];
    currentInspection: IRenovationInspection;
    constructionPlanPositions: UiConstructionPosition[];
    xktData: Record<string, { xktFile: ICondoFile; metadataFile: ICondoFile }>;
    renovationProcessEvents: ICondoEvent[];
}

function getInitialState(): IRenovationStore {
    return {
        plans: [],
        currentPlan: {} as IConstructionPlan,
        catalogue: [] as ICatalogueItem[],
        catalogueTotalCount: 0 as number,
        defects: [],
        inspectionQuestions: [],
        milestones: [] as IConstructionProgress[],
        inspections: [],
        currentInspection: {} as IRenovationInspection,
        constructionPlanPositions: [],
        xktData: {},
        renovationProcessEvents: [],
    };
}

const state = getInitialState();

const getters: GetterTree<IRenovationStore, any> = {
    plans(state: IRenovationStore): IConstructionPlan[] {
        return state.plans.map(plan => ({ ...plan, reviewProcess: plan.reviewProcess ? new ReviewProcess(plan.reviewProcess) : undefined }));
    },
    getXktFilesForEstate: (state: IRenovationStore) => (estateId: number) => state.xktData[estateId],
    positions(state: IRenovationStore): UiConstructionPosition[] {
        return state.constructionPlanPositions;
    },
    milestones(state: IRenovationStore): IConstructionProgress[] {
        return state.milestones;
    },
    current(state: IRenovationStore): IConstructionPlan {
        return state.currentPlan;
    },
    catalogue(state: IRenovationStore): ICatalogueItem[] {
        return state.catalogue;
    },
    catalogueTotalCount(state: IRenovationStore): number {
        return state.catalogueTotalCount;
    },
    defects(state: IRenovationStore): IDefect[] {
        return state.defects;
    },
    planDraft: () => (planId: number) => {
        const draft = localStorage.getItem(`construction_plan_draft_${planId}`);
        if (draft) {
            return JSON.parse(draft);
        }
        return null;
    },
    getIfcViewSettings: () => viewId => {
        const draft = localStorage.getItem(`ifc_settings_${viewId}`);
        if (draft) {
            return JSON.parse(draft);
        }
        return null;
    },
    inspectionQuestions(state: IRenovationStore): IInspectionQuestion[] {
        return state.inspectionQuestions;
    },
    inspections(state: IRenovationStore): IRenovationInspection[] {
        return state.inspections;
    },
    currentInspection(state: IRenovationStore): IRenovationInspection {
        return state.currentInspection;
    },
    renovationProcessEvents(state: IRenovationStore): ICondoEvent[] {
        return state.renovationProcessEvents;
    },
};

const actions: ActionTree<IRenovationStore, any> = {
    async processDefectEvent({ commit }, { defectId, event, additionalData }: { defectId: number } & IDefectEventRequest) {
        return processDefectEvent(defectId, { event, additionalData }).then(defect => {
            commit('setDefect', defect);
        });
    },
    async processConstructionPlanEvent({ commit }, request: { constructionPlanId: number; event: ConstructionPlanEvents }) {
        return processConstructionPlanEvent(request.constructionPlanId, request.event).then(plan => {
            commit('setCurrentPlan', plan);
        });
    },
    async getPlans({ commit }, params: ConstructionPlanSearchCriteria) {
        return getConstructionPlans(params).then(plans => {
            commit('setPlans', plans);

            return plans;
        });
    },
    async sendConstructionPlanReport(_, report: IConstructionPlanReportParams): Promise<void> {
        return sendConstructionPlanReport(report);
    },
    async getMilestones({ commit }, params: { estateId: number }) {
        return getConstructionPlansProgress({ estateId: params.estateId }).then(milestones => {
            commit('setMilestones', milestones);
            return milestones;
        });
    },
    async getCurrentPlan({ commit }, params: { planId: number; withRelations?: boolean }) {
        return getConstructionPlan(params.planId, params.withRelations).then(plan => {
            commit('setCurrentPlan', plan);
            return plan;
        });
    },
    async getConstructionPlanPositions({ commit }, params: { planId: number }): Promise<UiConstructionPosition[]> {
        return getConstructionPlanPositions(params.planId).then(positions => {
            commit('setConstructionPositions', positions);
            return positions;
        });
    },
    async fetchCatalogue({ commit }, params) {
        return getCatalogue(params).then(data => {
            commit('setCatalogue', data.catalogueItems);
            commit('setCatalogueTotalCount', data.count);

            return data;
        });
    },
    async fetchDefects(
        { commit },
        { estateId, crafts, defectsLayerId, type }: { estateId: number; type?: DefectType; defectsLayerId?: number; crafts?: ConstructionCraft[] },
    ) {
        return fetchEstateDefects({ crafts, defectsLayerId, estateId, type }).then(defects => {
            commit('setDefects', defects);
            return defects;
        });
    },
    async fetchInspectionQuestions({ commit }, params?: Partial<IInspectionQuestion>) {
        return fetchInspectionQuestions(params).then(questions => {
            commit('setInspectionQuestions', questions);
            return questions;
        });
    },
    async createInspection({ commit }, inspection: Partial<IRenovationInspection>): Promise<IRenovationInspection> {
        return createRenovationInspection(inspection).then(inspection => {
            commit('addInspectionToList', inspection);
            return inspection;
        });
    },
    async updateInspection({ commit, state }, inspection: Partial<IRenovationInspection>): Promise<IRenovationInspection> {
        const { renovationInspectionId } = inspection;
        return updateRenovationInspection(renovationInspectionId, inspection).then(inspection => {
            commit(
                'setInspections',
                state.inspections.map(ins => {
                    if (ins.renovationInspectionId === renovationInspectionId) {
                        return Object.assign(ins, inspection);
                    }

                    return ins;
                }),
            );
            return inspection;
        });
    },
    async sendRenovationInspectionReport(store, report: IConstructionSiteReportParams): Promise<void> {
        return sendRenovationInspectionReport(report);
    },
    async updateMilestone({ commit }, stone: Partial<IConstructionProgress>): Promise<IConstructionProgress[]> {
        return updateConstructionPlansProgress(stone).then(milestones => {
            commit('setMilestones', milestones);
            return milestones;
        });
    },
    async getInspections({ commit }, { estateId, withRelations }: { estateId: number; withRelations?: boolean }): Promise<IRenovationInspection[]> {
        return fetchRenovationInspections(estateId, { withRelations }).then(inspections => {
            commit('setInspections', inspections);
            return inspections;
        });
    },
    async getInspectionById({ commit }, { inspectionId, relationOpts }: { inspectionId; relationOpts }): Promise<IRenovationInspection> {
        return fetchInspectionById(inspectionId, relationOpts).then(inspection => {
            commit('setCurrentInspection', inspection);
            return inspection;
        });
    },

    async fetchXktFiles({ commit }, estateId) {
        const data = await getXktData(estateId);
        commit('setXktData', { estateId, data });
        return data;
    },
};

const mutations: MutationTree<IRenovationStore> = {
    setXktData(state: IRenovationStore, { estateId, data }) {
        state.xktData = { ...state.xktData, [estateId]: data };
    },
    setConstructionPositions(state: IRenovationStore, positions: UiConstructionPosition[]) {
        state.constructionPlanPositions = positions;
    },
    setPlans(state: IRenovationStore, plans: IConstructionPlan[]) {
        state.plans = plans;
    },
    setMilestones(state: IRenovationStore, milestones: IConstructionProgress[]) {
        state.milestones = milestones;
    },

    setCurrentPlan(state: IRenovationStore, plan: IConstructionPlan) {
        state.currentPlan = plan;
    },
    setCatalogue(state: IRenovationStore, catalogue: ICatalogueItem[]) {
        state.catalogue = catalogue;
    },
    setCatalogueTotalCount(state: IRenovationStore, catalogueTotalCount: number) {
        state.catalogueTotalCount = catalogueTotalCount;
    },
    saveDraft(state: IRenovationStore, draft: { data: object; planId: number }) {
        localStorage.setItem(`construction_plan_draft_${draft.planId}`, JSON.stringify(draft.data));
    },
    removeDraft(state: IRenovationStore, planId) {
        localStorage.removeItem(`construction_plan_draft_${planId}`);
    },
    saveIfcViewSettings(state: IRenovationStore, { viewId, viewSettings }: { viewId: string; viewSettings: object }) {
        localStorage.setItem(`ifc_settings_${viewId}`, JSON.stringify(viewSettings));
    },
    removeSavedIfcView(state: IRenovationStore, { viewId }: { viewId: string }) {
        localStorage.removeItem(`ifc_settings_${viewId}`);
    },
    setDefects(state: IRenovationStore, defects: IDefect[]) {
        state.defects = defects;
    },
    setDefect(state: IRenovationStore, defect: IDefect) {
        let defectInserted = false;
        const nextDefects = state.defects
            .map(d => {
                if (d.defectId === defect.defectId) {
                    defectInserted = true;
                    return defect;
                }
                return d;
            })
            .concat(defectInserted ? [] : [defect]);

        state.defects = nextDefects;
    },
    setInspectionQuestions(state: IRenovationStore, questions: IInspectionQuestion[]) {
        state.inspectionQuestions = questions;
    },
    setInspections(state: IRenovationStore, inspections: IRenovationInspection[]) {
        state.inspections = inspections;
    },
    addInspectionToList(state: IRenovationStore, inspection: IRenovationInspection) {
        state.inspections.push(inspection);
    },
    setCurrentInspection(state: IRenovationStore, inspection: IRenovationInspection) {
        state.currentInspection = inspection;
    },
};

export const renovationStore = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
