import { deleteMeter, deleteMeterReading, fetchEstateMeters, saveEstateMeter, saveEstateReading } from '@/api/property-management.api';
import type { IEstateMeter, IMeterReading, tasksGrouped } from '@condo/domain';

export interface IPropertyManagementStore {
    estateMeters: IEstateMeter[];
    filter: typeof tasksGrouped;
}

function getInitialState(): IPropertyManagementStore {
    return {
        estateMeters: [],
        filter: {
            Estate: [],
            Tenant: [],
            SEV: [],
        },
    };
}

// initial state
const state = getInitialState();

const getters = {
    estateMeters(state: IPropertyManagementStore): IEstateMeter[] {
        return state.estateMeters;
    },
};

const actions = {
    async getEstateMeters({ commit }, options: { estateId: number }) {
        const { estateId } = options;

        return fetchEstateMeters(estateId).then((estateMeters: IEstateMeter[]) => {
            commit('setEstateMeters', estateMeters);
            return estateMeters;
        });
    },
    async saveEstateMeter({ dispatch }, estateMeter: IEstateMeter) {
        return saveEstateMeter(estateMeter).then(() => dispatch('getEstateMeters', { estateId: estateMeter.estateId }));
    },
    async saveEstateReading({ state }: { state: IPropertyManagementStore }, { reading, estateMeterId }: { reading: IMeterReading; estateMeterId: number }) {
        return saveEstateReading(estateMeterId, reading).then((dbReading: IMeterReading) => {
            const meter = state.estateMeters.find(m => m.estateMeterId === estateMeterId);
            if (reading.meterReadingId) {
                meter.readings[meter.readings.findIndex(r => r.meterReadingId === reading.meterReadingId)] = dbReading;
            } else {
                meter.readings.push(dbReading);
            }
        });
    },
    async deleteMeter({ state }, { estateMeterId }: { estateMeterId: number }) {
        state.estateMeters.splice(
            state.estateMeters.findIndex(r => r.estateMeterId === estateMeterId),
            1,
        );
        return deleteMeter(estateMeterId);
    },
    async deleteMeterReading({ state }, { estateMeterId, readingId }: { estateMeterId: number; readingId: number }) {
        const meter = state.estateMeters.find(m => m.estateMeterId === estateMeterId);
        meter.readings.splice(
            meter.readings.findIndex(r => r.meterReadingId === readingId),
            1,
        );
        return deleteMeterReading(estateMeterId, readingId);
    },
};

const mutations = {
    setEstateMeters(state: IPropertyManagementStore, estateMeters: IEstateMeter[]) {
        state.estateMeters = estateMeters;
    },
    setFilter(state: IPropertyManagementStore, filter) {
        state.filter = filter;
    },
};

export const propertyManagementStore = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
