import { type IdTokenResult, SAMLAuthProvider, type User, getAuth, signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth';

export const authManager = {
    token: '',

    async logIn() {
        // @ts-ignore
        const provider = new SAMLAuthProvider('saml.onelogin');
        return signInWithPopup(getAuth(), provider);
    },

    async logOut() {
        return getAuth().signOut();
    },

    async logInWithEmailAndPassword(email, password) {
        return signInWithEmailAndPassword(getAuth(), email, password);
    },

    async getToken() {
        if (!getAuth().currentUser) {
            return Promise.reject('User Not Authenticated');
        }

        this.token = await getAuth().currentUser.getIdToken();

        return this.token;
    },

    getUserDataForPasswordProvider(idResult: IdTokenResult) {
        return {
            userId: idResult.claims.user_id,
            firstname: 'Test',
            lastname: 'User',
            email: idResult.claims.email,
            activeRole: 'test-user',
        };
    },

    getUserDataForSAMLProvider(v: IdTokenResult) {
        //Firebase return everything is some strange format somehow and umlauts not parsed
        // Most probably it is due to encoding on firebase side which doesn't have configurations
        // this piece of code should be fault-tolerant or whole page will not load
        let firstname = v.claims.firebase['User.FirstName'] ?? v.claims.firebase['sign_in_attributes']?.['User.FirstName'];
        let lastname = v.claims.firebase['User.LastName'] ?? v.claims.firebase['sign_in_attributes']?.['User.LastName'];
        try {
            firstname = decodeURIComponent(escape(firstname));
            lastname = decodeURIComponent(escape(lastname));
        } catch (e) {
            /** do nothing */
        }
        return {
            userId: v.claims.user_id,
            firstname,
            lastname,
            email: v.claims.firebase['User.email'] ?? v.claims.firebase['sign_in_attributes']?.['User.email'],
            activeRole: v.claims.activeRole,
        };
    },

    async fetchUserData(user: User): Promise<object> {
        if (user) {
            return user.getIdTokenResult().then(v => {
                if (v.claims.firebase.sign_in_provider === 'password') {
                    return this.getUserDataForPasswordProvider(v);
                }
                return this.getUserDataForSAMLProvider(v);
            });
        }
        return null;
    },

    /*
        Identity provider data available in result.additionalUserInfo.profile,
        or from the user's ID token obtained via result.user.getIdToken()
        as an object in the firebase.sign_in_attributes custom claim
        This is also available via result.user.getIdTokenResult()
        idTokenResult.claims.firebase.sign_in_attributes.
     */
    async getUserData() {
        // Firebase don't actually have this API, so we use "subscribe once". Callback will be triggered anyway, it is just user might be null
        return new Promise((resolve, reject) => {
            const unsubscribe = getAuth().onAuthStateChanged(user => {
                unsubscribe();
                if (user) {
                    authManager.fetchUserData(user).then(data => resolve(data));
                } else {
                    resolve(user);
                }
            }, reject);
        });
    },
};
