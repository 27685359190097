import type {
    IGetMFHPortfolioProcessReq,
    IMFHPortfolioProcess,
    IStartMFHPortfolioProcessParams,
    IUpdateMFHPortfolioProcessReq,
    MFHPortfolioProcessType,
} from '@condo/domain';
import { getClient } from '../api-client';

export const getMFHPortfolioProcess = async ({ processType, processId }: IGetMFHPortfolioProcessReq.Params): Promise<IMFHPortfolioProcess> =>
    getClient('basecamp')
        .get(`/mfh-portfolios/processes/${processType}/${processId}`)
        .then(response => response.data.process);

export const updateMfhPortfolioProcess = async (
    { mfhPortfolioId, processType, processId }: IUpdateMFHPortfolioProcessReq.Params,
    data: IUpdateMFHPortfolioProcessReq.Body,
): Promise<IMFHPortfolioProcess> =>
    getClient('basecamp')
        .patch(`/mfh-portfolios/${mfhPortfolioId}/processes/${processType}/${processId}`, data)
        .then(response => response.data.process);

export const startMfhPortfolioProcess = async ({ mfhPortfolioId, processType }: Partial<IStartMFHPortfolioProcessParams>): Promise<IMFHPortfolioProcess> =>
    getClient('basecamp')
        .post(`/mfh-portfolios/${mfhPortfolioId}/processes/${processType}`)
        .then(response => response.data.process);

export const postProcessMFHPortfolioEvent = async ({
    mfhPortfolioId,
    processType,
    processId,
    event,
    eventDate,
}: {
    mfhPortfolioId: number;
    processType: MFHPortfolioProcessType;
    processId: number;
    event: string;
    eventDate: Date;
}): Promise<{}> => getClient('basecamp').post(`/mfh-portfolios/${mfhPortfolioId}/processes/${processType}/${processId}/event`, { event, eventDate });
