import { getClient } from '@/api/api-client';
import type { IMFHCalendarEvent, IMFHCalendarEventCreateParams, IMFHCalendarEventSearchParams } from '@condo/domain';

export const mfhFetchCalendarEvents = async (params?: IMFHCalendarEventSearchParams): Promise<IMFHCalendarEvent[]> =>
    getClient('basecamp')
        .get('/mfh/calendar-event', { params })
        .then(res => res.data);

export const mfhCreateCalendarEvent = async (body: IMFHCalendarEventCreateParams): Promise<IMFHCalendarEvent> =>
    getClient('basecamp')
        .post('/mfh/calendar-event', body)
        .then(res => res.data);

export const mfhUpdateCalendarEvent = async (body: IMFHCalendarEvent): Promise<IMFHCalendarEvent> =>
    getClient('basecamp')
        .put(`/mfh/calendar-event/${body.calendarEventId}`, body)
        .then(res => res.data);

export const mfhDeleteCalendarEvent = async (calendarEventId): Promise<boolean> =>
    getClient('basecamp')
        .delete(`/mfh/calendar-event/${calendarEventId}`)
        .then(res => res.data);
