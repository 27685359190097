import { contactsMerge, getContacts, getOneContact, getUserContact, saveContact } from '@/api/contacts.api';
import type { ContactExpandOpts, IContactPerson, IContactPersonRelation, IFetchContactParams } from '@condo/domain';
import type { ActionTree, GetterTree, MutationTree } from 'vuex';

export interface IContactStore {
    oneContact: IContactPerson;
    contacts: IContactPerson[];
    total: number;
    contactLoading: boolean;
    filter: {
        orderBy: string;
        order: string;
        page: number;
        size: number;
    };
}

function getInitialState() {
    return {
        oneContact: null,
        contacts: [],
        total: 0,
        contactLoading: false,
        filter: {},
    } as IContactStore;
}

// initial state
const state = getInitialState();

const getters: GetterTree<IContactStore, any> = {
    oneContact(state) {
        return state.oneContact;
    },
    contacts(state: IContactStore) {
        return state.contacts;
    },
    total(state: IContactStore) {
        return state.total;
    },
    filter(state: IContactStore) {
        return state.filter;
    },
    loadingState(state: IContactStore) {
        return state.contactLoading;
    },
};

const actions: ActionTree<IContactStore, any> = {
    async saveContact({ commit }, { contact, relation }: { contact: Partial<IContactPerson>; relation?: Partial<IContactPersonRelation> }) {
        return saveContact(contact, relation).then(updatedContact => {
            const mutation = contact.contactId ? 'setOneContact' : 'addContact';
            commit(mutation, updatedContact);
            return updatedContact;
        });
    },

    async mergeContacts({ commit }, { contact, contacts }: { contact: IContactPerson; contacts: IContactPerson[] }) {
        commit('setLoading', true);
        return contactsMerge(contact, contacts)
            .then(updatedContact => {
                commit('setOneContact', updatedContact);
                const idStack = contacts.reduce((acc, curr): number[] => {
                    if (contact.contactId !== curr.contactId) {
                        acc.push(curr.contactId);
                    }
                    return acc;
                }, [] as number[]);
                commit('removeContacts', idStack);
                return contact;
            })
            .finally(() => commit('setLoading', false));
    },
    async getOneContact({ commit }, { contactId, userId, expand }: { contactId?: string & number; userId?: string; expand?: ContactExpandOpts }) {
        commit('setLoading', true);
        if (userId || contactId === 'me') {
            return getUserContact(userId || contactId).then(data => {
                commit('setOneContact', data.contact);
                commit('setLoading', false);
                return data.contact;
            });
        }

        return getOneContact(contactId, expand).then(data => {
            commit('setOneContact', data.contact);
            commit('setLoading', false);
            return data.contact;
        });
    },

    async getContacts({ commit }, params: IFetchContactParams) {
        commit('setLoading', true);
        return getContacts(params)
            .then(response => {
                const { contacts, total } = response;
                commit('setContacts', { contacts, total });
            })
            .finally(() => commit('setLoading', false));
    },
};

const mutations: MutationTree<IContactStore> = {
    removeContact(state: IContactStore, contact: IContactPerson) {
        state.contacts = state.contacts.filter(c => c.contactId !== contact.contactId);
        state.total--;
    },
    removeContacts(state: IContactStore, idStack: number[]) {
        state.contacts = state.contacts.filter(c => !idStack.includes(c.contactId));
        state.total -= idStack.length;
    },
    addContact(state: IContactStore, contact: IContactPerson) {
        state.contacts.push(contact);
        state.total++;
    },
    upsertContact(state: IContactStore, contact: IContactPerson) {
        let inserted = false;
        state.contacts = state.contacts
            .map(c => {
                if (c.contactId === contact.contactId) {
                    inserted = true;
                    return contact;
                }
                return c;
            })
            .concat(inserted ? [] : [contact]);
        state.total = inserted ? state.total : state.total + 1;
    },
    setContacts(state: IContactStore, { contacts, total }: { contacts: IContactPerson[]; total: number }) {
        state.contacts = contacts;
        state.total = total;
    },

    setOneContact(state: IContactStore, contact: IContactPerson) {
        state.oneContact = contact;
    },

    setLoading(state: IContactStore, loadingState: boolean) {
        state.contactLoading = loadingState;
    },
};

export const contactsStore = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
