import type {
    IDeleteMediaFilesQuery,
    IDocUploadResult,
    IFetchMediaFileParams,
    IGetMediaFilesResponse,
    ILinkMediaFileParams,
    IMediaFile,
    ISendDocumentsByEmailParams,
    IUpdateMediaFilesTagsQuery,
    MediaFileCategory,
} from '@condo/domain';
import { openDownloadLink, showErrorMessage } from '../shared';
import { getClient } from './api-client';

export const getMediaFiles = async (params: IFetchMediaFileParams): Promise<IGetMediaFilesResponse> =>
    getClient('basecamp')
        .get('/media-files', { params })
        .then(response => response.data);

export const updateMediaFilesTags = async (data: IUpdateMediaFilesTagsQuery): Promise<void> =>
    getClient('basecamp')
        .put('/media-files/tags', data)
        .then(response => response.data);

export const updateMediaFilesCategory = async (data: { mediaFileIds: number[]; category: MediaFileCategory }): Promise<void> =>
    getClient('basecamp')
        .put('/media-files/category', data)
        .then(response => response.data);

export const updateMediaFile = async ({ mediaId, data }: { mediaId: number; data: Partial<IMediaFile> }): Promise<void> =>
    getClient('basecamp')
        .patch(`/media-files/${mediaId}`, { ...data })
        .then(response => response.data);

export const deleteMediaFiles = async (query: IDeleteMediaFilesQuery): Promise<void> =>
    getClient('basecamp')
        .delete('/media-files', { params: query })
        .then(response => response.data);

export const uploadMediaFile = async (formdata: FormData, params: {}, url = '/media-files'): Promise<IDocUploadResult<IMediaFile>[]> =>
    getClient('basecamp')
        .post(url, formdata, { params, headers: { 'Content-Type': 'multipart/form-data' } })
        .then(response => response.data);

export const linkMediaFile = async (fileData: ILinkMediaFileParams): Promise<IDocUploadResult<IMediaFile>[]> =>
    getClient('basecamp')
        .post('/media-files/link', fileData)
        .then(response => [response.data]);

export const downloadSelectionAsPdf = async ({
    fileName,
    mediaFileIds,
    estateId,
}: {
    fileName: string;
    mediaFileIds: number[];
    estateId: number;
}): Promise<void> =>
    getClient('basecamp')
        .get('/media-files', { params: { query: { mediaFileIds, estateId } }, responseType: 'blob', headers: { Accept: 'application/pdf' } })
        .then(({ data }) => {
            if (data.size === 0) {
                showErrorMessage({ message: 'Unknown error while generating media-pdf, please try again later' });
                return;
            }

            const url = window.URL.createObjectURL(new Blob([data]));
            openDownloadLink(url, fileName);
        });

export const sendDocumentsByEmail = async (payload: ISendDocumentsByEmailParams): Promise<void> => {
    await getClient('basecamp').post('/documents/email', payload);
};
