import type { ICreateJobParams } from '@/store/modules/jobs.store';
import type { IFindJobsParams, IJobDTO, IJobExecutionOpts } from '@condo/domain';
import { getClient } from './api-client';

export const cancelJob = async (jobId: string): Promise<void> => getClient('basecamp').delete(`/jobs/${jobId}`);

export const findScheduledJobs = async (): Promise<void> =>
    getClient('basecamp')
        .get('/scheduled-jobs')
        .then(response => response.data);

export const findUserJobs = async (params?: IFindJobsParams): Promise<IJobDTO[]> =>
    getClient('basecamp')
        .get('/user/jobs', { params })
        .then(response => response.data);

export const findJobs = async (params?: IFindJobsParams): Promise<IJobDTO[]> =>
    getClient('basecamp')
        .get('/jobs', { params })
        .then(response => response.data);

export const createJob = async (jobName: string, params: Record<string, any>, meta: Record<string, any>, opts?: IJobExecutionOpts): Promise<IJobDTO> =>
    getClient('basecamp')
        .post('/jobs', { jobName, meta, params, opts })
        .then(response => response.data);

export const createDocumentJob = async (params: ICreateJobParams): Promise<IJobDTO> =>
    getClient('basecamp')
        .post('/documents/jobs', params)
        .then(response => response.data.job);

export const clearJobs = async (userId?: string): Promise<void> => getClient('basecamp').delete('/user/jobs', { params: { userId } });
