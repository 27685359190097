import type { IMFHBuildingProcess, IStartMFHBuildingProcessParams, MFHBuildingProcessType } from '@condo/domain';
import { getClient } from '../api-client';

export const getMFHBuildingProcess = async ({ processType, processId }: IGetMFHBuildingProcessReq.Params): Promise<IMFHBuildingProcess> =>
    getClient('basecamp')
        .get(`/mfh-buildings/processes/${processType}/${processId}`)
        .then(response => response.data.process);

export const startMfhBuildingProcess = async ({ mfhBuildingId, processType }: Partial<IStartMFHBuildingProcessParams>): Promise<IMFHBuildingProcess> =>
    getClient('basecamp')
        .post(`/mfh-buildings/${mfhBuildingId}/processes/${processType}`)
        .then(response => response.data.process);

export const postProcessMFHBuildingEvent = async ({
    mfhBuildingId,
    processType,
    processId,
    event,
    eventDate,
}: {
    mfhBuildingId: number;
    processType: MFHBuildingProcessType;
    processId: number;
    event: string;
    eventDate: Date;
}): Promise<{}> => getClient('basecamp').post(`/mfh-buildings/${mfhBuildingId}/processes/${processType}/${processId}/event`, { event, eventDate });
