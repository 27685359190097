import type { CellClassParams, ColDef, RowNode, ValueFormatterParams, ValueGetterParams } from '@ag-grid-community/core';
import { CCSRouteMap, type CompanyOrContactRelation, type ICompany, type IContactPerson } from '@condo/domain';
import type { VNode } from 'vue';
import type { Commit } from 'vuex';

export interface IFormState<TVal> {
    values: TVal | null;
    dirty: boolean;
    invalid: boolean;
}

export interface IActionParams<TState> {
    state: TState;
    commit: Commit;
}

export interface IGridNode<T> extends RowNode {
    data: T;
}

export interface IGridGetParams<T> extends ValueGetterParams {
    node: IGridNode<T>;
    data: T;
}
export interface IGridFormatterParams<T> extends ValueFormatterParams {
    node: IGridNode<T>;
    data: T;
}

export interface IGridCellClassParams<T> extends CellClassParams {
    node: IGridNode<T>;
    data: T;
}

export interface IBInput {
    checkHtml5Validity: () => boolean;
}

export interface IDefaultParams {
    processId?: string | number;
    estateId?: string | number;
    startDate?: string | number;
}

export interface IMenuItem {
    to: {
        name: string;
        params?: IDefaultParams | Record<string, any>;
    };
    key?: string;
    label?: string;
    icon?: string;
    extra?: string | (() => VNode);
    pack?: string;
    component?: any;
}

export interface IMenuList {
    groupName: string;
    items: IMenuItem[];
}

export interface IGridColDef<T> extends ColDef {
    valueGetter?: ((params: IGridGetParams<T>) => any) | string;
    cellClass?: ((cellClassParams: IGridCellClassParams<T>) => string | string[]) | string;
    processType?: string;
    widthRange?: [number, number];
    //children?: IGridColDef<T>[];
}

export interface ICockpitConfig {
    SERVICE_NAME: string;
    ENVIRONMENT_NAME: string;
    S_HERE_CACHE_URL: string;
    S_RAAS_URL: string;
    S_BASECAMP_URL: string;
    S_BASECAMP_MAINT_URL: string;
    S_SOURCING_STORAGE_URL: string;
    S_DISCOVERER_URL: string;
    S_SALES_PORTAL_URL: string;
    S_SALES_PORTAL_API_URL: string;
    S_LISTING_PERSISTER_URL: string;
    S_EXTERNAL_API_URL: string;
    S_PROPX_URL: string;

    HERE_CACHE_KEY: string;
    HOUSEHOLD_VERSION: string;
    F_USE_EMAIL_PASS_AUTH: boolean;
    F_RELOAD_JOBS: boolean;
    F_GPT_IMPORTS: boolean;
    DEFAULT_ROUTE: string;
    EMAIL_DOCUMENT_SENDING_ADDRESSES: string[];
    IMAGE_RESIZER_MAX_SIZE: number;

    LAUNCH_NOTES_ENABLED: boolean;
    LAUNCH_NOTES_TOKEN: string;
    LAUNCH_NOTES_PROJECT: string;

    SENTRY_ENABLED: boolean;
    SENTRY_DEBUG: boolean;
    SENTRY_DSN: string;

    GLEAP_ENABLED: boolean;
    GLEAP_API_KEY: string;

    GTM_ID: string;
    GTM_ENABLED: boolean;

    FBASE_API_KEY: string;
    FBASE_AUTH_DOMAIN: string;
    FBASE_PROJECT_ID?: string;

    ID_COMPANY_PEAKSIDE_FRA: number;

    //ONLY FOR LOCAL .env
    DEBUG_IS_EXTERNAL_USER?: boolean;

    F_JOBS_USE_SUPABASE: boolean;
    SUPABASE_API_KEY: string;
    SUPABASE_PROJECT_URL: string;
}

export enum CondoUiEvents {
    Logout = 'Logout',
}

export const RentingListTypes = {
    Renting: CCSRouteMap.renting.name,
    FirstNormalRenting: CCSRouteMap.firstNormalRenting.name,
    SharedRenting: CCSRouteMap.sharedRenting.name,
    FurnishedRenting: CCSRouteMap.furnishedRenting.name,
    AdditionalNormalRenting: CCSRouteMap.additionalNormalRenting.name,
};

export interface IContactWithRelation {
    company?: ICompany;
    contactPerson?: IContactPerson;
    relationName: CompanyOrContactRelation;
}
