import { fetchProcesses } from '@/api/basecamp.api';
import {
    acceptLoanOffer,
    deleteLoanOffer,
    fetchAllFinancingBanks,
    fetchOnePortfolio,
    fetchPortfolios,
    findPortfolioEstates,
    generateGDrive,
    generateInspectionSheet,
    getLoanOffer,
    processPortfolioEvent,
    saveLoanOffer,
    savePortfolio,
    searchPortfolios,
} from '@/api/finance.api';
import {
    type IAcceptOfferData,
    type ICreateUpdatePortfolioReq,
    type IEstate,
    type IFinancingBank,
    type IPortfolio,
    type IPortfolioEstatesQuery,
    type IProcess,
    type ISaveOfferParams,
    type IUpdatePortfolioStatusParams,
    type LoanOffer,
    type Portfolio,
    PortfolioStatus,
} from '@condo/domain';
import type { ActionTree, GetterTree, Module, MutationTree } from 'vuex';

export interface IPortfolioStore {
    eligibleEstatesProcesses: IProcess[]; // estates eligible for creating a portfolio
    portfolioEstates: IEstate[]; // selected estates for a given portfolio
    totalEligibleEstates: number;
    portfolios: Portfolio[];
    portfolio: Portfolio | null;
    financingBanks: IFinancingBank[];
}

export interface IPortfolioEntitiesDeleteParams {
    portfolioId: number;
    loanOfferId: number;
}

export type PortfolioPartialUpdateObject = {
    [key in keyof Partial<IPortfolio>]: IPortfolio[key];
};

const getters: GetterTree<IPortfolioStore, any> = {
    portfolioEstates(state: IPortfolioStore): IEstate[] {
        return state.portfolioEstates;
    },
    eligibleEstatesProcesses(state: IPortfolioStore): IProcess[] {
        return state.eligibleEstatesProcesses;
    },
    totalEligibleEstates(state: IPortfolioStore): number {
        return state.totalEligibleEstates;
    },
    portfolios(state: IPortfolioStore): Portfolio[] {
        return state.portfolios;
    },
    portfolio(state: IPortfolioStore): Portfolio {
        return state.portfolio;
    },
    isReviewProcessEnabled(state: IPortfolioStore): boolean {
        return [PortfolioStatus.Ready, PortfolioStatus.Review].includes(state.portfolio.status);
    },
    financingBanks(state: IPortfolioStore): IFinancingBank[] {
        return state.financingBanks;
    },
};

const actions: ActionTree<IPortfolioStore, any> = {
    async findPortfolioEstates({ commit }, params: IPortfolioEstatesQuery) {
        return findPortfolioEstates(params).then(estates => {
            commit('setPortfolioEstates', estates);
            return estates;
        });
    },
    async fetchEligibleEstatesProcesses({ commit }, params) {
        return fetchProcesses({ ...params }, true).then(({ processes, total }) => {
            commit('setEligibleEstatesProcesses', processes);
            commit('setTotalEligibleEstates', total);
            return processes;
        });
    },

    async fetchPortfolios({ commit }, params: Partial<IPortfolio>): Promise<Portfolio[]> {
        return fetchPortfolios(params).then(portfolios => {
            commit('setPortfolios', portfolios);
            return portfolios;
        });
    },

    async searchPortfolios(_, params: Partial<IPortfolio>): Promise<Pick<IPortfolio, 'shortUuid' | 'portfolioId'>[]> {
        return searchPortfolios(params);
    },

    async fetchOnePortfolio({ commit }, portfolioId: number): Promise<Portfolio> {
        return fetchOnePortfolio(portfolioId).then(portfolio => {
            commit('setPortfolio', portfolio);
            return portfolio;
        });
    },

    async fetchFinancingBanks({ commit, state }, force = false): Promise<IFinancingBank[]> {
        if (state.financingBanks.length && !force) {
            return state.financingBanks;
        }
        return fetchAllFinancingBanks().then(banks => {
            commit('setFinancingBanks', banks);
            return banks;
        });
    },

    async savePortfolio(state, { request, portfolioId }: { request: ICreateUpdatePortfolioReq; portfolioId?: number }): Promise<Portfolio> {
        return savePortfolio(request, portfolioId);
    },

    async saveLoanOffer({ commit, getters }, params: ISaveOfferParams): Promise<LoanOffer> {
        const portfolioId = getters.portfolio.portfolioId;
        return saveLoanOffer(portfolioId, params.loanOfferId ?? null, {
            seniorDebtInterest: params.seniorDebtInterest,
            amortizationType: params.amortizationType,
            equityRatio: params.equityRatio,
            seniorDebtProcessingFee: params.seniorDebtProcessingFee,
            documentId: params.documentId,
            loanOfferStatus: params.loanOfferStatus,
            receivedAt: params.receivedAt,
            sentAt: params.sentAt,
            loanOfferType: params.loanOfferType,
            timeOnBooks: params.timeOnBooks,
            portfolioId,
            financingBankId: params.financingBankId,
        })
            .then(loanOffer => getLoanOffer(portfolioId, loanOffer.loanOfferId))
            .then(loanOffer => {
                if (params.loanOfferId) {
                    commit('updateLoanOffer', loanOffer);
                } else {
                    commit('addLoanOffer', loanOffer);
                }
                return loanOffer;
            });
    },
    async acceptLoanOffer({ getters, dispatch }, { request, loanOfferId }: { request: IAcceptOfferData; loanOfferId: number }) {
        const portfolioId = getters.portfolio.portfolioId;
        return acceptLoanOffer(portfolioId, loanOfferId, request).then(() => dispatch('fetchOnePortfolio', portfolioId));
    },

    async deleteOffer({ commit, getters }, { loanOfferId }: { loanOfferId: number }) {
        return deleteLoanOffer(getters.portfolio.portfolioId, loanOfferId).then(() => commit('deleteOffer', { loanOfferId }));
    },
    async processPortfolioEvent({ commit, getters: { portfolio } }, { event, portfolioId }: IUpdatePortfolioStatusParams & { portfolioId?: number }) {
        commit('setPortfolio', await processPortfolioEvent(portfolioId ?? portfolio.portfolioId, { event }));
    },
    async generateGDrive({ commit }, { portfolioId, force }: { portfolioId: number; force?: boolean }): Promise<string> {
        return generateGDrive(portfolioId, force).then(documentsLink => {
            commit('updatePortfolioField', { documentsLink });
            return documentsLink;
        });
    },
    async generateInspectionSheet({ commit }, { portfolioId }: { portfolioId: number; force?: boolean }): Promise<string> {
        return generateInspectionSheet(portfolioId).then(inspectionSheetLink => {
            commit('updatePortfolioField', { inspectionSheetLink });
            return inspectionSheetLink;
        });
    },
};

const mutations: MutationTree<IPortfolioStore> = {
    setPortfolioEstates(state: IPortfolioStore, portfolioEstates: IEstate[]) {
        state.portfolioEstates = portfolioEstates;
    },
    setEligibleEstatesProcesses(state: IPortfolioStore, eligibleEstatesProcesses: IProcess[]) {
        state.eligibleEstatesProcesses = eligibleEstatesProcesses;
    },
    setTotalEligibleEstates(state: IPortfolioStore, totalEligibleEstates: number) {
        state.totalEligibleEstates = totalEligibleEstates;
    },
    setPortfolios(state: IPortfolioStore, portfolios: Portfolio[]) {
        state.portfolios = portfolios;
    },
    setPortfolio(state: IPortfolioStore, portfolio: Portfolio) {
        state.portfolio = portfolio;
    },
    setFinancingBanks(state: IPortfolioStore, financingBanks: IFinancingBank[]) {
        state.financingBanks = financingBanks;
    },
    updateLoanOffer(state: IPortfolioStore, loanOffer: LoanOffer) {
        state.portfolio.offers.splice(state.portfolio.findOfferIndexById(loanOffer.loanOfferId), 1, loanOffer);
    },
    addLoanOffer(state: IPortfolioStore, loanOffer: LoanOffer) {
        state.portfolio.offers.push(loanOffer);
    },
    deleteOffer(state: IPortfolioStore, { loanOfferId }) {
        state.portfolio.removeOffer(loanOfferId);
    },
    processPortfolioEvent(state: IPortfolioStore, status: PortfolioStatus) {
        state.portfolio.status = status;
    },
    updatePortfolioField(state: IPortfolioStore, partialUpdate: PortfolioPartialUpdateObject) {
        Object.entries(partialUpdate).forEach(([field, val]) => {
            state.portfolio[field] = val;
        });
    },
};

export const portfolioStore: Module<IPortfolioStore, any> = {
    namespaced: true,
    state: {
        eligibleEstatesProcesses: [],
        portfolioEstates: [],
        totalEligibleEstates: 0,
        portfolios: [],
        portfolio: null,
        financingBanks: [],
    },
    getters,
    actions,
    mutations,
};
