import type { ICockpitStore } from '@/store';
import type { RouteRecordRaw } from 'vue-router';

import { getStrippedCCSRoutes } from '@condo/domain';
import type { Store } from 'vuex';

const CCSRouteMap = getStrippedCCSRoutes();

export const createPortfolioRoutes = (store: Store<ICockpitStore>): RouteRecordRaw => ({
    ...CCSRouteMap['one.portfolio'],
    component: () => import('@/components/portfolio/PortfolioDetails.vue'),
    props: route => ({ portfolioId: +route.params.portfolioId }),
    redirect: params => `${params.fullPath}/${CCSRouteMap['portfolio.documents'].path}`,
    async beforeEnter(to, from, next) {
        await store.dispatch('portfolio/fetchOnePortfolio', +to.params.portfolioId);
        next();
    },
    children: [
        {
            ...CCSRouteMap['portfolio.documents'],
            components: {
                default: () => import('@/components/portfolio/documents/PortfolioDocuments.vue'),
            },
            props: true,
        },
        {
            ...CCSRouteMap['portfolio.tendering'],
            components: {
                default: () => import('@/components/portfolio/tendering/PortfolioTendering.vue'),
            },
            props: true,
        },
        {
            ...CCSRouteMap['portfolio.drawdowns'],
            components: {
                default: () => import('@/components/portfolio/PortfolioDrawdownSection.vue'),
            },
            props: true,
        },
        {
            ...CCSRouteMap['portfolio.loan'],
            components: {
                default: () => import('@/components/portfolio/PortfolioLoanSection.vue'),
            },
            props: true,
        },
        {
            ...CCSRouteMap['portfolio.tasks'],
            components: {
                default: () => import('@/components/ComingSoon.vue'),
            },
            props: true,
        },
        {
            ...CCSRouteMap['portfolio.history'],
            components: {
                default: () => import('@/components/portfolio/PortfolioHistory.vue'),
            },
            props: true,
        },
    ],
});
