import {
    ConstructionPlanState,
    ConstructionPosition,
    type ICatalogueItem,
    type IConstructionPlan,
    type IConstructionPosition,
    type IEstateElement,
    PositionEditType,
    type UnitName,
} from '@condo/domain';
import { isNil as _isNil } from 'lodash-es';
import { v4 as uuid4 } from 'uuid';

export interface IUiConstructionPosition extends IConstructionPosition {
    changed?: boolean;
    roomId: number;
    roomName: string;
    room: IEstateElement;

    estateElementName: string;

    linkedToId: number;

    positionName: string;
    unitName: UnitName;

    editable?: boolean;
    dependantRow?: boolean;

    disabled?: boolean;
    selected?: boolean;

    hasOptionalDeps?: boolean;

    optionalDeps: IUiConstructionPosition[];
    mandatoryDeps: IUiConstructionPosition[];
    readonly?: boolean;
}

export class UiConstructionPosition extends ConstructionPosition implements IUiConstructionPosition {
    changed = false;

    roomId!: number;
    roomName: string;
    room: IEstateElement;

    /*To Present normally*/
    estateElementName!: string;

    /*In DB links stored as uuids, we have to present them as ID*/
    linkedToId!: number;

    /*Display position for Grid - either entered or combined from code and name*/
    positionName!: string;
    unitName: UnitName;

    editable?: boolean;
    /*Dependant rows are highlighted*/
    dependantRow?: boolean;

    /*Mobile Construction Rows*/
    disabled?: boolean;
    /*Mobile Plan Field*/
    selected?: boolean;

    hasOptionalDeps?: boolean;

    optionalDeps: UiConstructionPosition[] = [];
    mandatoryDeps: UiConstructionPosition[] = [];

    readonly = false;
    checkable?: boolean;

    constructor(props: IUiConstructionPosition) {
        super(props);
        this.updateData(props);

        this.selected = !this.isDeleted || (this.positionId && !this.isDeleted && _isNil(props.selected));
    }

    static createBlank(constructionPlan: IConstructionPlan, catalogueItem: ICatalogueItem, props: Partial<IUiConstructionPosition>): UiConstructionPosition {
        return new UiConstructionPosition({
            uuid: uuid4(),
            constructionPlanId: constructionPlan.constructionPlanId,
            catalogueItemId: catalogueItem.catalogueItemId,
            catalogItem: catalogueItem,
            unitName: catalogueItem.unitName,
            craft: catalogueItem.craft,
            catalogueCode: catalogueItem.catalogueCode,
            editType: constructionPlan.state === ConstructionPlanState.Approved ? PositionEditType.Add : null,
            changed: false,
            ...props,
        } as IUiConstructionPosition);
    }

    updateData(props: Partial<IUiConstructionPosition>) {
        Object.assign(this, props);
        this.definePositionName();

        this.room = this.estateElement?.parent;
        this.roomName = this.estateElement?.parent?.name;
        this.roomId = this.estateElement?.parent?.estateElementId;
        this.estateElementName = this.estateElement?.name;
        this.editable = this.catalogItem?.editable;
        this.dependantRow = !!this.linkedTo;
        this.calculateTotalPrice();
        return this;
    }

    definePositionName(val?: string) {
        if (val) {
            this.positionTitle = val;
        }
        this.positionName = this.positionTitle ? `${this.catalogueCode} ${this.positionTitle}` : `${this.catalogueCode} ${this.catalogItem?.itemName}`;
    }

    setPricePerUnit(val: number) {
        this.pricePerUnit = val;
        this.calculateTotalPrice();
        return this;
    }

    setUnitNumber(val: number) {
        this.unitNumber = val;
        this.calculateTotalPrice();
        return this;
    }

    setChanged(val: boolean): this {
        this.changed = val;
        return this;
    }

    copy(): UiConstructionPosition {
        return new UiConstructionPosition({ ...this, optionalDeps: [], mandatoryDeps: [] });
    }

    toRequest(): IConstructionPosition {
        return {
            ...this,
            catalogItem: undefined,
            changed: undefined,
            roomId: undefined,
            roomName: undefined,
            estateElementName: undefined,
            linkedToId: undefined,
            positionName: undefined,
            editable: undefined,
            createdAt: undefined,
            updatedAt: undefined,
        };
    }
}
