import { createRule, deleteRule, getRules, updateRule } from '@/api/rules.api';
import { type IRule, RuleType } from '@condo/domain';
import { uniq } from 'lodash-es';
import type { ActionTree, GetterTree, MutationTree } from 'vuex';

export interface IRuleStore {
    rules: IRule[];
}

function getInitialState(): IRuleStore {
    return {
        rules: [],
    };
}

const getters: GetterTree<IRuleStore, any> = {
    rules(state: IRuleStore): IRule[] {
        return state.rules.filter(r => r.ruleType === RuleType.Business);
    },
    mfhRules(state: IRuleStore): IRule[] {
        return state.rules.filter(r => r.ruleType === RuleType.MfhBusiness);
    },
    portfolioRules(state: IRuleStore): IRule[] {
        return state.rules.filter(r => r.ruleType === RuleType.PortfolioBusiness);
    },
    priorityRules(state: IRuleStore): IRule[] {
        return state.rules.filter(r => r.ruleType === RuleType.Priority);
    },
};

const actions: ActionTree<IRuleStore, any> = {
    async fetchRules({ commit }) {
        commit('setRules', await getRules());
    },
    async toggleRegion({ commit, state }, params: { id: number; region: string; enabled: boolean }) {
        const rule = state.rules.find(r => r.ruleId === params.id);
        const regions: string[] = params.enabled ? uniq([...rule.regions, params.region]) : rule.regions.filter(r => r !== params.region);
        commit('updateRule', await updateRule(params.id, { regions }));
    },
    async updateRule({ commit }, params: { ruleId: number; payload: { ruleName?: string; condCode?: string; regions?: string[] } }) {
        commit('updateRule', await updateRule(params.ruleId, params.payload));
    },
    async createRule({ commit }, params: { ruleName: string; condCode: string; ruleType: RuleType }) {
        commit('addRule', await createRule({ ...params, regions: [] }));
    },
    async deleteRule({ commit }, id: number) {
        await deleteRule(id);
        commit('deleteRule', id);
    },
};

const mutations: MutationTree<IRuleStore> = {
    updateRule(state, newRule: Partial<IRule>) {
        state.rules = state.rules.map(r => (r.ruleId === newRule.ruleId ? { ...r, ...newRule } : r));
    },
    addRule(state, newRule: IRule) {
        state.rules = state.rules.concat(newRule);
    },
    setRules(state, rules: IRule[]) {
        state.rules = rules;
    },
    deleteRule(state, id: number) {
        state.rules = state.rules.filter(r => r.ruleId !== id);
    },
};

export const ruleStore = {
    namespaced: true,
    state: getInitialState(),
    actions,
    getters,
    mutations,
};
