import { deleteDocument, findDocuments, getSignedUrl, linkFile, publishFile, updateDocument, uploadFile } from '@/api/basecamp.api';
import { MessageType, showMessage, showSnackbar } from '@/shared/messages';
import {
    DocumentType,
    DocumentUploadStatus,
    FileCategory,
    type IDocument,
    type IDocumentRelation,
    type IFindDocumentsReq,
    type IMediaFile,
    IUploadAndSaveFileResult,
} from '@condo/domain';
import axios from 'axios';

import { resolveUrlFor } from '@/router/document-relation-url-resolver';

export interface IFileStore {
    documents: IDocument[];
    filterDocumentTypes: DocumentType[];
}

export interface IUploadData {
    fileOrFiles: File | File[];
    relationData?: Partial<IDocumentRelation>;
    docData?: Partial<IDocument>;
    mediaFileData?: Partial<IMediaFile>;
    fileCategory?: FileCategory;
    displayDuplicateSnackbar?: boolean;
    checkDuplicates?: boolean;
    folderId?: number;
}

function getInitialState() {
    return {
        documents: [],
        filterDocumentTypes: [],
    };
}

// initial state
const state = getInitialState();

const getters = {
    all(state: IFileStore): IDocument[] {
        if (state.filterDocumentTypes.length < 1) {
            return state.documents;
        }

        return state.documents.filter(
            (document: IDocument) =>
                state.filterDocumentTypes.includes(document.documentType) ||
                ((document.documentType === DocumentType.PromotionExpose || document.documentType === DocumentType.CustomizedSellingExpose) &&
                    state.filterDocumentTypes.includes(DocumentType.SellingExpose)),
        );
    },
    filterDocumentTypes(state: IFileStore) {
        return state.filterDocumentTypes;
    },
};

const baseDocumentUpload = async (
    { commit, dispatch },
    { fileOrFiles, relationData, docData, mediaFileData, fileCategory = FileCategory.Document, checkDuplicates = false, folderId }: IUploadData,
): Promise<{ status: DocumentUploadStatus; file: IDocument; uploadResponse?: string }[]> => {
    let bulkUploadSize = 0;
    const fileData = {
        ...relationData,
        ...docData,
        ...mediaFileData,
        folderId,
    };
    const sizeLimit = 30000000; // bytes, 28.61 MB in fact,
    // the G cloud run is about 30MB but better to be on the safe side

    const formData = new FormData();
    const mixedUploads = [];
    const files = Array.isArray(fileOrFiles) ? fileOrFiles : [fileOrFiles];

    files.forEach((file, index) => {
        if (bulkUploadSize + file.size < sizeLimit) {
            formData.append(`file_${index}_generic`, file);
            bulkUploadSize += file.size;
        } else {
            showMessage("Hold tight! We're in the middle of the upload, please do not leave the page.");
            // to big or out of total request size, see sizeLimit (this is limitation from G cloud run)
            mixedUploads.push(dispatch('uploadFileWithSignedUrl', { file, fileData, fileCategory }));
        }
    });

    // some bytes to upload in a bulk
    if (bulkUploadSize > 0) {
        mixedUploads.push(uploadFile(formData, { ...fileData, fileCategory, checkDuplicates }));
    }

    return Promise.all(mixedUploads).then(res =>
        res.flatMap(files => {
            const newFiles = files.filter(({ status }) => status === DocumentUploadStatus.New);
            newFiles.forEach(({ file }) => commit('addDocument', { ...file, isNew: true }));

            return files;
        }),
    );
};

const actions = {
    async getDocument({ commit }, params: IFindDocumentsReq) {
        return findDocuments(params).then((documents: IDocument[]) => {
            commit('documents', documents);
            return documents;
        });
    },

    async uploadDocuments(ctx, data: IUploadData): Promise<IUploadAndSaveFileResult[]> {
        const uploadResults = await baseDocumentUpload(ctx, data);
        const duplicateResults = uploadResults.filter(result => result.status === DocumentUploadStatus.Duplicate);
        const uploadResponses = uploadResults.filter(result => !!result.uploadResponse).map(r => r.uploadResponse!);
        if (uploadResponses.length) {
            uploadResponses.forEach(response => showSnackbar(response, MessageType.SuccessDark));
        }

        if (duplicateResults?.length && (typeof data.displayDuplicateSnackbar === 'undefined' || data.displayDuplicateSnackbar)) {
            const urlsForResults = duplicateResults
                .map(result => {
                    const url = resolveUrlFor(result.file);
                    if (url) {
                        return `· <a class="color-white-bold" href="${url}" target="_blank">${result.file.filename}</a>`;
                    }
                    return `<b>${result.file.filename}</b>`;
                })
                .join('<br />');
            showSnackbar(`Some uploaded files are duplicated: <br /><br />${urlsForResults}<br />`, MessageType.Danger);
        }
        return uploadResults;
    },
    async uploadFileWithSignedUrl(store, { file, fileData, fileCategory }) {
        return getSignedUrl().then(response => {
            const { bucketFilename, url } = response;
            return axios
                .create({
                    headers: {
                        'Content-Type': file.type,
                        'Access-Control-Allow-Origin': '*',
                    },
                })
                .put(url, file)
                .then(() => {
                    // Has defined relations
                    if (Object.values(fileData).some(val => val)) {
                        return linkFile({
                            ...fileData,
                            bucketFilename,
                            originalFileName: file.name,
                            source: 'generic',
                            fileCategory,
                        });
                    }
                    return publishFile(bucketFilename);
                });
        });
    },
    async deleteDocument({ commit }, { uuid }) {
        return deleteDocument(uuid).then(() => commit('deleteDocument', uuid));
    },
    async updateDocument({ commit }, documentDetails: Partial<IDocument>) {
        return updateDocument(documentDetails).then(document => commit('replaceDocument', document));
    },
};

const mutations = {
    documents(state: IFileStore, documents: IDocument[]) {
        state.documents = documents;
    },
    addDocument(state: IFileStore, document: IDocument) {
        state.documents.push(document);
    },
    replaceDocument(state: IFileStore, document: IDocument) {
        const index = state.documents.findIndex(d => d.documentId === document.documentId);
        state.documents[index] = document;
    },
    deleteDocument(state: IFileStore, uuid: string) {
        state.documents = state.documents.filter(document => document.uuid !== uuid);
    },

    setFilterDocumentTypes(state: IFileStore, filterDocumentTypes: DocumentType[]) {
        state.filterDocumentTypes = filterDocumentTypes;
    },
    toggleFilterDocumentTypes(state: IFileStore, filterDocumentType: DocumentType) {
        if (state.filterDocumentTypes.includes(filterDocumentType)) {
            state.filterDocumentTypes = state.filterDocumentTypes.filter(item => item !== filterDocumentType);
            return;
        }
        state.filterDocumentTypes.push(filterDocumentType);
    },
};

export const documentsStore = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
