import type { IDocument } from '@condo/domain';

export const resolveUrlFor = (document: IDocument): string | undefined => {
    const documentRelation = document.documentRelation[0];
    if (!documentRelation) {
        return undefined;
    }
    switch (true) {
        case !!documentRelation.invoiceId: {
            return `/accounting/invoices/${documentRelation.invoiceId}`;
        }
        // todo: inspectionId -> estateId  set as well? in that case, redirect to estates/:id/inspections
        // todo: renovationInspectionId -> estateId  set as well? in that case, redirect to estates/:id/inspections
        case !!documentRelation.estateId: {
            return `/estates/${documentRelation.estateId}`;
        }

        default:
            return undefined;
    }
};
