import type { ICockpitStore } from '@/store';
import { getStrippedCCSRoutes } from '@condo/domain';
import type { RouteRecordRaw } from 'vue-router';
import type { Store } from 'vuex';

const CCSRouteMap = getStrippedCCSRoutes();

export const createBankAccountRoutes = (store: Store<ICockpitStore>): RouteRecordRaw => ({
    ...CCSRouteMap['one.bank.account'],
    redirect: params => `${params.fullPath}/${CCSRouteMap['one.bank.account.transactions'].path}`,
    components: {
        default: () => import('@/components/finance/bank-account/OneBankAccountView.vue'),
    },
    props: {
        default: route => ({ bankAccountId: +route.params.bankAccountId }),
    },
    async beforeEnter(to, from, next) {
        await store.dispatch('finance/getBankAccount', { bankAccountId: +to.params.bankAccountId });
        next();
    },
    children: [
        {
            ...CCSRouteMap['one.bank.account.transactions'],
            components: {
                default: () => import('@/components/finance/bank-account/BankAccountCashflow.vue'),
            },
            props: true,
        },
        {
            ...CCSRouteMap['one.bank.account.balance-records'],
            components: {
                default: () => import('@/components/finance/bank-account/BankAccountBalanceRecords.vue'),
            },
            props: true,
        },
        {
            ...CCSRouteMap['one.bank.account.ledger-entries'],
            components: {
                default: () => import('@/components/finance/bank-account/BankAccountLedgerEntries.vue'),
            },
            props: true,
        },
    ],
});
