import { getClient } from '@/api/api-client';
import type { CompanyFunction, IGetUserDetailsResult, IUserCompanyFunction } from '@condo/domain';

export const getUserDetails = async (userUid: string): Promise<IGetUserDetailsResult> => {
    const { data } = await getClient('basecamp').get(`/users/${userUid}`);
    return data;
};

export const fetchUserCompanyFunctions = async (): Promise<IUserCompanyFunction[]> => {
    const { data } = await getClient('basecamp').get('/users/company-functions');
    return data.userCompanyFunctions;
};

export const updateUserCompanyFunction = async ({
    userCompanyFunctionId,
    userId,
    contactPersonId,
    companyFunction,
}: {
    userCompanyFunctionId: number | null;
    userId: string;
    contactPersonId: number | undefined;
    companyFunction: CompanyFunction;
}): Promise<IUserCompanyFunction> => {
    const { data } = await getClient('basecamp').post(`/users/${userId}/company-functions`, {
        userCompanyFunctionId,
        contactPersonId,
        companyFunction,
    });
    return data.userCompanyFunction;
};

export const deleteUserCompanyFunction = async (userId: string): Promise<void> => {
    await getClient('basecamp').delete(`/users/${userId}/company-functions`);
};
