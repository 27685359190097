import type { IMFHProcess, IStartMFHProcessParams, ProcessType } from '@condo/domain';
import { getClient } from '../api-client';

export const getMFHProcess = async ({ processType, processId }: IGetMFHProcessReq.Params): Promise<IMFHProcess> =>
    getClient('basecamp')
        .get(`/mfh/processes/${processType}/${processId}`)
        .then(response => response.data.process);

export const updateMfhProcess = async ({ mfhId, processType, processId }: IUpdateMFHProcessReq.Params, data: IUpdateMFHProcessReq.Body): Promise<IMFHProcess> =>
    getClient('basecamp')
        .patch(`/mfh/${mfhId}/processes/${processType}/${processId}`, data)
        .then(response => response.data.process);

export const startMfhProcess = async ({ mfhId, processType }: Partial<IStartMFHProcessParams>): Promise<IMFHProcess> =>
    getClient('basecamp')
        .post(`/mfh/${mfhId}/processes/${processType}`)
        .then(response => response.data.process);

export const postProcessMFHEvent = async ({
    processType,
    processId,
    event,
    eventDate,
    mfhId,
    additionalData,
}: {
    processType: ProcessType;
    processId: number;
    mfhId: number;
    event: string;
    eventDate: Date;
    additionalData: Record<string, any>;
}): Promise<{}> => getClient('basecamp').post(`/mfh/${mfhId}/processes/${processType}/${processId}/event`, { event, eventDate, additionalData });
