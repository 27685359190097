import type { DocumentType, IDocumentInspection, IFormAnswer } from '@condo/domain';
import { getClient } from './api-client';

export const fetchDocumentWithInspection = async (documentId: number): Promise<IDocumentInspection> =>
    getClient('basecamp')
        .get(`/document-inspections/document/${documentId}`)
        .then(({ data }) => data.document);

export const createDocumentInspection = async (documentId: number, syncWithDocumentType?: DocumentType | DocumentType[]): Promise<IDocumentInspection> =>
    getClient('basecamp')
        .post('/document-inspections', { documentId, syncWithDocumentType })
        .then(({ data }) => data.documentInspection);

export const updateDocumentInspection = async (
    inspectionId: number,
    answers: IFormAnswer,
    syncWithDocumentType?: DocumentType | DocumentType[],
): Promise<IDocumentInspection> =>
    getClient('basecamp')
        .put(`/document-inspections/${inspectionId}`, { answers, syncWithDocumentType })
        .then(({ data }) => data.documentInspection);

export const deleteDocumentInspection = async (inspectionId: number): Promise<void> => getClient('basecamp').delete(`/document-inspections/${inspectionId}`);

export const fetchDocumentInspections = async (params: { estateId?: number; state?: string; documentTypes?: DocumentType[] }): Promise<IDocumentInspection[]> =>
    getClient('basecamp')
        .get(`/document-inspections`, { params })
        .then(({ data }) => data.documentInspections);

export const addDocumentInspectionAnswerGroup = async (documentInspectionId: number): Promise<IDocumentInspection> =>
    getClient('basecamp')
        .post(`/document-inspections/${documentInspectionId}/answer-group`)
        .then(({ data }) => data.documentInspection);

export const deleteDocumentInspectionAnswerGroup = async (documentInspectionId: number, answerGroupIndex: number): Promise<void> =>
    getClient('basecamp').delete(`/document-inspections/${documentInspectionId}/answer-group/${answerGroupIndex}`);
