import {
    createEstateElement,
    deleteEstateElement,
    fetchConfigRecord,
    fetchLinkedEstates,
    fetchOneEstate,
    findEstates,
    findOverviewEstates,
    findSourcingEstates,
    findTransactionMonitoringEstates,
    getBimServerMap,
    getEstateElements,
    linkEstateLocation,
    patchEstateElement,
    triggerEstateValuation,
    updateEstate,
    updateEstateAttributes,
    updateEstateLocation,
} from '@/api/basecamp.api';
import { findPropertyManagementEstates } from '@/api/property-management.api';
import { showErrorMessage, withLoading } from '@/shared';
import type {
    IAttributes,
    IBimServerMap,
    IConfigSearchParams,
    IDictionarySlidersConfigs,
    IEstate,
    IEstateElement,
    IFetchEstate,
    ProcessStateUnion,
    ProcessType,
} from '@condo/domain';
import { ConfigRecord } from '@condo/domain';
import { orderBy } from 'lodash-es';
import type { ActionTree, GetterTree } from 'vuex';

export interface IEstatesStore {
    oneEstate: IEstate;
    estates: IEstate[];
    total: number;
    estatesLoading: boolean;
    estateElements: IEstateElement[];
    bimServerMap?: IBimServerMap;
    dictionaries: {
        slidersConfig: IDictionarySlidersConfigs;
    };
    estatesGroup: IEstate[];
    configurationRecords: ConfigRecord[];
    filter: {
        orderBy: string;
        order: string;
        page: number;
        size: number;
        quarter: string[];
        originalId: string[];
        state: string[];
        zipcode: string[];
        adPublishingDateFrom: undefined;
        adPublishingDateTo: undefined;
        marginFrom: undefined;
        marginTo: undefined;
        priceFrom: undefined;
        priceTo: undefined;
    };
}

function getInitialState() {
    return {
        oneEstate: null,
        estates: [],
        estateElements: [],

        bimServerMap: null,
        total: 0,
        estatesLoading: false,
        dictionaries: {
            slidersConfig: {
                price: { min: 0, max: 1000000000, ticks: [] },
                listedMargin: { min: -30, max: 60, ticks: [] },
            },
        },
        estatesGroup: [],
        configurationRecords: [],
        filter: {
            orderBy: 'estate.estateId',
            order: 'DESC',
            page: 1,
            size: 50,
            state: [],
            county: [],
            city: [],
            street: null,
            quarter: null,
            originalId: [],
            zipcode: [],

            adPublishingDateFrom: undefined,
            adPublishingDateTo: undefined,
            marginFrom: undefined,
            marginTo: undefined,
            priceFrom: undefined,
            priceTo: undefined,
        },
    } as IEstatesStore;
}

// initial state
const state = getInitialState();

const getters: GetterTree<IEstatesStore, any> = {
    oneEstate(state): IEstate {
        return state.oneEstate;
    },
    allEstates(state: IEstatesStore): IEstate[] {
        return state.estates;
    },
    estatesCount(state: IEstatesStore): number {
        return state.total;
    },
    //todo: KO-1391 move to dictionaries store
    dictionaries(state: IEstatesStore) {
        return state.dictionaries;
    },

    filter(state: IEstatesStore) {
        return state.filter;
    },
    loadingState(state: IEstatesStore): boolean {
        return state.estatesLoading;
    },
    elements(state: IEstatesStore): IEstateElement[] {
        return state.estateElements;
    },
    bimServerMap(state: IEstatesStore): IBimServerMap {
        return state.bimServerMap;
    },
    configurationRecords(state: IEstatesStore) {
        return state.configurationRecords;
    },
    orderedConfigurationRecords(state: IEstatesStore): ConfigRecord[] {
        return orderBy(state.configurationRecords, record => (record.createdAt ? -new Date(record.createdAt).getTime() : Number.NEGATIVE_INFINITY));
    },
    estatesGroup(state: IEstatesStore) {
        return state.estatesGroup;
    },
};

const actions: ActionTree<IEstatesStore, any> = {
    async updateEstate({ commit }, estate: Partial<IEstate>) {
        const { estateId, ...rest } = estate;
        return updateEstate(estateId!, rest).then(dbEstate => {
            commit('setOneEstate', dbEstate);
            return dbEstate;
        });
    },
    async updateEstateLocation({ commit }, { estateId, location }) {
        return updateEstateLocation(estateId, location).then(dbEstate => {
            commit('setOneEstate', dbEstate);
            return dbEstate;
        });
    },
    async linkEstateLocation({ commit }, { estateId, locationId }) {
        return linkEstateLocation(estateId, locationId).then(dbEstate => {
            commit('setOneEstate', dbEstate);
            return dbEstate;
        });
    },
    async updateEstateAttributes({ commit }, { estateId, attributes, isPostAttributes, mergeWithExisting }) {
        return updateEstateAttributes(estateId, attributes, isPostAttributes, mergeWithExisting).then(dbEstate => {
            commit('setOneEstate', dbEstate);
            return dbEstate;
        });
    },
    async createElement({ commit }, { element }) {
        return createEstateElement(element).then(result => {
            const { elements, newElement } = result;
            commit('setElements', elements);
            return newElement;
        });
    },

    async updateElement({ commit }, { element, estateElementId }) {
        return patchEstateElement(estateElementId, element).then(elements => {
            commit('setElements', elements);
            return elements;
        });
    },
    async setOneEstate({ commit }, params) {
        if (params?.estate) {
            commit('setOneEstate', params.estate);
            return params.estate;
        }
        commit('setOneEstate', null);
        return null;
    },
    async getOneEstate({ commit }, { estateId, params }: { estateId: number; params?: IFetchEstate }) {
        return withLoading(() =>
            fetchOneEstate(estateId, params).then(estate => {
                commit('setOneEstate', estate);
                return estate;
            }),
        );
    },
    async getElements({ commit }, { estateId, asTree }) {
        return getEstateElements(estateId, asTree).then(elements => {
            commit('setElements', elements);
            return elements;
        });
    },
    async findOverviewEstates({ commit }, { params }) {
        return findOverviewEstates(params).then(response => {
            const { estates, total } = response;
            commit('setEstates', { estates, total });
            return estates;
        });
    },
    async findSourcingEstates({ commit }, { params }) {
        return findSourcingEstates(params).then(response => {
            const { estates, total } = response;
            commit('setEstates', { estates, total });
            return estates;
        });
    },
    async findTransactionMonitoringEstates({ commit }, { params }) {
        return findTransactionMonitoringEstates(params).then(response => {
            const { estates, total } = response;
            commit('setEstates', { estates, total });
            return estates;
        });
    },
    async getEstates({ commit }, { params }) {
        commit('setLoading', true);

        return findEstates(params)
            .then(response => {
                const { estates, total } = response;
                commit('setEstates', { estates, total });
                return estates;
            })
            .catch(error => {
                commit('setEstates', { estates: [], total: 0 });
                showErrorMessage({ message: `Fetching Estates Failed: ${error.message}` });
            })
            .finally(() => commit('setLoading', false));
    },
    async getPropertyManagementEstates({ commit }, { params }) {
        return findPropertyManagementEstates(params)
            .then(response => {
                const { estates, total } = response;
                commit('setEstates', { estates, total });
            })
            .catch(error => {
                commit('setEstates', { estates: [], total: 0 });
                showErrorMessage({ message: `Fetching Estates Failed: ${error.message}` });
            });
    },
    async deleteEstateElement({ commit }, { estateElementId }) {
        return deleteEstateElement(estateElementId).then(elements => {
            commit('setElements', elements);

            return elements;
        });
    },
    async markAsScreened({ commit }, { estateId, isScreened }: { estateId: number; isScreened: boolean }) {
        return updateEstate(estateId, { isScreened }).then(async estate => {
            const merged = { ...(this.state.oneEstate ?? estate), isScreened };
            commit('upsertEstateInList', merged);
            return merged;
        });
    },
    async getBimServerMap({ commit }, { estateId }) {
        return getBimServerMap(estateId).then(data => {
            commit('setBimServerMap', data);
            return data;
        });
    },
    async triggerEstateValuation({ dispatch }, estateId) {
        return triggerEstateValuation(estateId).then(() =>
            dispatch('getOneEstate', { estateId }).then(() => dispatch('predictions/getPredictions', { estateId, latest: true })),
        );
    },
    async fetchConfigRecords({ commit }, attributes: IConfigSearchParams) {
        return fetchConfigRecord(attributes).then(data => {
            const configs = data.map(c => new ConfigRecord(c));
            commit('setConfigurationRecords', configs);
            return configs;
        });
    },
    async fetchEstatesGroup({ commit }, { groupId }: { groupId: string }) {
        if (!groupId) {
            commit('setEstateGroup', []);
            return [];
        }
        return fetchLinkedEstates({ groupId }).then(data => {
            commit('setEstateGroup', data.estates);
            return data.estates;
        });
    },
};

const mutations = {
    setOneEstate(state: IEstatesStore, estate: IEstate) {
        if (estate) {
            estate.postRenovationAttributes = estate.postRenovationAttributes ?? ({} as IAttributes);
            state.oneEstate = estate;
            return;
        }
        state.oneEstate = null;
    },
    upsertEstateInList(state: IEstatesStore, estate: IEstate) {
        let estateFound = false;
        state.estates = state.estates
            .map(existingEstate => {
                if (existingEstate.estateId === estate.estateId) {
                    estateFound = true;
                    return estate;
                }
                return existingEstate;
            })
            .concat(estateFound ? [] : [estate]);
        state.total = estateFound ? state.total : state.total + 1;

        if (state.oneEstate?.estateId === estate.estateId) {
            state.oneEstate = estate;
        }
    },
    setEstates(state: IEstatesStore, { estates, total }: { estates: IEstate[]; total: number }) {
        state.estates = estates;
        state.total = total;
    },
    setLoading(state: IEstatesStore, loadingState: boolean) {
        state.estatesLoading = loadingState;
    },
    setElements(state: IEstatesStore, elements: IEstateElement[]) {
        state.estateElements = elements;
    },
    setBimServerMap(state: IEstatesStore, bimServerMap: IBimServerMap) {
        state.bimServerMap = bimServerMap;
    },
    setConfigurationRecords(state: IEstatesStore, configurationRecords: ConfigRecord[]) {
        state.configurationRecords = configurationRecords;
    },
    setEstateGroup(state: IEstatesStore, estates: IEstate[]) {
        state.estatesGroup = estates;
    },
    updateState(state: IEstatesStore, { processType, processState }: { processType: ProcessType; processState: ProcessStateUnion }) {
        const process = processType.toLowerCase();
        state.oneEstate[process].processState = processState;
    },
};

export const estatesStore = {
    namespaced: false,
    state,
    getters,
    actions,
    mutations,
};
