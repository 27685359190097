import { fetchGoogleCalendarsList } from '@/api/basecamp.api';
import { mfhCreateCalendarEvent, mfhDeleteCalendarEvent, mfhFetchCalendarEvents, mfhUpdateCalendarEvent } from '@/api/mfh/mfh-calendar-events.api';
import type { IMFHCalendarEvent, IMFHCalendarEventCreateParams, IMFHCalendarEventSearchParams } from '@condo/domain';

export interface IMFHCalendarStore {
    all: IMFHCalendarEvent[];
}

function getInitialState() {
    return {
        data: [],
        gCalendars: [],
    };
}

// initial state
const state = getInitialState();

const getters = {
    all(state) {
        return state.data;
    },
    googleCalendars(state) {
        return state.gCalendars;
    },
};

const actions = {
    async getGoogleCalendarsList({ commit }) {
        return fetchGoogleCalendarsList().then(calendars => {
            commit('setGoogleCalendarsList', calendars);

            return calendars;
        });
    },

    async getCalendarEvents({ commit }, params: IMFHCalendarEventSearchParams) {
        return mfhFetchCalendarEvents(params)
            .then(events => {
                commit('setCalendarEvents', { events });

                return events;
            })
            .catch(() => {
                commit('setCalendarEvents', { events: [] });
            });
    },

    async createCalendarEvent({ commit }, payload: IMFHCalendarEventCreateParams) {
        return mfhCreateCalendarEvent(payload).then(event => {
            commit('addCalendarEvent', event);

            return event;
        });
    },

    async updateCalendarEvent({ commit }, payload: IMFHCalendarEvent) {
        return mfhUpdateCalendarEvent(payload).then(event => {
            commit('updateCalendarEvent', event);
            return event;
        });
    },

    async deleteCalendarEvent({ commit }, calendarEventId: number) {
        return mfhDeleteCalendarEvent(calendarEventId).then(event => {
            commit('deleteCalendarEvent', event);

            return event;
        });
    },
};

const mutations = {
    setGoogleCalendarsList(state, calendars) {
        state.gCalendars = calendars;
    },
    setCalendarEvents(state, { events }) {
        state.data = events;
    },
    addCalendarEvent(state, event) {
        state.data.push(event);
    },
    deleteCalendarEvent(state, eventId) {
        state.data = state.data.filter(event => event.eventId !== eventId);
    },
    updateCalendarEvent(state, event) {
        const existingEvents = state.data.filter(ev => ev.eventId === !event.eventId);
        state.data = [...existingEvents, event];
    },
};

export const mfhCalendarEventsStore = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
