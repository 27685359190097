import { findPurchasingEstates } from '@/api/purchasing.api';
import { getEventStartDateByStage } from '@/shared';
import { CalendarStagesUnsorted, type IPurchasingEstate, type IPurchasingEstatesQuery } from '@condo/domain';
import { currency, intlDateFormat } from '@condo/formatters';

export interface IPurchasingStore {
    purchasingEstates: IPurchasingEstate[];
    totalPurchasingEstates: number;
}

const getInitialState = (): IPurchasingStore => ({
    purchasingEstates: [],
    totalPurchasingEstates: 0,
});

const getters = {
    purchasingEstates(state: IPurchasingStore): IPurchasingEstate[] {
        return state.purchasingEstates;
    },
    totalPurchasingEstates(state: IPurchasingStore): number {
        return state.totalPurchasingEstates;
    },
};

const actions = {
    async getPurchasingEstates({ commit }, params?: IPurchasingEstatesQuery) {
        const { estates, total } = await findPurchasingEstates(params);

        const mappedEstates = estates.map(data => ({
            ...data,
            purchasingEntityShortName: data?.purchasing.purchasingEntity?.shortName,
            computed: {
                totalPurchasingPrice: currency(data.purchasing?.totalPurchasingPrice ?? null, 0, 0),
                appointment: intlDateFormat(data ? getEventStartDateByStage(data.purchasing.events ?? [], CalendarStagesUnsorted.Inspection) : null),
                notarizationDate: intlDateFormat(data ? getEventStartDateByStage(data.purchasing.events ?? [], CalendarStagesUnsorted.Notarization) : null),
                handover: intlDateFormat(data ? getEventStartDateByStage(data.purchasing.events ?? [], CalendarStagesUnsorted.Handover) : null),
            },
            createdAt: intlDateFormat(data.createdAt, false),
            purchasingOfferMadeAt: intlDateFormat(data.purchasingOfferMadeAt, false),
        }));
        commit('setPurchasingEstates', { estates: mappedEstates, total });

        return estates;
    },
};

const mutations = {
    setPurchasingEstates(state: IPurchasingStore, { estates, total }) {
        state.purchasingEstates = estates;
        state.totalPurchasingEstates = total;
    },
};

export const purchasingStore = {
    namespaced: true,
    state: getInitialState(),
    getters,
    actions,
    mutations,
};
