import { pustToQueryObjectNotation } from '@/shared';
import { arrify } from '@condo/helpers';
import { MaybeRef, computed, isReactive, isRef, onBeforeUnmount, onMounted, ref } from 'vue';
import { RouteLocationNormalizedLoaded, onBeforeRouteUpdate, useRoute } from 'vue-router';
import { useWindowSize } from 'vue-window-size';

export interface IRouteParamParserConfig {
    [key: string]: {
        name?: string;
        parser: any;
        fallback?: any;
        isArray?: boolean;
    };
}

export const useViewPortHeight = () => {
    const { height: windowHeight } = useWindowSize();
    return computed(() => {
        const NAVBAR_HEIGHT = 56;
        const REVIEW_PANEL = 62;
        return `${windowHeight.value - NAVBAR_HEIGHT - REVIEW_PANEL}px`;
    });
};

export const useTimeout = (timeMs: number) => {
    const timeoutFn = ref(null);
    const timeoutElapsed = ref(false);

    onMounted(() => {
        timeoutFn.value = setTimeout(() => {
            timeoutElapsed.value = true;
        }, timeMs);
    });

    onBeforeUnmount(() => {
        if (timeoutFn.value) {
            clearTimeout(timeoutFn.value);
        }
    });

    return timeoutElapsed;
};

const getRouteParamArray = <T extends number | string>(routeLike: RouteLocationNormalizedLoaded, key: string, caster: (arg: any) => T, fallback?: T[]): T[] => {
    const params = new URLSearchParams(decodeURIComponent(routeLike.fullPath.split('?')[1]).replaceAll('&#x3D;', '='));
    const value = params.getAll(key).filter(Boolean);

    if (value?.length) {
        return arrify(value).map(val => caster(val));
    }

    return fallback ?? [];
};

const getRouteParam = <T extends number | string | boolean>(
    routeLike: RouteLocationNormalizedLoaded,
    key: string,
    caster: (arg: any) => T,
    fallback?: any,
): T => {
    const params = new URLSearchParams(decodeURIComponent(routeLike.fullPath.split('?')[1]).replaceAll('&#x3D;', '='));
    const value = params.get(key);

    if (value) {
        return caster(value);
    }
    return fallback;
};

export const parseRouteBasedOnConig = (config: IRouteParamParserConfig, routeLike?: RouteLocationNormalizedLoaded) => {
    const route = routeLike ?? useRoute();
    const result = {};
    const applyDefaultFallbackValues = !routeLike && Object.keys(route.query).length === 0;

    console.info(`[use-url-query-based-fetching] applying default params: ${applyDefaultFallbackValues}\n`);

    Object.entries(config).forEach(([key, value]) => {
        result[key] = value.isArray
            ? getRouteParamArray(route, value.name ?? key, value.parser, applyDefaultFallbackValues ? (value.fallback ?? undefined) : undefined)
            : getRouteParam(route, value.name ?? key, value.parser, applyDefaultFallbackValues ? (value.fallback ?? undefined) : undefined);
    });
    return result;
};

export const useRouteParams = (routeLike?: RouteLocationNormalizedLoaded) => {
    const route = routeLike ?? useRoute();
    return {
        getRouteParamArray: (...args: any[]) => {
            // @ts-ignore
            return getRouteParamArray(route, ...args);
        },
        getRouteParam: (...args: any[]) => {
            // @ts-ignore
            return getRouteParam(route, ...args);
        },
    };
};

export const resetAccordingConfig = (config: IRouteParamParserConfig) => {
    return Object.entries(config).reduce(
        (acc, [key, config]) => {
            return {
                ...acc,
                [key]: config.fallback ?? undefined,
            };
        },
        {} as Record<string, any>,
    );
};

export interface IUseQueryParams {
    parserConfig: IRouteParamParserConfig;
    filterObject: MaybeRef<Record<any, any>>;
    fetchFunc: (param?: any) => any;
    routeName?: string;
    onBeforeUpdateUrl?: () => void;
    onAfterUpdateUrl?: () => void;
    onBeforeFetchData?: () => void;
    onAfterFetchData?: () => void;
}

/**
 * Downside - it is better to define filter
 * @param {IUseQueryParams} params
 */
export const useUrlQueryBasedFetching = (params: IUseQueryParams) => {
    const { routeName, fetchFunc, filterObject, parserConfig, onBeforeUpdateUrl, onAfterUpdateUrl, onBeforeFetchData } = params;

    /*Let's support all types of stuff here with closure tho*/
    const reassignFilter = (newFiler: Record<any, any>) => {
        if (isRef(filterObject)) {
            filterObject.value = newFiler;
        } else if (isReactive(filterObject)) {
            Object.assign(filterObject, newFiler);
        } else {
            params.filterObject = newFiler;
        }
    };

    const initialUrlValue = parseRouteBasedOnConig(parserConfig);
    reassignFilter(initialUrlValue);

    const updateURL = async (skipFetch?: boolean, replace?: boolean) => {
        console.info(`[use-url-query-based-fetching] refreshing with 'skipFetch':'${skipFetch}' URL params\n`, JSON.stringify(filterObject.value));
        onBeforeUpdateUrl?.();
        await pustToQueryObjectNotation({
            routeName,
            params: isRef(filterObject) ? filterObject.value : filterObject,
            replace,
            duplicateConfig: {
                preventNavigation: true,
                /*Duplication Check is done in push query via comparing full URLs and resolving URL beforehand*/
                func: () => {
                    /*If condition is required to make fetch on first load*/
                    if (!skipFetch) {
                        /*Hitting search Button Twice, Three times, how many times you want...*/
                        console.log('[use-url-query-based-fetching] refresh data on duplicate\n');
                        fetchFunc?.();
                    }
                },
            },
        });
        onAfterUpdateUrl?.();
    };

    onBeforeRouteUpdate(to => {
        /*Forward/Back Navigation -> getting params from URL and fetching data*/
        console.log('[use-url-query-based-fetching][on-before-mounted]\n');
        const newParams = parseRouteBasedOnConig(parserConfig, to);
        reassignFilter(newParams);
        fetchFunc?.();
    });

    onMounted(async () => {
        /*Initial Page Load. URL repalces in case of need. Loading Data Separately*/
        console.log('[use-url-query-based-fetching][on-mounted]');
        await updateURL(true, true);
        fetchFunc?.();
    });

    return {
        updateURL,
        resetQuery: async (reset?: Record<any, any>) => {
            /*Let's use the same config to reset it. fallback values as default one baby*/
            reassignFilter(reset ?? resetAccordingConfig(parserConfig));
            await updateURL();
        },
        runSearch: async (newSearch?: boolean) => {
            if (newSearch) {
                onBeforeFetchData?.();
            }
            await updateURL();
        },
        initialUrlValue,
    };
};
