import { getStrippedCCSRoutes } from '@condo/domain';
import type { RouteRecordRaw } from 'vue-router';

const CCSRouteMap = getStrippedCCSRoutes();

export const createMfhBuildingRoutes = (): RouteRecordRaw[] => [
    {
        ...CCSRouteMap['one.mfh-building'],
        components: {
            default: () => import('@/views/mfh/OneMfhBuilding.vue'),
        },
        props: {
            default: route => ({ mfhBuildingId: +route.params.mfhBuildingId }),
        },
        children: [
            {
                ...CCSRouteMap['one.mfh-building.detail'],
                components: {
                    default: () => import('@/components/mfh/building/MfhBuildingDetail.vue'),
                },
                props: true,
            },
            {
                ...CCSRouteMap['one.mfh-building.timeline'],
                components: {
                    default: () => import('@/components/timeline/EventsTimeline.vue'),
                },
                props: {
                    default: route => ({ ...route.params }),
                },
            },
            {
                ...CCSRouteMap['one.mfh-building.renovation.overview'],
                components: {
                    default: () => import('@/components/mfh/building/MfhBuildingRenovationOverview.vue'),
                },
                props: true,
            },
            {
                ...CCSRouteMap['one.mfh-building.estate-management.overview'],
                components: {
                    default: () => import('@/components/mfh/building/MfhBuildingEstateManagementOverview.vue'),
                },
                props: true,
            },
        ],
    },
];
