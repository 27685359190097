import { UiConstructionPosition } from '@/components/construction/entities/ui-construction-position';
import { openDownloadLink } from '@/shared';
import type {
    ConstructionCraft,
    ConstructionPlanEvents,
    ContractorPricingBreakdown,
    DefectType,
    FetchConstructionPlanCostComparisonParams,
    IBimServerMap,
    ICatalogueItem,
    ICatalogueItemAvailability,
    ICatalogueItemPricing,
    ICondoFile,
    IConstructionPlan,
    IConstructionPlanPricingOfWeek,
    IConstructionPlanReportParams,
    IConstructionPlanStats,
    IConstructionPlanWeeklyComparisonRequest,
    IConstructionPlansReq,
    IConstructionPosition,
    IConstructionProgress,
    IConstructionSiteReportParams,
    IConstructionTemplate,
    IConstructionTemplateCatalogueItemRelation,
    IContractor,
    IContractorPricing,
    IDefect,
    IDefectEventRequest,
    IDefectsLayer,
    IDefectsResponse,
    IEstate,
    IEstateElement,
    IFetchDefectsParams,
    IFindCatalogueItemsQueries,
    IFindCatalogueItemsRelationParams,
    IGetRenovationCostTrackingParams,
    IGetRenovationProjectsParams,
    IInspectionQuestion,
    IInspectionQuestionAnswer,
    IMediaFile,
    IMilestone,
    IRenovationCostTrackingResponse,
    IRenovationEstatesQuery,
    IRenovationInspection,
    IRenovationProcess,
    IRenovationProject,
    InteriorQuality,
    RenovationCostTrackingPerEstate,
    RoomType,
    UpsertCatalogueItemParams,
} from '@condo/domain';
import { getClient } from './api-client';

export const getCatalogueItem = async (catalogueItemId: number, opts: IFindCatalogueItemsRelationParams): Promise<ICatalogueItem> => {
    return getClient('basecamp')
        .get(`/catalogue-items/${catalogueItemId}`, { params: opts })
        .then(res => res.data.catalogueItem);
};

export const saveCatalogueItem = async (catalogueItem: Partial<ICatalogueItem>): Promise<ICatalogueItem> => {
    if (!catalogueItem.catalogueItemId) {
        return getClient('basecamp')
            .post('/catalogue-items', { catalogueItem })
            .then(res => res.data.catalogueItem);
    }
    return getClient('basecamp')
        .patch(`/catalogue-items/${catalogueItem.catalogueItemId}`, { catalogueItem })
        .then(res => res.data.catalogueItem);
};

export const saveCatalogueItemAvailabilities = async (
    catalogueItemAvailabilities: Partial<ICatalogueItemAvailability>[],
): Promise<ICatalogueItemAvailability> => {
    return getClient('basecamp')
        .post('/catalogue-item-availabilities/', { catalogueItemAvailabilities })
        .then(res => res.data.catalogueItemAvailabilities);
};

export const deleteCatalogueItemAvailabilities = async (catalogueItemAvailabilityIds: number[]): Promise<ICatalogueItemAvailability> => {
    return getClient('basecamp').delete(`/catalogue-item-availabilities`, { params: { catalogueItemAvailabilityIds } });
};

export const fetchRenovationEstates = async (
    params: IRenovationEstatesQuery,
): Promise<{ estates: IEstate[]; total: number; totalInPlanning: number; totalInFixing: number; totalInRenovation: number }> =>
    getClient('basecamp')
        .get('/renovation/estates', { params })
        .then(res => res.data);

export const getEstateElements = async (estateId: number, asTree = true): Promise<IEstateElement[]> =>
    getClient('basecamp')
        .get('/estate-element', { params: { estateId, asTree } })
        .then(response => response.data);

export const createEstateElement = async (body: Partial<IEstateElement>): Promise<{ elements: IEstateElement[]; newElement: IEstateElement }> =>
    getClient('basecamp')
        .post('/estate-element', body)
        .then(response => response.data);

export const patchEstateElement = async (estateElementId: number, body: Partial<IEstateElement>): Promise<IEstateElement> =>
    getClient('basecamp')
        .patch(`/estate-element/${estateElementId}`, body)
        .then(response => response.data);

export const deleteEstateElement = async (estateElementId: Pick<IEstateElement, 'estateElementId'>): Promise<IEstateElement[]> =>
    getClient('basecamp')
        .delete(`/estate-element/${estateElementId}`)
        .then(response => response.data);

export const getIfcObjectCalculations = async (estateId: number, objectGuid: string) =>
    getClient('basecamp')
        .get('/bimserver/area-calculations', {
            params: {
                estateId,
                objectGuid,
            },
        })
        .then(response => response.data);

export const getXktData = async (estateId: number, force = false): Promise<{ xktFile: ICondoFile; metadataFile: ICondoFile }> =>
    getClient('basecamp')
        .get(`/bimdata/${estateId}/xkt`, { params: { force } })
        .then(response => response.data);

export const getBimServerMap = async (estateId: number): Promise<IBimServerMap> =>
    getClient('basecamp')
        .get('/bim/bimserver-record', { params: { estateId } })
        .then(response => response.data.bimServerMap);

/*Construction Plan & It's Friends Routes*/
export const getConstructionPlans = async (params: IConstructionPlansReq['Querystring']): Promise<IConstructionPlan[]> =>
    getClient('basecamp')
        .get('/construction-plans', { params })
        .then(r => r.data.constructionPlans);

export const generateConstructionPlansProgress = async (estateId: number): Promise<Partial<IConstructionProgress[]>> =>
    getClient('basecamp')
        .post('/construction-plans/progress', { estateId })
        .then(r => r.data.constructionProgress);

export const getConstructionPlansProgress = async (params: { estateId: number; craft?: ConstructionCraft }): Promise<Partial<IConstructionProgress[]>> =>
    getClient('basecamp')
        .get('/construction-plans/progress', { params })
        .then(r => r.data.constructionProgress);

export const getRenovationCostTrackingForEstate = async (estateId: number): Promise<RenovationCostTrackingPerEstate | null> =>
    getClient('basecamp')
        .get(`/estates/${estateId}/renovation-cost-tracking`)
        .then(r => r.data);

export const getRenovationCostTracking = async (params: IGetRenovationCostTrackingParams): Promise<IRenovationCostTrackingResponse> =>
    getClient('basecamp')
        .get('/renovation/cost-tracking', { params })
        .then(r => r.data);

export const getConstructionPlansMilestones = async (estateId: number): Promise<Partial<IMilestone[]>> =>
    getClient('basecamp')
        .get('/construction-plans/milestones', { params: { estateId } })
        .then(r => r.data.milestones);

export const updateConstructionPlansProgress = async (body: Partial<IConstructionProgress>): Promise<Partial<IConstructionProgress[]>> =>
    getClient('basecamp')
        .put('/construction-plans/progress', body)
        .then(r => r.data.constructionProgress);

export const updateConstructionPlanOvershoot = async (
    constructionPlanId: number,
    data: { allowedOvershoot: number; note: string },
): Promise<Partial<IConstructionPlan>> =>
    getClient('basecamp')
        .put(`/construction-plans/${constructionPlanId}/overshoot`, data)
        .then(r => r.data.constructionPlan);

export const updateRenovationProcessOvershoot = async (
    renovationProcessId: number,
    data: { allowedOvershoot: number; note: string },
): Promise<Partial<IRenovationProcess>> =>
    getClient('basecamp')
        .put(`/processes/renovation/${renovationProcessId}/overshoot`, data)
        .then(r => r.data.renovationProcess);

export const getContractors = async (): Promise<IContractor[]> =>
    getClient('basecamp')
        .get('/contractors')
        .then(r => r.data.contractors);
export const getEstateContractors = async (estateId: number): Promise<IContractor[]> =>
    getClient('basecamp')
        .get(`/estates/${estateId}/contractors`)
        .then(r => r.data.contractors);

export const updateContractor = async (contractorId: number, contractor: IContractor): Promise<IContractor> =>
    getClient('basecamp')
        .patch(`/contractors/${contractorId}`, contractor)
        .then(r => r.data.contractor);

export const createContractor = async (contractor: IContractor): Promise<IContractor> =>
    getClient('basecamp')
        .post('/contractors', contractor)
        .then(r => r.data.contractor);

export const getConstructionPlan = async (planId: number, withRelations?: boolean): Promise<IConstructionPlan> =>
    getClient('basecamp')
        .get(`/construction-plans/${planId}`, { params: { withRelations } })
        .then(r => r.data.constructionPlan);

export const upsertConstructionPlan = async (plan: Partial<IConstructionPlan>): Promise<IConstructionPlan> => {
    const { constructionPlanId } = plan;
    const method = constructionPlanId ? 'put' : 'post';

    return getClient('basecamp')
        .request({
            url: `/construction-plans/${constructionPlanId ?? ''}`,
            method,
            data: plan,
        })
        .then(r => r.data.constructionPlan);
};

export const updateConstructionPlan = async (constructionPlanId: number, data: Partial<IConstructionPlan>): Promise<IConstructionPlan> =>
    getClient('basecamp')
        .patch(`/construction-plans/${constructionPlanId}`, data)
        .then(r => r.data.constructionPlan);

export const processConstructionPlanEvent = async (constructionPlanId: number, event: ConstructionPlanEvents): Promise<IConstructionPlan> =>
    getClient('basecamp')
        .post(`/construction-plans/${constructionPlanId}/process-event`, { event })
        .then(r => r.data.constructionPlan);

export const deleteConstructionPlan = async (planId: number): Promise<void> => getClient('basecamp').delete(`/construction-plans/${planId}`);

export const saveConstructionPlanPosition = async (planId: number, positions: Array<Partial<IConstructionPosition>>): Promise<UiConstructionPosition[]> =>
    getClient('basecamp')
        .post(`/construction-plans/${planId}/positions`, { positions })
        .then(r => r.data.positions.map(p => new UiConstructionPosition(p)));

export const copyConstructionPlan = async (planId: number): Promise<IConstructionPlan> =>
    getClient('basecamp')
        .post(`/construction-plans/${planId}/copy`)
        .then(r => r.data.constructionPlan);

export const deleteConstructionPlanPositions = async (planId: number, positionIds: number[], renovationInspectionId?: number): Promise<void> =>
    getClient('basecamp').delete(`/construction-plans/${planId}/positions`, { data: { positionIds, renovationInspectionId } });

export const rollbackPosition = async (constructionPlanId: number, positionId: number): Promise<UiConstructionPosition> =>
    getClient('basecamp')
        .post<{ position: UiConstructionPosition }>(`/construction-plans/${constructionPlanId}/positions/${positionId}/rollback`)
        .then(({ data }) => new UiConstructionPosition(data.position));

export const getConstructionPlanPositions = async (planId: number, params: Partial<IConstructionPosition> = {}): Promise<UiConstructionPosition[]> =>
    getClient('basecamp')
        .get(`/construction-plans/${planId}/positions`, { params })
        .then(r => (r.data.positions as UiConstructionPosition[]).map(p => new UiConstructionPosition(p)));

export const getCatalogue = async (params: IFindCatalogueItemsQueries): Promise<{ catalogueItems: ICatalogueItem[]; count: number }> =>
    getClient('basecamp')
        .get('/renovation-catalogue', { params })
        .then(r => r.data);

export const fetchPlanCatalogueItems = async (
    constructionPlanId,
    params,
): Promise<{ catalogueItems: ICatalogueItem[] } | Partial<Record<string, Record<string, ICatalogueItem[]>>> | Partial<Record<string, ICatalogueItem[]>>> =>
    getClient('basecamp')
        .get(`/construction-plans/${constructionPlanId}/catalogue-items`, { params })
        .then(r => r.data);

export const deleteCatalogueItems = async (catalogItems: string[]): Promise<void> =>
    getClient('basecamp').delete('/renovation-catalogue', { data: { catalogItems } });

export const sendConstructionPlanReport = async ({ constructionPlanId, ...report }: IConstructionPlanReportParams): Promise<void> =>
    getClient('basecamp').post(`construction-plans/${constructionPlanId}/report`, report);

/**
 * Renovation Inspection
 */
export const fetchRenovationInspections = async (estateId: number, opts?: { withRelations: boolean }): Promise<IRenovationInspection[]> =>
    getClient('basecamp')
        .get('/renovation-inspection', { params: { estateId, withRelations: opts?.withRelations } })
        .then(res => res.data.inspections);

export const fetchInspectionById = async (inspectionId: number, relationOpts: Record<string, boolean>): Promise<IRenovationInspection> =>
    getClient('basecamp')
        .get(`/renovation-inspection/${inspectionId}`, { params: relationOpts })
        .then(res => res.data.inspection);

export const createRenovationInspection = async (inspection: Partial<IRenovationInspection>): Promise<IRenovationInspection> =>
    getClient('basecamp')
        .post('/renovation-inspection', inspection)
        .then(res => res.data.inspection);

export const updateRenovationInspection = async (inspectionId: number, inspection: Partial<IRenovationInspection>): Promise<IRenovationInspection> =>
    getClient('basecamp')
        .patch(`/renovation-inspection/${inspectionId}`, inspection)
        .then(res => res.data.inspection);

export const deleteRenovationInspection = async (inspectionId: number, nullifyRelations?: boolean): Promise<void> =>
    getClient('basecamp').delete(`/renovation-inspection/${inspectionId}`, { params: { nullifyRelations } });

export const saveInspectionPosition = async (inspectionId: number, positions: Array<Partial<IConstructionPosition>>): Promise<UiConstructionPosition[]> =>
    getClient('basecamp')
        .post(`/renovation-inspection/${inspectionId}/positions`, { positions })
        .then(r => r.data.positions.map(p => new UiConstructionPosition(p)));

export const deleteInspectionPositions = async (inspectionId: number, positions: Partial<IConstructionPosition>[]): Promise<void> =>
    getClient('basecamp').delete(`/renovation-inspection/${inspectionId}/positions`, { data: { positions } });

export const getConstructionPlanPositionsByInspectionId = async (
    renovationInspectionId: number,
    params: Partial<IConstructionPosition> = {},
): Promise<UiConstructionPosition[]> =>
    getClient('basecamp')
        .get(`/renovation-inspection/${renovationInspectionId}/positions`, { params })
        .then(r => (r.data.positions as UiConstructionPosition[]).map(p => new UiConstructionPosition(p)));

/*Inspection Questions and Answers*/
export const fetchInspectionQuestions = async (params?: Partial<IInspectionQuestion>): Promise<IInspectionQuestion[]> =>
    getClient('basecamp')
        .get('/inspection-question', { params })
        .then(r => r.data.questions);

export const fetchInspectionAnswers = async (params?: Partial<{ estateId: number; renovationInspectionId: number }>): Promise<IInspectionQuestionAnswer[]> =>
    getClient('basecamp')
        .get('/inspection-question-answers', { params })
        .then(r => r.data.answers);

export const saveInspectionQuestion = async (question: Partial<IInspectionQuestion>): Promise<IInspectionQuestion> =>
    getClient('basecamp')
        .put('/inspection-question', question)
        .then(r => r.data.question);

export const deleteInspectionQuestion = async (questionId: number): Promise<void> => getClient('basecamp').delete(`/inspection-question/${questionId}`);

export const saveInspectionAnswer = async (answers: Partial<IInspectionQuestionAnswer>[]): Promise<IInspectionQuestionAnswer[]> =>
    getClient('basecamp')
        .put(`/inspection-question/answers`, answers)
        .then(r => r.data.answers);

export const sendRenovationInspectionReport = async ({ renovationInspectionId, ...report }: IConstructionSiteReportParams): Promise<void> =>
    getClient('basecamp').post(`renovation-inspection/${renovationInspectionId}/report`, report);

/*Inspection Questions and Answers*/
export const saveCataloguePricing = async (pricingData: Partial<ICatalogueItemPricing>): Promise<ICatalogueItemPricing> =>
    getClient('basecamp')
        .post('/renovation-catalogue/pricing', pricingData)
        .then(r => r.data.pricingRecord);

export const deleteCataloguePricing = async (pricingId: number): Promise<void> => getClient('basecamp').delete(`/renovation-catalogue/pricing/${pricingId}`);

export const getBimDataConfig = async (): Promise<{
    token: string;
    config: { clientId: string; clientSecret: string; bimDataDomain: string; cloudId: number };
}> => {
    return getClient('basecamp')
        .get('/bimdata/config')
        .then(response => response.data);
};

export const processDefectEvent = async (defectId: number, defectEventBody: IDefectEventRequest): Promise<IDefect> =>
    getClient('basecamp')
        .post(`/defects/${defectId}/process-event`, defectEventBody)
        .then(r => r.data.defect);

export const fetchEstateDefects = async ({
    crafts,
    defectsLayerId,
    estateId,
    type,
}: {
    estateId: number;
    type?: DefectType;
    defectsLayerId: number;
    crafts: ConstructionCraft[];
}): Promise<IDefect[]> =>
    getClient('basecamp')
        .get(`/estates/${estateId}/defects`, { params: { defectsLayerId, crafts, type } })
        .then(r => r.data.defects);

export const fetchDefects = async (params?: IFetchDefectsParams): Promise<IDefectsResponse> => {
    const { data } = await getClient('basecamp').get<IDefectsResponse>('/defects', { params });
    return data;
};

export const getDefect = async (defectId: number): Promise<IDefect> =>
    getClient('basecamp')
        .get<{ defect: IDefect }>(`/defects/${defectId}`)
        .then(r => r.data.defect);

export const saveDefect = async (defect: Partial<IDefect>, media?: IMediaFile[], note?: string): Promise<IDefect> =>
    getClient('basecamp')
        .post('/defects', { defect, media, note })
        .then(r => r.data.defect);

export const deleteDefect = async (defectId: number): Promise<void> => getClient('basecamp').delete(`/defects/${defectId}`);

export const fetchDefectsLayers = async (estateId: number): Promise<IDefectsLayer[]> =>
    getClient('basecamp')
        .get('/defects/layers', { params: { estateId } })
        .then(r => r.data.defectsLayers);

export const saveDefectsLayers = async (layer: Partial<IDefectsLayer>): Promise<IDefectsLayer> =>
    getClient('basecamp')
        .post('/defects/layers', layer)
        .then(r => r.data.defectsLayer);

/**
 * Construction Templates
 */
export const fetchConstructionTemplates = async (name?: string[], opts?: { withCatalogueItems?: boolean }): Promise<IConstructionTemplate[]> =>
    getClient('basecamp')
        .get('/construction-templates', { params: { name, withCatalogueItems: opts?.withCatalogueItems } })
        .then(res => res.data.constructionTemplates);

export const fetchConstructionTemplate = async (
    templateId: number,
    opts: { withCatalogueItems?: boolean; withCatalogueItemAvailabilities?: boolean } = {},
): Promise<IConstructionTemplate> =>
    getClient('basecamp')
        .get(`/construction-templates/${templateId}`, { params: opts })
        .then(res => res.data.constructionTemplate);

/**
 * Catalogue Items
 */

export const deleteCatalogueItemFromConstructionTemplate = async (constructionTemplateId: number, catalogueItemId: number): Promise<void> =>
    getClient('basecamp').delete(`/construction-templates/${constructionTemplateId}/catalogue-items/${catalogueItemId}`);

export const createConstructionTemplate = async (name: string, opts?: { copyFromTemplateId?: number }): Promise<IConstructionTemplate> =>
    getClient('basecamp')
        .post('/construction-templates', { name, copyFromTemplateId: opts?.copyFromTemplateId })
        .then(res => res.data);

export const addCatalogueItemsToTemplate = async (
    constructionTemplateId: number,
    catalogueItems: { catalogueItemId: number; ignoredRoomAvailabilities: Record<string, boolean> }[],
): Promise<IConstructionTemplateCatalogueItemRelation> =>
    getClient('basecamp')
        .post(`/construction-templates/${constructionTemplateId}/catalogue-items`, { catalogueItems })
        .then(res => res.data);

export const createCatalogueItem = async (catalogueItem: UpsertCatalogueItemParams): Promise<ICatalogueItem> =>
    getClient('basecamp')
        .post('/catalogue-items', { ...catalogueItem })
        .then(res => res.data.catalogueItem);

export interface IAttachCatalogueItemSubItemsParams {
    optionalSubItemIds?: number[];
    requiredSubItemIds?: number[];
    interiorQualities: InteriorQuality[];
    estateRoomTypes: RoomType[];
}

export const attachCatalogueItemSubItems = async (catalogueItemId: number, params: IAttachCatalogueItemSubItemsParams): Promise<void> =>
    getClient('basecamp').post(`/catalogue-items/${catalogueItemId}/sub-items`, params);

export interface IDetachCatalogueItemSubItemParams {
    requiredSubItemId?: number;
    optionalSubItemId?: number;
    interiorQuality: InteriorQuality;
    estateRoomType: RoomType;
}

export const detachCatalogueItemSubItem = async (catalogueItemId: number, params: IDetachCatalogueItemSubItemParams): Promise<void> =>
    getClient('basecamp').delete(
        `/catalogue-items/${catalogueItemId}/sub-items/estate-room-type/${params.estateRoomType}/interior-quality/${params.interiorQuality}`,
        {
            params: { requiredSubItemId: params.requiredSubItemId, optionalSubItemId: params.optionalSubItemId },
        },
    );

export const getConstructionPlanStats = async (constructionPlanId: number, type: 'room' | 'craft', roomElementId?: number): Promise<IConstructionPlanStats> =>
    getClient('basecamp')
        .get(`/construction-plans/${constructionPlanId}/stats`, {
            params: { type, roomElementId },
        })
        .then(r => r.data.stats);

export const fetchConstructionPlanCostComparison = async ({
    constructionPlanId,
    contractorIds,
    startDate,
    endDate,
    regionName,
}: FetchConstructionPlanCostComparisonParams): Promise<ContractorPricingBreakdown[]> =>
    getClient('basecamp')
        .get(`/construction-plans/${constructionPlanId}/cost-comparison`, {
            params: { contractorIds, startDate, endDate, regionName },
        })
        .then(r => r.data.contractorPricingBreakdowns);

export const fetchWeeklyCostComparisonOfConstructionPlan = async ({
    constructionPlanId,
    contractorIds,
    startDate,
    endDate,
    regionName,
    durationInWeeks,
}: IConstructionPlanWeeklyComparisonRequest): Promise<IConstructionPlanPricingOfWeek[]> =>
    getClient('basecamp')
        .get(`/construction-plans/${constructionPlanId}/weekly-cost-comparison`, {
            params: { contractorIds, startDate, endDate, regionName, durationInWeeks },
        })
        .then(r => r.data);

export const updateCatalogueItemTemplateRelation = async (
    constructionTemplateId: number,
    catalogueItemId: number,
    data: Pick<IConstructionTemplateCatalogueItemRelation, 'ignoredRoomAvailabilities'>,
): Promise<void> =>
    getClient('basecamp')
        .patch(`/construction-templates/${constructionTemplateId}/catalogue-items/${catalogueItemId}`, data)
        .then(r => r.data);

export const fetchExternallyRenovatedEstates = async (
    query?: Partial<IGetRenovationProjectsParams['query'] & IGetRenovationProjectsParams['relationOpts']>,
): Promise<{ renovationProjects: IRenovationProject[]; count: number }> =>
    getClient('basecamp')
        .get(`/renovation/estates/external`, { params: query })
        .then(r => r.data);

export const fetchContractorPricings = async (contractorId: number, opts: { withCatalogueItemPrices: boolean }): Promise<IContractorPricing[]> =>
    getClient('basecamp')
        .get<{ contractorPricings: IContractorPricing[] }>(`/contractors/${contractorId}/pricings`, { params: opts })
        .then(r => r.data.contractorPricings);

export const fetchContractorCatalogueItems = async (contractorId: number): Promise<ICatalogueItem[]> =>
    getClient('basecamp')
        .get<{ catalogueItems: ICatalogueItem[] }>(`/contractors/${contractorId}/catalogue-items`)
        .then(r => r.data.catalogueItems);

export const downloadCatalogueItemXls = async (): Promise<void> => {
    const { data } = await getClient('basecamp').get(`/catalogue-items/xls`, { headers: { Accept: 'application/vnd.ms-excel' }, responseType: 'blob' });
    const url = window.URL.createObjectURL(new Blob([data], { type: 'application/vnd.ms-excel' }));
    openDownloadLink(url, `catalogue-item-pricing.xlsx`);
};

export const updateInspectionQuestionsSequence = async (inspectionQuestions): Promise<void> => {
    await getClient('basecamp').put('/inspection-questions/sequence', {
        inspectionQuestions,
    });
};

export const toggleConstructionPlanActiveStatus = async (constructionPlanId: number, active: boolean): Promise<void> => {
    await getClient('basecamp').put(`/construction-plans/${constructionPlanId}/toggle-status`, {
        active,
    });
};
