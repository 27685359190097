import { getDocument } from '@/api/basecamp.api';
import { createDocumentInspection, deleteDocumentInspection, updateDocumentInspection } from '@/api/document-inspection.api';
import type { DocumentType, IDocument, IDocumentInspection, IFormAnswer, IGetDocumentParams } from '@condo/domain';
import type { ActionTree, GetterTree, MutationTree } from 'vuex';

export interface IDocumentInspectionStore {
    document: IDocument | null;
}

function getInitialState(): IDocumentInspectionStore {
    return {
        document: null,
    };
}

const getters: GetterTree<IDocumentInspectionStore, any> = {
    document(state: IDocumentInspectionStore): IDocument | null {
        return state.document;
    },
};

const actions: ActionTree<IDocumentInspectionStore, any> = {
    async loadDocument({ commit }, { documentId, expand }: { documentId: number; expand: IGetDocumentParams['expand'] }) {
        commit('setDocument', await getDocument(documentId, { expand }));
    },
    async createInspection({ commit }, { documentId, syncWithDocumentType }: { documentId: number; syncWithDocumentType?: DocumentType[] }) {
        const documentInspection = await createDocumentInspection(documentId, syncWithDocumentType);
        commit('setInspection', documentInspection);
    },
    async updateInspection(
        { commit },
        { inspectionId, answers, syncWithDocumentType }: { inspectionId: number; answers: IFormAnswer; syncWithDocumentType?: DocumentType[] },
    ) {
        const documentInspection = await updateDocumentInspection(inspectionId, answers, syncWithDocumentType);
        commit('setInspection', documentInspection);
        return documentInspection;
    },
    async deleteInspection({ commit }, { inspectionId }: { inspectionId: number }) {
        await deleteDocumentInspection(inspectionId);
        commit('setInspection', null);
    },
    flushDocument({ commit }) {
        commit('setDocument', null);
    },
};

const mutations: MutationTree<IDocumentInspectionStore> = {
    setDocument(state, value: IDocument | undefined) {
        state.document = value;
    },
    setInspection(state, value: IDocumentInspection) {
        state.document.documentInspection = value;
    },
};

export const documentInspectionStore = {
    namespaced: true,
    state: getInitialState(),
    actions,
    getters,
    mutations,
};
