import { authManager } from '@/shared/auth-manager';
import { MessageType, showErrorMessage, showMessage } from '@/shared/messages';
import { type ICockpitStore, store } from '@/store';
import type { ICockpitConfig } from '@/types';
import { ErrorCode } from '@condo/domain';
import { type ExternalApi, initExternalApiHTTPClient, externalApi as sdkExternalApi } from '@condo/external-api/sdk';
import { type ListingPersisterApi, initListingPersisterHTTPClient, listingPersisterApi } from '@condo/listing-persister/sdk';
import { type PropxApi, initPropxHTTPClient, propxApi } from '@condo/propx/sdk';
import { type SourcingStorageApi, initSourcingStorageHTTPClient, sourcingStorageApi } from '@condo/sourcing-storage/sdk';
import axios, { type AxiosInstance, type AxiosRequestConfig } from 'axios';
import { isNil } from 'lodash-es';
import qs from 'qs';
import { v4 as uuid } from 'uuid';
import type { Store } from 'vuex';

interface IApiClients {
    basecampMaintenance: AxiosInstance;
    basecamp: AxiosInstance;
    salesPortal: AxiosInstance;
    discoverer: AxiosInstance;
    propx: PropxApi;
    sourcingStorage: SourcingStorageApi;
    listingPersister: ListingPersisterApi;
    externalApi: ExternalApi;
}
declare global {
    interface Window {
        __CLIENTS__: IApiClients;
    }
}

const clients = {
    basecampMaintenance: import.meta?.hot?.data?.basecampMaintenance ?? (window as any).__BASECAMP_MAINTENANCE_CLIENT__ ?? null,
    basecamp: import.meta?.hot?.data?.basecamp ?? (window as any).__BASECAMP_CLIENT__ ?? null,
    salesPortal: import.meta?.hot?.data?.salesPortal ?? (window as any).__SALES_PORTAL_CLIENT__ ?? null,
    discoverer: import.meta?.hot?.data?.discoverer ?? (window as any).__DISCOVERER_CLIENT__ ?? null,
    propx: import.meta?.hot?.data?.propx ?? (window as any).__PROPX_CLIENT__ ?? null,
    sourcingStorage: import.meta?.hot?.data?.propx ?? (window as any).__SOURCING_STORAGE_CLIENT__ ?? null,
    listingPersister: import.meta?.hot?.data?.listingPersister ?? window.__CLIENTS__?.listingPersister ?? null,
    externalApi: import.meta?.hot?.data?.externalApi ?? window.__CLIENTS__?.externalApi ?? null,
} as IApiClients;

window.__CLIENTS__ = window.__CLIENTS__ ?? clients;

interface IDecodedCondoVersion {
    year: number;
    minor: number;
    patch: 'beta' | number;
    sha: string;
}

const decodeVersion = (version: string): IDecodedCondoVersion => {
    const [year, minor, _patch] = version.split('.');
    const [patch, sha] = _patch.split('-');

    return { year: +year, minor: +minor, patch: patch === 'beta' ? patch : +patch, sha } as IDecodedCondoVersion;
};

const compareVersions = (self: IDecodedCondoVersion, other: IDecodedCondoVersion) => {
    const isBeta = self.patch === 'beta' || other.patch === 'beta';
    const sameYear = self.year === other.year;
    const sameMinor = self.minor === other.minor;
    const samePatch = isBeta ? true : self.patch === other.patch;
    const sameSha = isBeta ? true : self.sha === other.sha;

    return sameYear && sameMinor && samePatch && sameSha;
};

const extendAxiosClient = (
    store: Store<ICockpitStore>,
    baseUrlProp:
        | 'S_BASECAMP_URL'
        | 'S_BASECAMP_MAINT_URL'
        | 'S_DISCOVERER_URL'
        | 'S_PROPX_URL'
        | 'S_SOURCING_STORAGE_URL'
        | 'S_SALES_PORTAL_API_URL'
        | 'S_LISTING_PERSISTER_URL'
        | 'S_EXTERNAL_API_URL',
    checkVersionCompat = true,
): AxiosInstance => {
    const client = axios.create({
        paramsSerializer: {
            serialize: params => qs.stringify(params, { arrayFormat: 'repeat' }),
        },
    });

    client.interceptors.request.use(config => {
        const appConfig = store.getters['generalData/config'] as ICockpitConfig;
        const impersonatedUserUid = store.getters['generalData/impersonatedUserId'];
        const dangerouslyEnableSideEffects = store.getters['generalData/dangerouslyEnableSideEffects'];

        config.baseURL = appConfig[baseUrlProp];
        return onRequest(impersonatedUserUid, dangerouslyEnableSideEffects)(config);
    });

    /**
     * Global error handling for Basecamp API
     */
    client.interceptors.response.use(response => {
        if (!checkVersionCompat) {
            return response;
        }
        const backendVersion = response.headers['x-condo-version'];
        if (backendVersion) {
            const appConfig = store.getters['generalData/config'] as ICockpitConfig;
            const decodedBackendVersion = decodeVersion(backendVersion);
            const cockpitVersion = decodeVersion(appConfig.HOUSEHOLD_VERSION);
            if (!compareVersions(cockpitVersion, decodedBackendVersion) && cockpitVersion.patch < decodedBackendVersion.patch) {
                showMessage(
                    `Your CCS-Version got outdated, please refresh your browser and make sure the version <b>👉 ${backendVersion} 👈</b> is shown on the bottom right`,
                    MessageType.Warning,
                    10_000,
                    backendVersion,
                );
            }
        }
        return response;
    }, onRejectedResponse);

    return client;
};

const onRequest =
    (impersonatedUserUid: string, dangerouslyEnableSideEffects?: boolean) =>
    <T extends AxiosRequestConfig>(config: T): T | Promise<T> => {
        if (impersonatedUserUid) {
            config.headers['x-impersonate-user'] = impersonatedUserUid;
        }
        if (dangerouslyEnableSideEffects) {
            config.headers['x-dangerously-enable-side-effects'] = '1';
        }

        config.headers['x-stream-id'] = uuid();

        return authManager.getToken().then(token => {
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }

            return config;
        });
    };

const onRejectedResponse = async (error: any): Promise<any> => {
    if (axios.isCancel(error)) {
        return Promise.reject(error);
    }

    if (!shouldBeHandledInComponent(error)) {
        if (axios.isAxiosError(error)) {
            const streamId = error?.response?.data?.debug?.streamId;
            if (error?.response?.data?.debug?.consoleLink) {
                console.error(`Error happened, here is the cloud-logging link: ${error.response?.data?.debug?.consoleLink}`);
            }
            const errorMessage = [
                error?.response?.data?.data?.context?.cause?.message,
                error?.response?.data?.data?.context?.errors?.[0].message,
                error?.response?.data?.data?.context?.message,
                error?.response?.data?.data?.message,
                error?.response?.data?.message,
                error?.message,
                'Unknown error occurred',
            ].filter(m => m?.length > 0)[0];

            if (error?.response?.data instanceof Blob && error?.response?.data?.type === 'application/json') {
                showErrorMessage({ message: errorMessage, responseData: JSON.parse(await error.response.data.text()), streamId });
            } else {
                showErrorMessage({ message: errorMessage, responseData: error?.response?.data, streamId });
            }
        } else {
            showErrorMessage({ message: error.message });
        }
    }

    return Promise.reject(error);
};

const shouldBeHandledInComponent = (error: any) => {
    const code = error?.response?.data?.data?.context?.code;
    if (error?.config?.skipErrorHandler) {
        return true;
    }

    return !isNil(code) && ([ErrorCode.DuplicateInvoice] as string[]).includes(code);
};

/**
 * @note This is a circular dependency to the store (all stores invoke api-methods
 * which use this class, directly using the store here is not possible)
 */
export const initBasecampApiClient = (store: Store<ICockpitStore>) => {
    clients.basecamp = extendAxiosClient(store, 'S_BASECAMP_URL');
    clients.basecampMaintenance = extendAxiosClient(store, 'S_BASECAMP_MAINT_URL', true);
};

/**
 * @note This is a circular dependency to the store (all stores invoke api-methods
 * which use this class, directly using the store here is not possible)
 */
export const initDiscovererApiClient = (store: Store<ICockpitStore>) => {
    clients.discoverer = extendAxiosClient(store, 'S_DISCOVERER_URL');
};

export const initExternalApiClient = (store: Store<ICockpitStore>) => {
    const appConfig = store.getters['generalData/config'] as ICockpitConfig;
    const impersonatedUserUid = store.getters['generalData/impersonatedUserId'];
    const dangerouslyEnableSideEffects = store.getters['generalData/dangerouslyEnableSideEffects'];
    initExternalApiHTTPClient(appConfig.S_EXTERNAL_API_URL, onRequest(impersonatedUserUid, dangerouslyEnableSideEffects), onRejectedResponse);
    clients.externalApi = sdkExternalApi;
};

export const initListingPersisterClient = (store: Store<ICockpitStore>) => {
    const appConfig = store.getters['generalData/config'] as ICockpitConfig;
    const impersonatedUserUid = store.getters['generalData/impersonatedUserId'];
    const dangerouslyEnableSideEffects = store.getters['generalData/dangerouslyEnableSideEffects'];
    initListingPersisterHTTPClient(appConfig.S_LISTING_PERSISTER_URL, onRequest(impersonatedUserUid, dangerouslyEnableSideEffects), onRejectedResponse);
    clients.listingPersister = listingPersisterApi;
};

export const initSalesPortalApiClient = (store: Store<ICockpitStore>) => {
    clients.salesPortal = extendAxiosClient(store, 'S_SALES_PORTAL_API_URL');
};

export const initPropxApiClient = (store: Store<ICockpitStore>) => {
    const appConfig = store.getters['generalData/config'] as ICockpitConfig;
    const impersonatedUserUid = store.getters['generalData/impersonatedUserId'];
    const dangerouslyEnableSideEffects = store.getters['generalData/dangerouslyEnableSideEffects'];
    initPropxHTTPClient(appConfig.S_PROPX_URL, onRequest(impersonatedUserUid, dangerouslyEnableSideEffects), onRejectedResponse);
    clients.propx = propxApi;
};

export const initSourcingStorageClient = (store: Store<ICockpitStore>) => {
    const appConfig = store.getters['generalData/config'] as ICockpitConfig;
    const impersonatedUserUid = store.getters['generalData/impersonatedUserId'];
    const dangerouslyEnableSideEffects = store.getters['generalData/dangerouslyEnableSideEffects'];
    initSourcingStorageHTTPClient(appConfig.S_SOURCING_STORAGE_URL, onRequest(impersonatedUserUid, dangerouslyEnableSideEffects), onRejectedResponse);
    clients.sourcingStorage = sourcingStorageApi;
};

export const initHttpClients = (cockpitStore: Store<ICockpitStore> = store) => {
    initBasecampApiClient(cockpitStore);
    initDiscovererApiClient(cockpitStore);
    initExternalApiClient(cockpitStore);
    initListingPersisterClient(cockpitStore);
    initSalesPortalApiClient(cockpitStore);
    initPropxApiClient(cockpitStore);
    initSourcingStorageClient(cockpitStore);
};

export const getClient = <TIdentifier extends keyof IApiClients>(identifier: TIdentifier): IApiClients[TIdentifier] => {
    const client = clients[identifier];

    if (!client) {
        initHttpClients();
        return clients[identifier];
    }

    return client;
};

if (import.meta.hot) {
    import.meta.hot.accept(() => {
        import.meta.hot.data.clients = clients;
    });
}
