export const getDefaultFilterQuery = (query?: Record<string, any>): Record<string, any> => {
    if (!query) {
        return {};
    }
    return {
        ...query,
        ...(query.page ? { page: +query.page } : {}),
        ...(query.size ? { size: +query.size } : {}),
        ...(query.skip ? { skip: +query.skip } : {}),
        ...(query.limit ? { limit: +query.limit } : {}),
    };
};
