import { fetchUsers } from '@/api/basecamp.api';
import { processUsersList } from '@/shared';
import { type IContactPerson, type IUser, getContactDisplayName } from '@condo/domain';
import { lowerCase, partition, sortBy } from 'lodash-es';

export type IUsersObject = Record<string, IUser>;
export type IContactUsersObject = Record<number, IUser>;

export interface IUsersStore {
    users: IUsersObject;
    usersByContactId: IContactUsersObject;
}

function getInitialState(): IUsersStore {
    return {
        users: {},
        usersByContactId: {},
    };
}

const state = getInitialState();

const toSelectableUser = (user: IUser) => {
    let base: string;
    if (user.displayName) {
        base = user.displayName;
    } else if (user.firstName && user.lastName) {
        base = `${user.firstName} ${user.lastName}`;
    } else if (user.contact?.fullname) {
        base = user.contact.fullname;
    } else {
        base = user.email;
    }

    if (!user.email.includes('@condogroup.de')) {
        const domain = user.email?.split('@')?.[1]?.split('.')?.[0];
        if (domain) {
            base = `${base} (${domain})`;
        }
    }

    return {
        ...user,
        contactId: user.contact?.contactId,
        displayName: user.disabled ? `🚫 ${base}` : base,
    };
};

const getInternalUsers = (users: IUsersObject) => {
    return Object.entries(users).reduce((acc, [k, user]: [string, IUser]) => {
        const isInternalEmail = user.email.endsWith('condogroup.de') || user.email.endsWith('hh-lg.de');
        if (isInternalEmail && (!!user.firstName || !!user.lastName || !!user.displayName)) {
            return { ...acc, [k]: user };
        }

        return acc;
    }, {} as IUsersObject);
};

const getters = {
    users(state): IUsersObject {
        return state.users;
    },
    usersByContactId(state): IContactUsersObject {
        return state.usersByContactId;
    },

    selectableUsers(state): IUser[] {
        const users = (Object.values(state.users) as IUser[]).filter(user => user.displayName || user.email);
        const [disabledUsers, activeUsers] = partition(users, user => user.disabled);

        const sortedDisabledUsers = sortBy(
            disabledUsers.map(u => toSelectableUser(u)),
            user => lowerCase(user.displayName),
        );
        const sortedActiveUsers = sortBy(
            activeUsers.map(u => toSelectableUser(u)),
            user => lowerCase(user.displayName),
        );

        const res = sortedActiveUsers.concat(sortedDisabledUsers);

        return res;
    },

    activeUsers(state: IUsersStore): IUsersObject {
        return Object.entries(state.users).reduce((acc, [k, user]: [string, IUser]) => {
            if (!user.disabled) {
                return { ...acc, [k]: user };
            }

            return acc;
        }, {} as IUsersObject);
    },

    usersList(state): IUser[] {
        return Object.values(state.users);
    },

    internalUsers(state): IUsersObject {
        return getInternalUsers(state.users);
    },

    internalUserContacts(state): IContactPerson[] {
        const internalUsers = getInternalUsers(state.users);
        return (Object.values(internalUsers) as IUser[])
            .map(u => (u.contact ? { displayName: getContactDisplayName(u.contact), ...u.contact } : null))
            .filter(Boolean) as IContactPerson[];
    },
};

const actions = {
    async loadUsers({ commit }) {
        return fetchUsers().then((users: IUser[]) => {
            const { usersByContact, usersObject, companyFunctions } = processUsersList(users);
            commit('userCompanyFunction/setUserCompanyFunctions', companyFunctions);
            commit('users', usersObject);
            commit('setUsersByContactId', usersByContact);
            return usersObject;
        });
    },
};
const mutations = {
    users(state: IUsersStore, users: IUsersObject) {
        state.users = users;
    },
    setUsersByContactId(state: IUsersStore, usersByContactId: IContactUsersObject) {
        state.usersByContactId = usersByContactId;
    },
};

export const usersStore = {
    namespaced: false,
    state,
    getters,
    actions,
    mutations,
};
