import { CondoEvents, DefectEvents, InvoiceEvents, MFHEvents, ManualProcessRejectionReason, ProcessStateUnion, RentableUnitEvents } from '@condo/domain';

export const eventsAlternativeNames = {
    [DefectEvents.DefectUpdated]: 'Update defect',
    [DefectEvents.DefectMarkedAsValid]: 'Defect is valid',
    [DefectEvents.DefectMarkedAsInvalid]: 'Defect is invalid',
    [DefectEvents.DefectTransfered]: 'Transfer Defect',
    [DefectEvents.DefectProgressStarted]: 'Start progress',
    [DefectEvents.DefectFixed]: 'Defect fixed',
    [DefectEvents.DefectReopened]: 'Reopen',
    [CondoEvents.HandoverDone]: 'Handover Completed',
    [CondoEvents.ConstructionPlanCreated]: 'Renovation Project is Mandated',
    [RentableUnitEvents.HandoverTakesPlace]: 'Handover took place',
};

export const mfhEventsAlternativeNames = {
    [MFHEvents.BzaGenerated]: 'BZA Generated',
    [MFHEvents.BzaUploadedToSubsidy]: 'BZA Uploaded to subsidy',
    [MFHEvents.ZueGenerated]: 'ZUE Generated',
};

export const mfhBuildingEventsAlternativeNames = {};

export const mfhPortfolioEventsAlternativeNames = {};

export const invoiceEventAlternativeNames = {
    [InvoiceEvents.Reset]: 'Reset to new',
};

export const rejectionReasonEnumTranslationMap = {
    [ProcessStateUnion.PreApproved]: {
        [ManualProcessRejectionReason.Offline]: 'CCS does not recognize for some listings that listing has been unpublished',
        [ManualProcessRejectionReason.Location]: 'Municipality is not supported by CONDO (in metropol region)',
        [ManualProcessRejectionReason.Profitability]: 'Transaction team considers investment case unviable',
        [ManualProcessRejectionReason.NotInScope]: 'CCS missed to identify not in scope criteria (rented, in need of refurbishment, special allocations)',
        [ManualProcessRejectionReason.OwnersRightOfResidence]: 'Owners want a right of residence (> 3 months)',
        [ManualProcessRejectionReason.Fake]: 'Fake listings or advertisements',
    },
    [ProcessStateUnion.Contacted]: {
        [ManualProcessRejectionReason.NotInScope]: 'Not in scope criteria or hard knock outs are first identified in the telephone call/exposée',
        [ManualProcessRejectionReason.UnrealisticRent]: 'After receiving the exact address, the rent predicition is no longer realistic, rent is set too high',
        [ManualProcessRejectionReason.NotAvailable]: 'Object is no longer available',
        [ManualProcessRejectionReason.ViewingImpossible]: 'No viewing is possible yet (e.g. still rented or only after moving out)        ',
        [ManualProcessRejectionReason.PriceTooHigh]: 'Price is too high, but already contacting the broker for later negotiation',
        [ManualProcessRejectionReason.SaleWithdrawn]: 'Sale was withdrawn',
        [ManualProcessRejectionReason.OwnersRightOfResidence]: 'Owners want a right of residence',
    },
    [ProcessStateUnion.InspectionScheduled]: {
        [ManualProcessRejectionReason.NotAvailable]: 'Object is no longer available',
        [ManualProcessRejectionReason.Documents]:
            'The documents contain informations which were not available before (leasehold, special apportionments, disputes in the WEG)',
    },
    [ProcessStateUnion.Inspected]: {
        [ManualProcessRejectionReason.RealtorNoFinalDeal]: 'Realtor is unable to close the deal (e.g. do not provide the information to make a final offer)',
        [ManualProcessRejectionReason.EstateInsufficient]:
            'The inspection revealed something that is not apparent from the listing (bad condition, bad impression, mold)',
        [ManualProcessRejectionReason.Documents]:
            'The documents contain informations which were not available before (leasehold, special apportionments, disputes in the WEG)',
        [ManualProcessRejectionReason.PriceTooHigh]: 'Price is too high, however it is apparent that an interested party pays the full price',
        [ManualProcessRejectionReason.OwnersRightOfResidence]: 'Owners want a right of residence',
    },
    [ProcessStateUnion.DraftReceived]: {
        [ManualProcessRejectionReason.OfferTooLow]: 'Offer too low',
        [ManualProcessRejectionReason.OtherBuyer]: 'Realtor decides on other buyer',
        [ManualProcessRejectionReason.CommercialBuyer]: 'Realtors are not selling to commercial buyers (preference for e.g. couples, families)',
        [ManualProcessRejectionReason.Other]: 'Other. Reason to be specified below',
    },
    [ProcessStateUnion.OfferMade]: {
        [ManualProcessRejectionReason.OfferTooLow]: 'Offer too low',
        [ManualProcessRejectionReason.OtherBuyer]: 'Realtor decides on other buyer',
        [ManualProcessRejectionReason.CommercialBuyer]: 'Realtors are not selling to commercial buyers (preference for e.g. couples, families)',
        [ManualProcessRejectionReason.Other]: 'Other. Reason to be specified below',
    },
    [ProcessStateUnion.OfferAccepted]: {
        [ManualProcessRejectionReason.OfferTooLow]: 'Offer too low',
        [ManualProcessRejectionReason.OtherBuyer]: 'Realtor decides on other buyer',
        [ManualProcessRejectionReason.CommercialBuyer]: 'Realtors are not selling to commercial buyers (preference for e.g. couples, families)',
        [ManualProcessRejectionReason.Other]: 'Other. Reason to be specified below',
    },
    [ProcessStateUnion.ExportedToPartner]: {
        [ManualProcessRejectionReason.OfferTooLow]: 'Offer too low',
        [ManualProcessRejectionReason.OtherBuyer]: 'Realtor decides on other buyer',
        [ManualProcessRejectionReason.CommercialBuyer]: 'Realtors are not selling to commercial buyers (preference for e.g. couples, families)',
        [ManualProcessRejectionReason.Other]: 'Other. Reason to be specified below',
    },
};
