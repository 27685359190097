import { TinyEmitter } from 'tiny-emitter';

export class EventBus {
    emitter: TinyEmitter;

    constructor() {
        this.emitter = new TinyEmitter();
    }

    on(eventOrEvents: string | string[], callback: Function, ctx?: any) {
        const events = !Array.isArray(eventOrEvents) ? [eventOrEvents] : eventOrEvents;
        events.forEach(event => this.emitter.on(event, callback, ctx));
        return this;
    }

    off(eventOrEvents: string | string[], callback?: Function) {
        const events = !Array.isArray(eventOrEvents) ? [eventOrEvents] : eventOrEvents;
        events.forEach(event => this.emitter.off(event, callback));
        return this;
    }

    emit(event: string, ...args: any[]): this {
        this.emitter.emit(event, ...args);
        return this;
    }
}

/*Recommended way to communicate events between components
 * which are siblings or have too many layers in
 * the tree. Simple and easy.
 * */
export const eventBus = new EventBus();
