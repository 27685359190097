import { type DefineComponent, markRaw } from 'vue';
import type { ICockpitConfig } from '../../types';

export interface ICustomModal {
    active: boolean;
    props: Record<string, any>;
    events: Record<string, any>;
    width?: number;
    maxWidth?: number;
    height?: number;
    maxHeight?: number;
    fullScreen?: boolean;
    maskClosable?: boolean;
    component: DefineComponent;
}

export interface IGeneralDataStore {
    modal: ICustomModal;
    impersonatedUserId?: string;
    config?: ICockpitConfig;
    isMobile: boolean;
    dangerouslyEnableSideEffects: boolean;
}

const defaultModalProps = (): ICustomModal => ({
    active: true,
    props: {},
    events: {},
    width: 100,
    fullScreen: false,
    maskClosable: true,
    component: markRaw({}) as DefineComponent,
});

function getInitialState(): IGeneralDataStore {
    return {
        config: undefined,
        isMobile: false,
        dangerouslyEnableSideEffects: !!localStorage.getItem('__DANGEROUSLY_ENABLE_SIDE_EFFECTS__'),
        impersonatedUserId: localStorage.getItem('__IMPERSONATED_USER_ID__'),
        modal: {
            ...defaultModalProps(),
            active: false,
        },
    };
}

const state = getInitialState();

const getters = {
    config(state: IGeneralDataStore): ICockpitConfig | undefined {
        return state.config;
    },
    modal(state): ICustomModal {
        return state.modal;
    },
    impersonatedUserId(state): string | undefined {
        return state.impersonatedUserId;
    },
    dangerouslyEnableSideEffects(state): boolean {
        return state.dangerouslyEnableSideEffects;
    },
    isMobile(state): boolean {
        return state.isMobile;
    },
};

const actions = {};
const mutations = {
    setIsMobile(state: IGeneralDataStore, isMobile: boolean) {
        state.isMobile = isMobile;
    },
    setConfig(state: IGeneralDataStore, config: ICockpitConfig) {
        state.config = config;
    },
    setImpersonatedUserId(state: IGeneralDataStore, impersonatedUserId: string | undefined | null) {
        if (impersonatedUserId) {
            localStorage.setItem('__IMPERSONATED_USER_ID__', impersonatedUserId);
        } else {
            localStorage.removeItem('__IMPERSONATED_USER_ID__');
        }
        state.impersonatedUserId = impersonatedUserId;
    },
    toggleDangerouslyEnableSideEffects(state: IGeneralDataStore) {
        const enabled = !state.dangerouslyEnableSideEffects;
        if (enabled) {
            localStorage.setItem('__DANGEROUSLY_ENABLE_SIDE_EFFECTS__', `${enabled}`);
        } else {
            localStorage.removeItem('__DANGEROUSLY_ENABLE_SIDE_EFFECTS__');
        }
        state.dangerouslyEnableSideEffects = enabled;
    },
    hideModal(state: IGeneralDataStore) {
        state.modal.active = false;
    },
    showModal(state: IGeneralDataStore, modal: ICustomModal) {
        state.modal = {
            ...defaultModalProps(),
            ...modal,
            component: markRaw(modal.component),
            active: true,
        };
    },
};

export const generalDataStore = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
