import client from "../../client";
import type { ResponseConfig } from "../../client";
import type { GetHistoryAuditLogsForAggregateQueryParams, GetHistoryAuditLogsForAggregateQueryResponse } from "../models/auditModels/GetHistoryAuditLogsForAggregate";
import type { GetLatestChangesForColumnsPathParams, GetLatestChangesForColumnsQueryResponse } from "../models/auditModels/GetLatestChangesForColumns";
import type { GetNthChangesForColumnsPathParams, GetNthChangesForColumnsQueryParams, GetNthChangesForColumnsQueryResponse } from "../models/auditModels/GetNthChangesForColumns";
import type { FindRenovationEstatesQueryParams, FindRenovationEstatesQueryResponse } from "../models/estateModels/FindRenovationEstates";
import type { FindInvoicesQueryParams, FindInvoicesQueryResponse } from "../models/invoiceModels/FindInvoices";
import type { CleanupCdcOutboxTableMutationRequest, CleanupCdcOutboxTableMutationResponse } from "../models/maintenanceModels/CleanupCdcOutboxTable";
import type { ForceDistributedStateMutationRequest, ForceDistributedStateMutationResponse } from "../models/maintenanceModels/ForceDistributedState";
import type { ResetCdcOutboxFailedEventsMutationRequest, ResetCdcOutboxFailedEventsMutationResponse } from "../models/maintenanceModels/ResetCdcOutboxFailedEvents";
import type { TriggerSnapshotMutationRequest, TriggerSnapshotMutationResponse } from "../models/maintenanceModels/TriggerSnapshot";
import type { GetStatusQueryResponse } from "../models/statusModels/GetStatus";

 /**
 * @description Receives audit-log entries by aggregate-root
 * @link /audit-as-history
 */
export async function getHistoryAuditLogsForAggregate(params?: GetHistoryAuditLogsForAggregateQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetHistoryAuditLogsForAggregateQueryResponse>["data"]> {
    const res = await client<GetHistoryAuditLogsForAggregateQueryResponse>({ method: "get", url: `/audit-as-history`, params, ...options });
    return res.data;
}

 /**
 * @description Provides the last change for a column of a specific row in a table
 * @link /changes/latest/databases/:database/tables/:table/rows/:pk/column/:columns
 */
export async function getLatestChangesForColumns({ database, table, pk, columns, limit }: {
    database: GetLatestChangesForColumnsPathParams["database"];
    table: GetLatestChangesForColumnsPathParams["table"];
    pk: GetLatestChangesForColumnsPathParams["pk"];
    columns: GetLatestChangesForColumnsPathParams["columns"];
    limit: GetLatestChangesForColumnsPathParams["limit"];
}, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetLatestChangesForColumnsQueryResponse>["data"]> {
    const res = await client<GetLatestChangesForColumnsQueryResponse>({ method: "get", url: `/changes/latest/databases/${database}/tables/${table}/rows/${pk}/column/${columns}`, ...options });
    return res.data;
}

 /**
 * @description Provides up to n changes for a column of a specific row in a table.
 * @link /changes/history/databases/:database/tables/:table/rows/:pk/column/:columns
 */
export async function getNthChangesForColumns({ database, table, pk, columns, limit }: {
    database: GetNthChangesForColumnsPathParams["database"];
    table: GetNthChangesForColumnsPathParams["table"];
    pk: GetNthChangesForColumnsPathParams["pk"];
    columns: GetNthChangesForColumnsPathParams["columns"];
    limit: GetNthChangesForColumnsPathParams["limit"];
}, params: GetNthChangesForColumnsQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetNthChangesForColumnsQueryResponse>["data"]> {
    const res = await client<GetNthChangesForColumnsQueryResponse>({ method: "get", url: `/changes/history/databases/${database}/tables/${table}/rows/${pk}/column/${columns}`, params, ...options });
    return res.data;
}

 /**
 * @description Renovation estates overview route
 * @link /renovation/estates
 */
export async function findRenovationEstates(params?: FindRenovationEstatesQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<FindRenovationEstatesQueryResponse>["data"]> {
    const res = await client<FindRenovationEstatesQueryResponse>({ method: "get", url: `/renovation/estates`, params, ...options });
    return res.data;
}

 /**
 * @description Gets status of all major (sub) components
 * @link /status
 */
export async function getStatus(options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<GetStatusQueryResponse>["data"]> {
    const res = await client<GetStatusQueryResponse>({ method: "get", url: `/status`, ...options });
    return res.data;
}

 /**
 * @description Invoices overview route
 * @link /invoices
 */
export async function findInvoices(params?: FindInvoicesQueryParams, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<FindInvoicesQueryResponse>["data"]> {
    const res = await client<FindInvoicesQueryResponse>({ method: "get", url: `/invoices`, params, ...options });
    return res.data;
}

 /**
 * @description Forces a distributed state kind for a specific table to recover stuck nodes in emergencies
 * @link /maintenance/force-distributed-state
 */
export async function forceDistributedState(data: ForceDistributedStateMutationRequest, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<ForceDistributedStateMutationResponse>["data"]> {
    const res = await client<ForceDistributedStateMutationResponse, ForceDistributedStateMutationRequest>({ method: "post", url: `/maintenance/force-distributed-state`, data, ...options });
    return res.data;
}

 /**
 * @description Triggers a cdc-snapshot for all configured tables at the given target-database
 * @link /maintenance/trigger-cdc-snapshot
 */
export async function triggerSnapshot(data: TriggerSnapshotMutationRequest, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<TriggerSnapshotMutationResponse>["data"]> {
    const res = await client<TriggerSnapshotMutationResponse, TriggerSnapshotMutationRequest>({ method: "post", url: `/maintenance/trigger-cdc-snapshot`, data, ...options });
    return res.data;
}

 /**
 * @description Cleanup processed outbox events and perform VACUUM ANALYZE
 * @link /maintenance/cleanup-cdc-outbox-table
 */
export async function cleanupCdcOutboxTable(data?: CleanupCdcOutboxTableMutationRequest, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<CleanupCdcOutboxTableMutationResponse>["data"]> {
    const res = await client<CleanupCdcOutboxTableMutationResponse, CleanupCdcOutboxTableMutationRequest>({ method: "post", url: `/maintenance/cleanup-cdc-outbox-table`, data, ...options });
    return res.data;
}

 /**
 * @description Reset failed cdc outbox events
 * @link /maintenance/reset-failed-cdc-outbox-events
 */
export async function resetCdcOutboxFailedEvents(data?: ResetCdcOutboxFailedEventsMutationRequest, options: Partial<Parameters<typeof client>[0]> = {}): Promise<ResponseConfig<ResetCdcOutboxFailedEventsMutationResponse>["data"]> {
    const res = await client<ResetCdcOutboxFailedEventsMutationResponse, ResetCdcOutboxFailedEventsMutationRequest>({ method: "post", url: `/maintenance/reset-failed-cdc-outbox-events`, data, ...options });
    return res.data;
}

 export const operations = {
    "getHistoryAuditLogsForAggregate": {
        "path": "/audit-as-history",
        "method": "get"
    },
    "getLatestChangesForColumns": {
        "path": "/changes/latest/databases/:database/tables/:table/rows/:pk/column/:columns",
        "method": "get"
    },
    "getNthChangesForColumns": {
        "path": "/changes/history/databases/:database/tables/:table/rows/:pk/column/:columns",
        "method": "get"
    },
    "findRenovationEstates": {
        "path": "/renovation/estates",
        "method": "get"
    },
    "getStatus": {
        "path": "/status",
        "method": "get"
    },
    "findInvoices": {
        "path": "/invoices",
        "method": "get"
    },
    "forceDistributedState": {
        "path": "/maintenance/force-distributed-state",
        "method": "post"
    },
    "triggerSnapshot": {
        "path": "/maintenance/trigger-cdc-snapshot",
        "method": "post"
    },
    "cleanupCDCOutboxTable": {
        "path": "/maintenance/cleanup-cdc-outbox-table",
        "method": "post"
    },
    "resetCDCOutboxFailedEvents": {
        "path": "/maintenance/reset-failed-cdc-outbox-events",
        "method": "post"
    }
} as const;