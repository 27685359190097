import { fetchDefects, fetchDefectsLayers, saveDefectsLayers } from '@/api/basecamp.api';
import type { IDefect, IDefectsLayer } from '@condo/domain';
import type { ActionTree, GetterTree, MutationTree } from 'vuex';

export interface IDefectsStore {
    defects: IDefect[];
    defectLayers: IDefectsLayer[];
    total: number;
}

function getInitialState(): IDefectsStore {
    return {
        defects: [],
        defectLayers: [],
        total: 0,
    };
}

const state = getInitialState();

const getters: GetterTree<IDefectsStore, any> = {
    defects(state: IDefectsStore): IDefect[] {
        return state.defects;
    },
    defectLayers(state: IDefectsStore): IDefectsLayer[] {
        return state.defectLayers;
    },
    total(state: IDefectsStore): number {
        return state.total;
    },
};

const actions: ActionTree<IDefectsStore, any> = {
    async fetchDefectLayers({ commit }, estateId: number) {
        return fetchDefectsLayers(estateId).then(layers => {
            commit('setDefectLayers', layers);
            return saveDefectsLayers;
        });
    },
    async fetchDefects({ commit }, params) {
        return fetchDefects(params)
            .then(response => {
                const { defects, total } = response;
                commit('setDefects', { defects, total });
                return defects;
            })
            .catch(() => {
                commit('setDefects', { defects: [], total: 0 });
            });
    },
};

const mutations: MutationTree<IDefectsStore> = {
    setDefects(state: IDefectsStore, { defects, total }: { defects: IDefect[]; total: number }) {
        state.defects = defects;
        state.total = total;
    },
    setDefectLayers(state: IDefectsStore, defectLayers: IDefectsLayer[]) {
        state.defectLayers = defectLayers;
    },
};

export const defectsStore = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
