import { authManager } from '@/shared/auth-manager';
import { eventBus } from '@/shared/event-bus';
import type { ICockpitStore } from '@/store';
import { CondoUiEvents } from '@/types';
import type { IUser } from '@condo/domain';
import VueGoogleMaps from '@fawmi/vue-google-maps';
import type { AxiosError } from 'axios';
import { debounce } from 'lodash-es';
import type { App } from 'vue';
import { useToast } from 'vue-toastification';
import VueClipboard from 'vue3-clipboard';
import type { Store } from 'vuex';
import { registerCondoSharedComponents } from './ui';

const toast = useToast();

export const bootstrapComponents = (app: App) => {
    registerCondoSharedComponents(app);
};

export const getUserDetails = async (store: Store<ICockpitStore>, userObj?: IUser): Promise<IUser> => {
    const user: IUser = userObj || (await store.dispatch('getUserDetails'));

    eventBus.on(CondoUiEvents.Logout, () => {
        authManager.logOut();
        location.reload();
    });
    return user;
};

export const registerThirdPartyComponents = (app: App) => {
    app.use(VueClipboard, {
        autoSetContainer: true,
        appendToBody: true,
    });

    app.use(VueGoogleMaps, { load: { key: 'AIzaSyCFh64tk9CmYyqVrZ1q06WyQxfu6ZIz5Pg', libraries: ['places'] } });
};

export const configureAppInstance = (app: App) => {
    /**
     * Error Handling,
     * For now we just display any unhandled error to the end user,
     * When we have logging for front-end, we can fire a request from here
     */
    app.config.errorHandler = err => {
        const toastFun = debounce(toast.error, 1000);

        console.error(err);
        const axiosErr = err as AxiosError<any>;
        // We ignore axios errors because they are already handled by `showErrorMessage` in `api-client.ts`
        if (axiosErr?.name === 'AxiosError') {
            return;
        }
        toastFun("Oops! Something's not good. Call your development team!", {
            timeout: 10000,
        });
    };

    app.config.compilerOptions.whitespace = 'preserve';
};
