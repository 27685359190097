import { fetchEvents } from '@/api/basecamp.api';
import type { ICondoEvent, IPaginatedResponse } from '@condo/domain';
import type { ActionTree, GetterTree, Module, MutationTree } from 'vuex';

export interface ICondoEventsStore {
    events: ICondoEvent[];
    pagination?: {
        size: number;
        page: number;
        total: number;
        hasNextPage: boolean;
        hasPreviousPage: boolean;
    };
}

const getInitialState = (): ICondoEventsStore => ({ events: [] });

const state = getInitialState();

const getters: GetterTree<ICondoEventsStore, any> = {
    events: state => state.events,
};

const actions: ActionTree<ICondoEventsStore, any> = {
    getEvents: async ({ commit }, payload): Promise<IPaginatedResponse<ICondoEvent>> => {
        const response = await fetchEvents(payload);
        commit('setEvents', response);
        return response;
    },
};

const mutations: MutationTree<ICondoEventsStore> = {
    setEvents: (state, payload: IPaginatedResponse<ICondoEvent>) => {
        state.events = payload.data;
        state.pagination = payload.pagination;
    },
};

export const eventsStore: Module<ICondoEventsStore, any> = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
