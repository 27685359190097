import { getClient } from '@/api/api-client';
import {
    createSubscription,
    deleteInvoice,
    findInvoiceSubscriptions,
    findInvoices,
    findInvoicesForReview,
    getInvoiceById,
    getInvoiceDictionaries,
    getRelatedInvoices,
    postInvoiceEvent,
    removeInvoiceSubscription,
    saveInvoices,
    sendInvoiceReminder,
    updateInvoice,
    updateInvoiceSubscription,
    updateSubscription,
} from '@/api/finance.api';
import {
    type IFetchInvoicesParams,
    type IFindSubscriptionParams,
    IInvoice,
    type IInvoiceDictionaries,
    type IInvoiceEventReqBody,
    type IInvoicePosResponse,
    type IInvoiceReminderParams,
    type IInvoiceSubscription,
    type ISaveInvoiceParams,
    Invoice,
} from '@condo/domain';
import type { ListingPersisterApi } from '@condo/listing-persister/sdk';
import type { ActionTree, GetterTree, Module, MutationTree } from 'vuex';

interface IRelatedInvoices {
    invoiceId: number;
    invoices: Invoice[];
}

interface IInvoicesStore {
    subscriptions: IInvoiceSubscription[];
    currentInvoice: Invoice;
    invoices: Invoice[];
    related: IRelatedInvoices;
    review: Invoice[];
    total: number;
    loading: boolean;
    dictionaries: IInvoiceDictionaries;
}

function getInitialState(): IInvoicesStore {
    return {
        currentInvoice: null,
        invoices: [],
        subscriptions: [],
        related: { invoiceId: null, invoices: [] },
        review: [],
        total: 0,
        loading: false,
        dictionaries: {
            assignmentThresholdEuro: -1,
            amount: { min: 0, max: 10000 },
        },
    };
}

// initial state
const state = getInitialState();

const getters: GetterTree<IInvoicesStore, any> = {
    currentInvoice(state): Invoice {
        return state.currentInvoice;
    },
    all(state): Invoice[] {
        return state.invoices;
    },
    subscriptions(state): IInvoiceSubscription[] {
        return state.subscriptions;
    },
    review(state): Invoice[] {
        return state.review;
    },
    related(state): IRelatedInvoices {
        return state.related;
    },
    total(state): number {
        return state.total;
    },
    loading(state): boolean {
        return state.loading;
    },
    dictionaries(state): IInvoiceDictionaries {
        return state.dictionaries;
    },
};

const actions: ActionTree<IInvoicesStore, any> = {
    async getInvoicesList({ commit }, params: IFetchInvoicesParams): Promise<{ invoices: Invoice[]; total: number }> {
        commit('setLoading', true);
        return findInvoices(params)
            .then(response => {
                const { invoices, total } = response;
                commit('setInvoices', { invoices, total });

                return { invoices, total };
            })
            .finally(() => {
                commit('setLoading', false);
            });
    },
    async getInvoicesListFromListingPersister({ commit }, params: IFetchInvoicesParams): Promise<{ invoices: Invoice[]; total: number }> {
        commit('setLoading', true);
        return (
            getClient('listingPersister')
                // incompatability between date/stringdate with typebox
                // @todo: fix this type issue
                .findInvoices(params as Parameters<ListingPersisterApi['findInvoices']>[0])
                .then(response => {
                    const { invoices, total } = response;
                    const withClass = invoices.map(inv => new Invoice(inv as IInvoice));

                    commit('setInvoices', { invoices: withClass, total });

                    return { invoices: withClass, total };
                })
                .finally(() => {
                    commit('setLoading', false);
                })
        );
    },
    async getInvoiceSubscriptions({ commit }, params: IFindSubscriptionParams): Promise<{ subscriptions: IInvoiceSubscription[]; total: number }> {
        commit('setLoading', true);
        return findInvoiceSubscriptions(params)
            .then(response => {
                const { data: subscriptions, total } = response;
                commit('setSubscriptions', { subscriptions, total });

                return { subscriptions, total };
            })
            .finally(() => {
                commit('setLoading', false);
            });
    },
    async saveSubscription({ commit }, { subscription, documentId }): Promise<IInvoiceSubscription> {
        commit('setLoading', true);
        if (subscription.invoiceSubscriptionId) {
            return updateSubscription(subscription.invoiceSubscriptionId, subscription, documentId)
                .then(
                    subscription =>
                        // commit('setSubscription', subscription)
                        subscription,
                )
                .finally(() => {
                    commit('setLoading', false);
                });
        }

        return createSubscription(subscription, documentId)
            .then(
                subscription =>
                    // commit('setSubscription', subscription)
                    subscription,
            )
            .finally(() => {
                commit('setLoading', false);
            });
    },

    async getRelatedInvoices({ commit }, invoiceId: number): Promise<IRelatedInvoices> {
        return getRelatedInvoices(invoiceId).then(invoices => {
            const result = { invoiceId, invoices };
            commit('setRelatedInvoices', result);

            return result;
        });
    },

    async getInvoicesForReview({ commit }): Promise<{ invoices: Invoice[]; total: number }> {
        return findInvoicesForReview().then(response => {
            const { invoices, total } = response;
            commit('setReviewInvoices', invoices);

            return { invoices, total };
        });
    },

    getOneInvoice({ commit }, { invoiceId }: { invoiceId: number }): Promise<Invoice> {
        return getInvoiceById(invoiceId).then(invoice => {
            commit('setCurrentInvoice', invoice);
            return invoice;
        });
    },

    getInvoiceDictionaries({ commit }): Promise<IInvoiceDictionaries> {
        return getInvoiceDictionaries().then(dictionaries => {
            commit('setDictionaries', dictionaries);

            return dictionaries;
        });
    },

    async createInvoice({ commit }, documentIds: number | number[]): Promise<IInvoicePosResponse> {
        documentIds = Array.isArray(documentIds) ? documentIds : [documentIds];
        return saveInvoices(documentIds).then(({ invoices, existingInvoices }) => {
            commit('addInvoices', invoices);
            return { invoices, existingInvoices };
        });
    },

    async updateInvoice({ commit }, { invoice, options = {} }: ISaveInvoiceParams): Promise<Invoice> {
        return updateInvoice({ invoice, options }).then(invoice => {
            commit('updateInvoice', invoice);
            commit('setCurrentInvoice', invoice);

            return invoice;
        });
    },
    async updateInvoiceSubscription({ commit }, invoice): Promise<Invoice> {
        return updateInvoiceSubscription(invoice).then(invoice => {
            commit('updateInvoice', invoice);
            commit('setCurrentInvoice', invoice);

            return invoice;
        });
    },
    async removeInvoiceSubscription({ commit }, invoice): Promise<Invoice> {
        return removeInvoiceSubscription(invoice).then(invoice => {
            commit('updateInvoice', invoice);
            commit('setCurrentInvoice', invoice);

            return invoice;
        });
    },

    async deleteInvoice({ commit }, invoiceId: number): Promise<void> {
        return deleteInvoice(invoiceId).then(() => {
            commit('deleteInvoice', invoiceId);
        });
    },

    async postInvoiceEvent({ commit }, { invoiceId, event, payload }: { invoiceId: number } & IInvoiceEventReqBody): Promise<Invoice> {
        return postInvoiceEvent(invoiceId, { event, payload }).then(invoice => {
            commit('setCurrentInvoice', invoice);

            return invoice;
        });
    },

    sendInvoiceReminder(store, params: IInvoiceReminderParams) {
        return sendInvoiceReminder(params);
    },
};

const mutations: MutationTree<IInvoicesStore> = {
    setCurrentInvoice(state, invoice) {
        state.currentInvoice = invoice;
    },
    setInvoices(state, { invoices, total }) {
        state.invoices = invoices;
        state.total = total;
    },

    setSubscriptions(state, { subscriptions, total }) {
        state.subscriptions = subscriptions;
        state.total = total;
    },
    setRelatedInvoices(state, { invoiceId, invoices }: IRelatedInvoices) {
        state.related = { invoiceId, invoices };
    },
    setReviewInvoices(state, invoices) {
        state.review = invoices;
    },
    setDictionaries(state, dictionaries) {
        state.dictionaries = dictionaries;
    },
    addInvoices(state, invoices) {
        invoices = Array.isArray(invoices) ? invoices : [invoices];
        state.invoices.unshift(...invoices.map(i => ({ ...i, isNew: true })));
        state.total += invoices.length;
    },
    updateInvoice(state, updatedInvoice) {
        state.invoices = state.invoices.map(invoice => {
            if (invoice.invoiceId === updatedInvoice.invoiceId) {
                return updatedInvoice;
            }

            return invoice;
        });
    },
    deleteInvoice(state, invoiceId) {
        state.invoices = state.invoices.filter(invoice => invoice.invoiceId !== invoiceId);
    },
    setLoading(state, isLoading: boolean) {
        state.loading = isLoading;
    },
};

export const invoicesStore: Module<IInvoicesStore, any> = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
