import {
    createBankOwnerAccount,
    createLedgerEntry,
    createTransactionReconcilerRules,
    deleteBankAccount,
    deleteDrawdown,
    deleteLoanAccount,
    deleteTransactionReconcilerRule,
    executeActionOnBankOwnerAccount,
    fetchBankOwnerAccount,
    fetchBankOwnerAccounts,
    fetchLedgerAccount,
    fetchLedgerBalanceRecords,
    fetchLoanAccount,
    fetchLoanAccountSummary,
    fetchLoanAccounts,
    fetchLoanDrawdowns,
    findTransactionReconcilerRules,
    getBankAccount,
    getBankAccounts,
    getBankTransactions,
    markDrawdownAsReceived,
    prepareLoanDrawdown,
    saveBankAccount,
    saveBankTransactions,
    saveCashDrawdown,
    saveInvoicesDrawdown,
    saveLoanAccount,
    savePurchasingDrawdown,
    sendLoanDrawdown,
    updateBankOwnerAccount,
    updateTransactionReconcilerRule,
} from '@/api/finance.api';
import { FrontEndPortfolioEvents } from '@/components/portfolio/portfolio.helpers';
import { eventBus } from '@/shared/event-bus';
import type {
    BalanceRecord,
    BankAccount,
    BankAccountExpandOpts,
    BankOwnerAccountAction,
    BankTransaction,
    BankTransactionExpandOpts,
    GeneralLedger,
    GeneralLedgerExpandOpts,
    GetBankAccountParams,
    GetBankTransactionParams,
    IAddBankTransactionData,
    IBankAccount,
    IBankOwnerAccount,
    IBankTransaction,
    IBankTransactionOrAccountReconcilerRule,
    ICashDrawdownData,
    IExtendedBankOwnerAccount,
    IFullDrawdownParams,
    IGeneralLedger,
    IGetLoanAccountParams,
    IInvoicesDrawdownData,
    ILedgerEntry,
    ILoanAccount,
    ILoanDrawdown,
    IMarkAsReceivedParams,
    IPaginationFields,
    IPurchasingPriceDrawdownData,
    LedgerEntry,
    LoanAccount,
    LoanAccountExpandOpts,
    LoanAccountFieldsSummary,
    LoanDrawdown,
    PortfolioLoanAccount,
} from '@condo/domain';
import { uniqBy } from 'lodash-es';
import type { ActionTree, GetterTree, Module, MutationTree } from 'vuex';

/*TODO: move fields connected to one entity to sub-store*/
export interface IFinanceStore {
    reconcilerRules: IBankTransactionOrAccountReconcilerRule[];
    bankOwnerAccounts: {
        byId: Record<number, IBankOwnerAccount>;
        all: number[];
        current: IBankOwnerAccount | null;
    };
    bankAccounts: BankAccount[];
    bankAccountsCounter: number;
    /*Current Bank Account view account*/
    bankAccount: BankAccount;
    loan: LoanAccount | PortfolioLoanAccount;
    bankTransactions: BankTransaction[];
    bankTransactionCounter: number;
    /*One Loan Account Ledger Account*/
    generalLedger: GeneralLedger;
    /*Loan List*/
    loanAccounts: LoanAccount[];
    /*One Loan Account Ledger Entries*/
    ledgerEntries: LedgerEntry[];
    /*One Loan Account balance records*/
    balanceRecords: BalanceRecord[];
    /*One Loan Account drawdowns*/
    loanDrawdowns: LoanDrawdown[];
    loanAccountSummary: LoanAccountFieldsSummary;
}

export interface ISaveBankTransaction {
    bankTransaction: Partial<IBankTransaction>;
    additionalData: IAddBankTransactionData;
}

const getters: GetterTree<IFinanceStore, any> = {
    reconcilerRules(state: IFinanceStore): IBankTransactionOrAccountReconcilerRule[] {
        return state.reconcilerRules;
    },
    bankOwnerAccounts(state: IFinanceStore): IBankOwnerAccount[] {
        return state.bankOwnerAccounts.all.map(id => state.bankOwnerAccounts.byId[id]);
    },
    bankOwnerAccount: (state: IFinanceStore) => state.bankOwnerAccounts.current,
    bankAccounts(state: IFinanceStore): BankAccount[] {
        return state.bankAccounts;
    },
    bankAccountsCounter(state: IFinanceStore): number {
        return state.bankAccountsCounter;
    },
    bankAccount(state: IFinanceStore): BankAccount {
        return state.bankAccount;
    },
    loanAccount(state: IFinanceStore): LoanAccount | PortfolioLoanAccount {
        return state.loan;
    },
    loanAccounts(state: IFinanceStore): LoanAccount[] {
        return state.loanAccounts;
    },
    bankTransactions(state: IFinanceStore): BankTransaction[] {
        return state.bankTransactions;
    },
    bankTransactionsCounter(state: IFinanceStore): number {
        return state.bankTransactionCounter;
    },
    generalLedger(state: IFinanceStore): GeneralLedger {
        return state.generalLedger;
    },
    ledgerEntries(state: IFinanceStore): LedgerEntry[] {
        return state.ledgerEntries;
    },
    balanceRecords(state: IFinanceStore): BalanceRecord[] {
        return state.balanceRecords;
    },
    loanDrawdowns(state: IFinanceStore): LoanDrawdown[] {
        return state.loanDrawdowns;
    },
    loanAccountSummary(state: IFinanceStore): LoanAccountFieldsSummary {
        return state.loanAccountSummary;
    },
};

const actions: ActionTree<IFinanceStore, any> = {
    async createTransactionReconcilerRule({ commit }, { opts, rule }) {
        return createTransactionReconcilerRules([rule], opts).then(rules => {
            commit('addBankTransactionReconcilerRules', rules);
            return rules[0];
        });
    },
    async createTransactionReconcilerRules({ commit }, { opts, rules }) {
        return createTransactionReconcilerRules(rules, opts).then(rules => {
            commit('addBankTransactionReconcilerRules', rules);
            return rules;
        });
    },
    async deleteTransactionReconcilerRule({ commit }, ruleId: number) {
        return deleteTransactionReconcilerRule(ruleId).then(() => {
            commit('deleteTransactionReconcilerRule', ruleId);
        });
    },
    async updateTransactionReconcilerRule({ commit }, { ruleId, rule, opts }) {
        return updateTransactionReconcilerRule(ruleId, rule, opts).then(rule => {
            commit('updateTransactionReconcilerRule', { ruleId, rule });
            return rule;
        });
    },

    async findTransactionReconcilerRules({ commit }, filter) {
        return findTransactionReconcilerRules(filter).then(rules => {
            commit('setBankTransactionReconcilerRules', rules);
            return rules;
        });
    },

    async createBankOwnerAccount({ commit }, params: IExtendedBankOwnerAccount) {
        return createBankOwnerAccount(params).then(bankOwnerAccount => {
            commit('setBankOwnerAccounts', [bankOwnerAccount]);
        });
    },
    async updateBankOwnerAccount({ commit }, params: { bankOwnerAccountId: number; data: IExtendedBankOwnerAccount }) {
        return updateBankOwnerAccount(params.bankOwnerAccountId, params.data).then(bankOwnerAccount => {
            commit('setBankOwnerAccounts', [bankOwnerAccount]);
        });
    },
    async executeActionOnBankOwnerAccount({ commit }, params: { bankOwnerAccountId: number; action: BankOwnerAccountAction }) {
        return executeActionOnBankOwnerAccount(params.bankOwnerAccountId, params.action).then(bankOwnerAccount => {
            commit('setBankOwnerAccounts', [bankOwnerAccount]);
        });
    },

    async getBankOwnerAccounts({ commit }) {
        return fetchBankOwnerAccounts().then(bankOwnerAccounts => {
            commit('setBankOwnerAccounts', bankOwnerAccounts);
        });
    },

    async getBankOwnerAccountById({ commit }, bankOwnerAccountId: number) {
        return fetchBankOwnerAccount(bankOwnerAccountId).then(bankOwnerAccount => {
            commit('setBankOwnerAccounts', [bankOwnerAccount]);
            commit('setCurrentBankOwnerAccount', bankOwnerAccount);
        });
    },

    async getBankAccounts({ commit }, params: { query: Partial<GetBankAccountParams>; expandOpts?: BankAccountExpandOpts; pagination?: IPaginationFields }) {
        return getBankAccounts(params.query, params.expandOpts, params.pagination).then(({ bankAccounts, counter }) => {
            commit('setBankAccounts', bankAccounts);
            commit('setBankAccountsCounter', counter);
        });
    },

    async saveBankAccount(actions, params: Partial<IBankAccount>) {
        return saveBankAccount(params);
    },

    async getBankAccount({ commit }, opts: { bankAccountId: number; expandOpts?: BankAccountExpandOpts }) {
        const expandOpts: BankAccountExpandOpts = {
            select: ['*'],
            expand: {
                bank: {
                    select: ['*'],
                },
                contactPerson: {
                    select: ['*'],
                },
                company: {
                    select: ['*'],
                },
                estateBankAccounts: {
                    select: ['*'],
                    expand: {
                        estate: {
                            select: ['estateId', 'condoUuid'],
                        },
                    },
                },
                investorInfo: {
                    select: ['investorInfoId', 'investorBankAccountId'],
                },
            },
        };
        return getBankAccount(opts.bankAccountId, expandOpts).then(bankAccount => commit('setBankAccount', bankAccount));
    },

    async deleteBankAccount(actions, bankAccountId: number) {
        return deleteBankAccount(bankAccountId);
    },

    async fetchLoanAccount({ commit }, data: IGetLoanAccountParams): Promise<LoanAccount | undefined> {
        return fetchLoanAccount(data.loanAccountId, { expandOpts: data.expandOpts }).then(loan => {
            commit('setLoan', loan);
            return loan;
        });
    },
    async fetchLoanAccountSummary({ commit }, loanAccountId: number): Promise<LoanAccountFieldsSummary | undefined> {
        return fetchLoanAccountSummary(loanAccountId).then(summary => {
            commit('setLoanAccountSummary', summary);
            return summary;
        });
    },
    async fetchLoanDrawdowns({ commit, state }, req?: Partial<Omit<IFullDrawdownParams, 'loanDrawdownId'>> & Partial<ILoanDrawdown>): Promise<LoanDrawdown[]> {
        const { loanAccountId, ...rest } = req ?? {};
        return fetchLoanDrawdowns(loanAccountId ?? state.loan.loanAccountId, rest).then(drawdowns => {
            commit('setDrawdowns', drawdowns);
            return drawdowns;
        });
    },
    async savePurchasingDrawdown({ dispatch }, { loanAccountId, data }: { loanAccountId: number; data: IPurchasingPriceDrawdownData }) {
        return savePurchasingDrawdown(loanAccountId, data).then(() => dispatch('fetchLoanDrawdowns', loanAccountId));
    },
    async saveInvoicesDrawdown({ dispatch }, { loanAccountId, data }: { loanAccountId: number; data: IInvoicesDrawdownData }) {
        return saveInvoicesDrawdown(loanAccountId, data).then(() => dispatch('fetchLoanDrawdowns', loanAccountId));
    },
    async saveCashDrawdown({ dispatch }, { loanAccountId, data }: { loanAccountId: number; data: ICashDrawdownData }) {
        return saveCashDrawdown(loanAccountId, data).then(() => dispatch('fetchLoanDrawdowns', loanAccountId));
    },

    async deleteDrawdown({ dispatch, state }, req: IFullDrawdownParams) {
        return deleteDrawdown(req).then(() => dispatch('fetchLoanDrawdowns', { loanAccountId: state.loan.loanAccountId }));
    },

    /**
     * @param {any} dispatch
     * @param {IFullDrawdownParams & {send: boolean}} req
     * @returns {Promise<LoanDrawdown>}
     */
    async prepareLoanDrawdown({ dispatch }, req: IFullDrawdownParams & { send: boolean }) {
        return prepareLoanDrawdown(req).then(result => {
            dispatch('fetchLoanDrawdowns', { loanAccountId: req.loanAccountId });
            return result;
        });
    },
    /**
     * Send drawdown request
     * @param {any} dispatch
     * @param {{loanAccountId: number, loanDrawdownId: number, message: string}} data
     * @returns {Promise<void>}
     */
    async sendLoanDrawdown({ dispatch }, data: { loanAccountId: number; loanDrawdownId: number; message: string }) {
        return sendLoanDrawdown(data).then(() => dispatch('fetchLoanDrawdowns', data.loanAccountId));
    },

    async fetchBankTransactions({ commit }, opts: { query: GetBankTransactionParams; pagination?: IPaginationFields; expandOpts?: BankTransactionExpandOpts }) {
        return getBankTransactions(opts.query, opts.expandOpts, opts.pagination).then(({ bankTransactions, counter }) => {
            commit('setBankTransactionCounter', counter);
            commit('setBankTransaction', bankTransactions);
        });
    },

    async saveBankTransactions({ dispatch }, { bankTransaction, additionalData }: ISaveBankTransaction) {
        return saveBankTransactions(bankTransaction, additionalData).then(() =>
            dispatch('fetchBankTransactions', { query: { bankAccountId: bankTransaction.bankAccountId } }),
        );
    },

    async fetchGeneralLedger({ commit }, opts: { query: Partial<IGeneralLedger>; expandOpts: GeneralLedgerExpandOpts }): Promise<GeneralLedger> {
        return fetchLedgerAccount(opts.query, opts.expandOpts).then((ledger: GeneralLedger) => {
            commit('setGeneralLedger', ledger);
            return ledger;
        });
    },

    async fetchLoanAccounts({ commit }, params: { query: Partial<ILoanAccount>; expandOpts?: LoanAccountExpandOpts }) {
        return fetchLoanAccounts(params.query, params.expandOpts).then((loanAccounts: LoanAccount[]) => commit('setLoanAccounts', loanAccounts));
    },

    async saveLoanAccount(actions, data: ILoanAccount) {
        return saveLoanAccount(data).then(loanAccount => loanAccount);
    },

    async markDrawdownAsReceived({ dispatch }, data: IFullDrawdownParams & IMarkAsReceivedParams) {
        const { loanAccountId, loanDrawdownId, ...rest } = data;
        return markDrawdownAsReceived({ loanAccountId, loanDrawdownId }, rest).then(() => {
            eventBus.emit(FrontEndPortfolioEvents.DrawdownUpdated);
            const common = { pagination: { page: 1, size: 500 } };
            dispatch('fetchGeneralLedgerEntries', common);
            dispatch('fetchGeneralLedgerBalanceRecords', common);
        });
    },

    async deleteLoanAccount({ dispatch }, loanAccountId: number) {
        return deleteLoanAccount(loanAccountId).then(() => dispatch('fetchLoanAccounts'));
    },

    async fetchGeneralLedgerBalanceRecords(
        { commit, state },
        opts: {
            query?: { generalLedgerId?: number };
            pagination?: IPaginationFields;
        },
    ) {
        const generalLedgerId = opts.query?.generalLedgerId ?? state.generalLedger.generalLedgerId;
        return fetchLedgerBalanceRecords(generalLedgerId, opts.pagination).then((entries: BalanceRecord[]) => {
            commit('setBalanceRecords', entries);
            return entries;
        });
    },

    async addEntryManually({ dispatch }, data: Partial<ILedgerEntry>) {
        const { generalLedgerId, ...rest } = data;
        return createLedgerEntry(generalLedgerId, rest).then(() => dispatch('fetchGeneralLedgerEntries', { generalLedgerId: data.generalLedgerId }));
    },
};

const mutations: MutationTree<IFinanceStore> = {
    setBankOwnerAccounts(state: IFinanceStore, bankOwnerAccounts: IBankOwnerAccount[]) {
        const bankAccountsById = bankOwnerAccounts.reduce((acc, ba) => ({ ...acc, [ba.id!]: ba }), state.bankOwnerAccounts.byId);
        state.bankOwnerAccounts = {
            ...state.bankOwnerAccounts,
            current: (state.bankOwnerAccounts.current && bankAccountsById[state.bankOwnerAccounts.current.id]) || undefined,
            byId: bankAccountsById,
            all: Object.keys(bankAccountsById).map(Number),
        };
    },
    setCurrentBankOwnerAccount(state: IFinanceStore, bankOwnerAccount: IBankOwnerAccount) {
        state.bankOwnerAccounts.current = bankOwnerAccount;
    },
    setBankAccounts(state: IFinanceStore, bankAccounts: BankAccount[]) {
        state.bankAccounts = bankAccounts;
    },
    setBankAccountsCounter(state: IFinanceStore, bankAccountsCounter: number) {
        state.bankAccountsCounter = bankAccountsCounter;
    },
    setLoan(state: IFinanceStore, loan: PortfolioLoanAccount) {
        state.loan = loan;
    },
    setDrawdowns(state: IFinanceStore, drawdowns: LoanDrawdown[]) {
        state.loanDrawdowns = drawdowns;
    },
    addLoanDrawdown(state: IFinanceStore, loanDrawdown: LoanDrawdown) {
        state.loanDrawdowns.push(loanDrawdown);
    },
    setLoanAccountSummary(state: IFinanceStore, sumamry: LoanAccountFieldsSummary) {
        state.loanAccountSummary = sumamry;
    },
    setBankTransaction(state: IFinanceStore, bankTransactions: BankTransaction[]) {
        state.bankTransactions = bankTransactions;
    },
    setBankTransactionCounter(state: IFinanceStore, counter: number) {
        state.bankTransactionCounter = counter;
    },
    setGeneralLedger(state: IFinanceStore, generalLedger: GeneralLedger) {
        state.generalLedger = generalLedger;
    },
    setLoanAccounts(state: IFinanceStore, loanAccounts: LoanAccount[]) {
        state.loanAccounts = loanAccounts;
    },
    setBankAccount(state: IFinanceStore, bankAccount: BankAccount) {
        state.bankAccount = bankAccount;
    },
    setLedgerEntries(state: IFinanceStore, entries: LedgerEntry[]) {
        state.ledgerEntries = entries;
    },
    setBalanceRecords(state: IFinanceStore, balanceRecords: BalanceRecord[]) {
        state.balanceRecords = balanceRecords;
    },
    resetAccountView(state: IFinanceStore) {
        state.balanceRecords = [];
        state.ledgerEntries = [];
        state.loan = null;
        state.generalLedger = null;
    },
    addBankTransactionReconcilerRules(state: IFinanceStore, rules: IBankTransactionOrAccountReconcilerRule[]) {
        state.reconcilerRules = uniqBy<IBankTransactionOrAccountReconcilerRule>([...rules, ...state.reconcilerRules], 'reconcilerRuleId');
    },
    setBankTransactionReconcilerRules(state: IFinanceStore, rules: IBankTransactionOrAccountReconcilerRule[]) {
        state.reconcilerRules = rules;
    },
    deleteTransactionReconcilerRule(state: IFinanceStore, ruleId: number) {
        state.reconcilerRules = state.reconcilerRules.filter(rule => rule.reconcilerRuleId !== ruleId);
    },
    updateTransactionReconcilerRule(state: IFinanceStore, { ruleId, rule }) {
        const ruleIdx = state.reconcilerRules.findIndex(rule => rule.reconcilerRuleId === ruleId);
        let nextRules = state.reconcilerRules;
        if (ruleIdx >= 0) {
            nextRules[ruleIdx] = rule;
        } else {
            nextRules = nextRules.concat(rule);
        }

        state.reconcilerRules = nextRules;
    },
};

export const financeStore: Module<IFinanceStore, any> = {
    namespaced: true,
    state: {
        bankOwnerAccounts: { all: [], byId: {}, current: null },
        reconcilerRules: [],
        bankAccounts: [],
        loan: null,
        bankTransactions: [],
        generalLedger: null,
        loanAccounts: [],
        bankAccount: null,
        ledgerEntries: [],
        balanceRecords: [],
        loanDrawdowns: [],
        loanAccountSummary: {} as LoanAccountFieldsSummary,
        bankTransactionCounter: 0,
        bankAccountsCounter: 0,
    },
    getters,
    actions,
    mutations,
};
