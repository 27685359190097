import { getStrippedCCSRoutes } from '@condo/domain';
import type { RouteRecordRaw } from 'vue-router';
import { getDefaultFilterQuery } from './route-helpers';

const CCSRouteMap = getStrippedCCSRoutes();

export const accountingRoutes: RouteRecordRaw = {
    ...CCSRouteMap.accounting,
    component: () => import('@/views/Accounting.vue'),
    redirect: `${CCSRouteMap.accounting.path}/${CCSRouteMap.invoices.path}`,
    children: [
        {
            ...CCSRouteMap.invoices,
            component: () => import('@/components/accounting/InvoiceList.vue'),
        },
        {
            ...CCSRouteMap.subscriptions,
            component: () => import('@/components/accounting/SubscriptionsList.vue'),
            props: route => ({ filter: getDefaultFilterQuery(route.query) }),
        },
        {
            ...CCSRouteMap['subscription.details'],
            component: () => import('@/components/accounting/InvoiceList.vue'),
            props: route => ({ filter: getDefaultFilterQuery(route.query) }),
        },
        {
            ...CCSRouteMap['invoice.details'],
            component: () => import('@/components/accounting/invoice-details/InvoiceDetails.vue'),
            props: route => ({
                ...route.params,
                ...route.query,
            }),
        },
    ],
};
