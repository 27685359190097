import {
    BalanceRecord,
    BankAccount,
    type BankAccountExpandOpts,
    type BankOwnerAccountAction,
    BankTransaction,
    type BankTransactionExpandOpts,
    type GeneralLedger,
    type GeneralLedgerExpandOpts,
    type GetBankAccountParams,
    type GetBankTransactionParams,
    type IAcceptOfferData,
    type IAccounting,
    type IAddBankTransactionData,
    type IAnyBankingReconcilerRule,
    type IBankAccount,
    type IBankTransaction,
    type IBaseLedgerEntry,
    type ICashDrawdownData,
    type ICreateUpdatePortfolioReq,
    type IEstate,
    type IExtendedBankOwnerAccount,
    type IFetchInvoicesParams,
    type IFetchTransactionRuleOpts,
    type IFinancingBank,
    type IFindEstateInvestmentsParams,
    type IFindSubscriptionParams,
    type IFullDrawdownParams,
    type IGeneralLedger,
    type IGetLoanAccountParams,
    type IInvestment,
    type IInvestmentRequest,
    type IInvestorLedgerStatement,
    type IInvoice,
    type IInvoiceDictionaries,
    type IInvoiceEventReqBody,
    type IInvoicePosResponse,
    type IInvoiceReminderParams,
    type IInvoiceResponse,
    type IInvoiceSubscription,
    type IInvoiceSubscriptionsResponse,
    type IInvoicesDrawdownData,
    type ILedgerEntry,
    type ILoanAccount,
    type ILoanDrawdown,
    type ILoanOffer,
    type IMarkAsReceivedParams,
    type IMatchIbanToBankParams,
    type IMatchIbanToBankResponse,
    type IPaginationFields,
    type IPaymentOrder,
    type IPortfolio,
    type IPortfolioEstatesQuery,
    type IPurchasingPriceDrawdownData,
    type ISaveInvoiceParams,
    type ISaveOfferParams,
    type IUpdatePortfolioStatusParams,
    type InvestmentCaseType,
    InvestorLedgerStatement,
    Invoice,
    type LedgerEntryExpandOpts,
    LedgerFactory,
    type LoanAccount,
    type LoanAccountExpandOpts,
    LoanAccountFactory,
    type LoanAccountFieldsSummary,
    LoanDrawdown,
    LoanOffer,
    PaymentOrder,
    Portfolio,
} from '@condo/domain';
import { extractDate } from '@condo/helpers';
import axios, { AxiosRequestConfig } from 'axios';
import { omit as _omit } from 'lodash-es';
import { openDownloadLink, showErrorMessage } from '../shared';
import { getClient } from './api-client';

/* Reconciler rule */
export const findTransactionReconcilerRules = async (params): Promise<IAnyBankingReconcilerRule[]> =>
    getClient('basecamp')
        .get('/finance/transaction-rules', { params })
        .then(r => r.data.transactionRules);

export const updateTransactionReconcilerRule = async (
    ruleId: number,
    rule: Partial<IAnyBankingReconcilerRule>,
    opts?: IFetchTransactionRuleOpts,
): Promise<IAnyBankingReconcilerRule> =>
    getClient('basecamp')
        .put(`/finance/transaction-rules/${ruleId}`, {
            rule,
            opts,
        })
        .then(r => r.data.transactionRule);

export const createTransactionReconcilerRules = async (
    rules: Partial<IAnyBankingReconcilerRule>[],
    opts?: IFetchTransactionRuleOpts,
): Promise<IAnyBankingReconcilerRule[]> =>
    getClient('basecamp')
        .post('/finance/transaction-rules', {
            rules,
            opts,
        })
        .then(r => r.data.transactionRules);

export const reconcileBankTransaction = async (params: { bankTransactionId: number; transactionUpdateMode?: string }): Promise<BankTransaction> =>
    getClient('basecamp')
        .post(`/finance/transactions/${params.bankTransactionId}/reconcile`, undefined, { params: { transactionUpdateMode: params.transactionUpdateMode } })
        .then(r => new BankTransaction(r.data.bankTransaction));

export const reRunReconciler = async (): Promise<any[]> =>
    getClient('basecamp')
        .post('/finance/transaction-rules/run', {})
        .then(r => r.data);

export const deleteTransactionReconcilerRule = async (ruleId: number): Promise<void> => getClient('basecamp').delete(`/finance/transaction-rules/${ruleId}`);
export const deleteTransactionReconcilerRules = async (params: {
    rentId?: number;
    ruleIds?: number[];
    investorInfoId?: number;
    rentTenancyId?: number;
}): Promise<void> => getClient('basecamp').delete(`/finance/transaction-rules`, { params });

/* Invoice-Subscriptions */
export const findInvoiceSubscriptions = async (params?: IFindSubscriptionParams): Promise<IInvoiceSubscriptionsResponse> =>
    getClient('basecamp')
        .get('/invoice-subscriptions', { params })
        .then(r => r.data);

export const createSubscription = async (subscription: IInvoiceSubscription, documentId?: number): Promise<IInvoiceSubscription> =>
    getClient('basecamp')
        .post('/invoice-subscriptions', { subscription, documentId })
        .then(r => r.data.subscription);

export const updateSubscription = async (subscriptionId: number, subscription: IInvoiceSubscription, documentId?: number): Promise<IInvoiceSubscription> =>
    getClient('basecamp')
        .patch(`/invoice-subscriptions/${subscriptionId}`, { subscription, documentId })
        .then(r => r.data.subscription);

/* Invoices */
export const findInvoices = async (params?: IFetchInvoicesParams): Promise<IInvoiceResponse> => {
    return getClient('basecamp')
        .get('/invoice', { params })
        .then(r => ({
            invoices: Invoice.createFromList(r.data.invoices),
            total: r.data.total,
        }));
};

export const getRelatedInvoices = async (invoiceId: number): Promise<Invoice[]> =>
    getClient('basecamp')
        .get(`/invoice/${invoiceId}/related`)
        .then(res => Invoice.createFromList(res.data.invoices));

export const findInvoicesForReview = async (): Promise<IInvoiceResponse> =>
    getClient('basecamp')
        .get('/invoice-for-review')
        .then(r => ({
            invoices: Invoice.createFromList(r.data.invoices),
            total: r.data.total,
        }));

export const findInvoicesForExternalReview = async (): Promise<IInvoice[]> =>
    getClient('basecamp')
        .get('/invoice-for-external-review')
        .then(r => Invoice.createFromList(r.data.invoices));

export const getInvoiceById = async (invoiceId: number): Promise<Invoice> =>
    getClient('basecamp')
        .get(`/invoice/${invoiceId}`)
        .then(res => new Invoice(res.data.invoice));

export const getInvoiceDictionaries = async (): Promise<IInvoiceDictionaries> =>
    getClient('basecamp')
        .get('/invoice/dictionaries')
        .then(res => res.data);

export const saveInvoices = async (documentIds: number[]): Promise<IInvoicePosResponse> =>
    getClient('basecamp')
        .post('/invoice', { documentIds })
        .then(r => ({
            invoices: r.data.invoices.map(invoice => new Invoice(invoice)),
            existingInvoices: r.data.existingInvoices.map(invoice => new Invoice(invoice)),
        }));

export const updateInvoice = async ({ invoice: { invoiceId, ...invoiceFields }, options }: ISaveInvoiceParams): Promise<Invoice> =>
    getClient('basecamp')
        .patch(`/invoice/${invoiceId}`, { invoice: invoiceFields, options })
        .then(r => new Invoice(r.data.invoice));

export const updateInvoiceSubscription = async ({ invoiceId, subscriptionId }): Promise<Invoice> =>
    getClient('basecamp')
        .patch(`/invoice/${invoiceId}/subscriptions`, { subscriptionId })
        .then(r => new Invoice(r.data.invoice));

export const removeInvoiceSubscription = async ({ invoiceId }): Promise<Invoice> =>
    getClient('basecamp')
        .delete(`/invoice/${invoiceId}/subscriptions`)
        .then(r => new Invoice(r.data.invoice));

export const deleteInvoice = async (invoiceId: number): Promise<void> => getClient('basecamp').delete(`/invoice/${invoiceId}`);

export const postInvoiceEvent = async (invoiceId: number, body: IInvoiceEventReqBody): Promise<Invoice> =>
    getClient('basecamp')
        .post(`/invoice/${invoiceId}/events`, body)
        .then(r => new Invoice(r.data.invoice));

export const sendInvoiceReminder = async (params: IInvoiceReminderParams) => {
    const { invoiceId, ...data } = params;

    return getClient('basecamp').post(`/invoice/${invoiceId}/reminder`, data);
};

export const updateInvestment = async (params: IInvestmentRequest): Promise<IInvestment> => {
    const { estateId, predictionId, investmentId } = params;
    const body = _omit(params, [
        'estateId',
        'predictionId',
        'investmentId',
        'userId',
        'uuid',
        'createdAt',
        'updatedAt',
        'deletedAt',
        'predictionResult',
        'profitabilityResult',
        'configRecord',
        'allAncillaryCostsPercents',
        'totalPurchasePrice',
        'investorIncome',
        'parentInvestmentId',
        'agencyFeePercent',
        'parentInvestmentCase',
        'estate',
        'state',
        'investorCashflow',
        'approvedGeneralRenovationCost',
        'approvedKitchenCost',
        'approvedRenovationExtraCost',
    ]);

    return getClient('basecamp')
        .put(`/estates/${estateId}/prediction-results/${predictionId}/investments/${investmentId}`, body)
        .then(response => response.data);
};

export const getInvestments = async (params: IFindEstateInvestmentsParams): Promise<IInvestment[]> => {
    const { estateId, predictionId, investmentCaseTypes } = params;
    const parentPrediction = predictionId ? `/prediction-results/${predictionId}` : '';

    return getClient('basecamp')
        .get<IInvestment[]>(`/estates/${estateId}${parentPrediction}/investments`, { params: { investmentCaseTypes: investmentCaseTypes } })
        .then(response => response.data);
};

export const getInvestmentById = async (investmentId, params: { withReviewProcess: boolean; checkIfPredictionIsOptimal: boolean }): Promise<IInvestment> =>
    getClient('basecamp')
        .get<IInvestment>(`/investments/${investmentId}`, { params })
        .then(response => response.data);

export const copyInvestmentCase = async (investmentCaseId: number, copyInvestmentCaseType?: InvestmentCaseType): Promise<IInvestment> =>
    getClient('basecamp')
        .post<{
            investmentCase: IInvestment;
        }>(`/investments/${investmentCaseId}/copy`, { copyInvestmentCaseType: copyInvestmentCaseType ?? null })
        .then(response => response.data.investmentCase);

export const executeInterCompanyTransfer = async (investmentCaseId: number, purchasingEntityId: number, ictDate: Date): Promise<string> =>
    getClient('basecamp').post(`/investments/${investmentCaseId}/execute-inter-company-transfer`, { purchasingEntityId, ictDate });

export const startInterCompanyTransfer = async (investmentCaseId: number): Promise<IInvestment> =>
    getClient('basecamp')
        .post(`/investments/${investmentCaseId}/start-inter-company-transfer`)
        .then(response => response.data.investmentCase);

export const deleteInvestment = async (investmentId: number): Promise<boolean> =>
    getClient('basecamp')
        .delete(`/investments/${investmentId}`)
        .then(res => res.data);

export const getControllingViewData = async (estateId: number) => {
    return getClient('basecamp')
        .get(`/finance/controlling/${estateId}`)
        .then(res => res.data);
};

/*Portfolio routes*/
export const savePortfolio = async (request: ICreateUpdatePortfolioReq, portFolioId?: number): Promise<Portfolio> => {
    const method: 'patch' | 'post' = portFolioId ? 'patch' : 'post';
    const url = `/portfolio${portFolioId ? `/${portFolioId}` : ''}`;
    return getClient('basecamp')
        [method](url, request)
        .then(r => (r.data.portfolio ? Portfolio.create(r.data.portfolio) : null));
};

export const fetchOnePortfolio = async (portfolioId: number): Promise<Portfolio> =>
    getClient('basecamp')
        .get(`/portfolio/${portfolioId}`)
        .then(r => (r.data.portfolio ? Portfolio.create(r.data.portfolio) : null));

export const fetchPortfolios = async (params: Partial<IPortfolio>): Promise<Portfolio[]> =>
    getClient('basecamp')
        .get('/portfolio', { params })
        .then(r => Promise.all((r.data.portfolios as Portfolio[]).map(p => Portfolio.create(p))));

export const searchPortfolios = async (params: Partial<IPortfolio>): Promise<Pick<IPortfolio, 'portfolioId' | 'shortUuid'>[]> =>
    getClient('basecamp')
        .get('/portfolio/search', { params })
        .then(r => r.data.portfolios);

export const findPortfolioEstates = async (params: IPortfolioEstatesQuery): Promise<IEstate[]> =>
    getClient('basecamp')
        .get('/portfolio/estates', { params })
        .then(r => r.data.estates);

export const saveLoanOffer = async (portfolioId: number, loanOfferId: number | null, body: ISaveOfferParams): Promise<LoanOffer> => {
    const method: 'patch' | 'post' = loanOfferId ? 'patch' : 'post';
    const url = `/portfolio/${portfolioId}/offer${loanOfferId ? `/${loanOfferId}` : ''}`;
    return getClient('basecamp')
        [method](url, body)
        .then(r => (r.data.loanOffer ? new LoanOffer(r.data.loanOffer) : null));
};

export const acceptLoanOffer = async (portfolioId: number, loanOfferId: number, request: IAcceptOfferData): Promise<void> =>
    getClient('basecamp').post(`/portfolio/${portfolioId}/offer/${loanOfferId}/accept`, request);

export const fetchAllFinancingBanks = async (): Promise<IFinancingBank[]> =>
    getClient('basecamp')
        .get('/finance/banks')
        .then(r => r.data.financingBanks);

export const saveFinancingBank = async (financingBank: Partial<IFinancingBank>): Promise<IFinancingBank> =>
    getClient('basecamp')
        .post<{ financingBank: IFinancingBank }>('/finance/banks', financingBank)
        .then(r => r.data.financingBank);

export const deleteFinancingBank = async (financingBankId: number): Promise<void> => getClient('basecamp').delete(`/finance/banks/${financingBankId}`);

export const deleteLoanOffer = async (portfolioId: number, loanOfferId: number): Promise<void> =>
    getClient('basecamp').delete(`/portfolio/${portfolioId}/offer/${loanOfferId}`);

export const getLoanOffer = async (portfolioId: number, loanOfferId: number): Promise<LoanOffer> =>
    getClient('basecamp')
        .get<{ loanOffer: ILoanOffer }>(`/portfolio/${portfolioId}/offer/${loanOfferId}`)
        .then(r => new LoanOffer(r.data.loanOffer));

export const processPortfolioEvent = async (portfolioId: number, request: IUpdatePortfolioStatusParams): Promise<Portfolio> =>
    getClient('basecamp')
        .post(`/portfolio/${portfolioId}/process-event`, request)
        .then(r => (r.data.portfolio ? Portfolio.create(r.data.portfolio) : null));

export const generateGDrive = async (portfolioId: number, force: boolean): Promise<string> =>
    getClient('basecamp')
        .post(`/portfolio/${portfolioId}/documents`, { force })
        .then(r => r.data.GDriveLink);

export const getUsedJuniorDebtAmount = async (portfolioId: number): Promise<number> =>
    getClient('basecamp')
        .get(`/portfolio/${portfolioId}/junior-debt-amount`)
        .then(({ data }) => data.usedJuniorDebt);

// ========

export const fetchBankOwnerAccounts = async (): Promise<IExtendedBankOwnerAccount[]> =>
    getClient('basecamp')
        .get('/finance/bank-owner-accounts')
        .then(r => r.data.bankOwnerAccounts);

export const fetchBankOwnerAccount = async (bankOwnerAccountId: number): Promise<IExtendedBankOwnerAccount | undefined> =>
    getClient('basecamp')
        .get(`/finance/bank-owner-accounts/${bankOwnerAccountId}`)
        .then(r => r.data.bankOwnerAccount);

export const createBankOwnerAccount = async (req: IExtendedBankOwnerAccount): Promise<IExtendedBankOwnerAccount> =>
    getClient('basecamp')
        .post('/finance/bank-owner-accounts', req)
        .then(r => r.data.bankOwnerAccount);

export const updateBankOwnerAccount = async (bankOwnerAccountId: number, req: IExtendedBankOwnerAccount): Promise<IExtendedBankOwnerAccount> =>
    getClient('basecamp')
        .patch(`/finance/bank-owner-accounts/${bankOwnerAccountId}`, req)
        .then(r => r.data.bankOwnerAccount);

export const executeActionOnBankOwnerAccount = async (bankOwnerAccountId: number, action: BankOwnerAccountAction): Promise<IExtendedBankOwnerAccount> =>
    getClient('basecamp')
        .post(`/finance/bank-owner-accounts/${bankOwnerAccountId}/actions/${action}`)
        .then(r => r.data.bankOwnerAccount);

export const savePurchasingDrawdown = async (loanAccountId: number, data: IPurchasingPriceDrawdownData): Promise<void> =>
    getClient('basecamp').post(`/finance/loan-accounts/${loanAccountId}/drawdowns/purchasing-price`, data);

export const saveInvoicesDrawdown = async (loanAccountId: number, data: IInvoicesDrawdownData): Promise<void> =>
    getClient('basecamp').post(`/finance/loan-accounts/${loanAccountId}/drawdowns/invoices`, data);

export const saveCashDrawdown = async (loanAccountId: number, data: ICashDrawdownData): Promise<void> =>
    getClient('basecamp').post(`/finance/loan-accounts/${loanAccountId}/drawdowns/cash`, data);

export const deleteDrawdown = async (req: IFullDrawdownParams): Promise<void> =>
    getClient('basecamp').delete(`/finance/loan-accounts/${req.loanAccountId}/drawdowns/${req.loanDrawdownId}`);

export const fetchLoanAccount = async (loanAccountId: number, params?: Omit<IGetLoanAccountParams, 'loanAccountId'>): Promise<LoanAccount | undefined> => {
    return getClient('basecamp')
        .get<{ loanAccount: LoanAccount | undefined }>(`/finance/loan-accounts/${loanAccountId}`, { params })
        .then(({ data }) => (data.loanAccount ? LoanAccountFactory.factory(data.loanAccount) : undefined));
};

export const fetchLoanAccountSummary = async (loanAccountId: number): Promise<LoanAccountFieldsSummary> =>
    getClient('basecamp')
        .get(`/finance/loan-accounts/${loanAccountId}/summary`)
        .then(({ data }) => data);

export const fetchLoanAccounts = async (query: Partial<ILoanAccount>, expandOpts?: LoanAccountExpandOpts): Promise<LoanAccount[]> =>
    getClient('basecamp')
        .get<{ loanAccounts: ILoanAccount[] }>('/finance/loan-accounts', { params: { query, expandOpts } })
        .then(({ data }) => Promise.all(data.loanAccounts.map(loanAccount => LoanAccountFactory.factory(loanAccount))));

export const saveLoanAccount = async (loanAccountData: ILoanAccount): Promise<LoanAccount> =>
    getClient('basecamp')
        .post<{
            loanAccount: LoanAccount;
        }>('/finance/loan-accounts', loanAccountData)
        .then(({ data }) => LoanAccountFactory.factory(data.loanAccount));

export const fetchLoanDrawdowns = async (loanId: number, params?: Partial<ILoanDrawdown>): Promise<LoanDrawdown[]> =>
    getClient('basecamp')
        .get<{ drawdowns: LoanDrawdown[] }>(`/finance/loan-accounts/${loanId}/drawdowns`, { params })
        .then(({ data }) => data.drawdowns.map(d => new LoanDrawdown(d)));

export const prepareLoanDrawdown = async (req: IFullDrawdownParams) =>
    getClient('basecamp').post<{ loanDrawdown: LoanDrawdown }>(`/finance/loan-accounts/${req.loanAccountId}/drawdowns/${req.loanDrawdownId}/prepare`);

export const markDrawdownAsReceived = async (req: IFullDrawdownParams, data: IMarkAsReceivedParams): Promise<void> =>
    getClient('basecamp').post(`/finance/loan-accounts/${req.loanAccountId}/drawdowns/${req.loanDrawdownId}/receive`, data);

export const sendLoanDrawdown = async (req: IFullDrawdownParams & { message: string }): Promise<void> =>
    getClient('basecamp').post(`/finance/loan-accounts/${req.loanAccountId}/drawdowns/${req.loanDrawdownId}/send`, { message: req.message });

/*Bank Account Requests*/
export const getBankAccounts = async (
    query: Partial<GetBankAccountParams>,
    expandOpts?: BankAccountExpandOpts,
    pagination?: IPaginationFields,
): Promise<{ counter: number; bankAccounts: BankAccount[] }> =>
    getClient('basecamp')
        .get('/finance/bank-accounts', { params: { query, expandOpts, pagination } })
        .then(({ data }) => ({
            counter: data.count,
            bankAccounts: (data.bankAccounts as IBankAccount[]).map(b => new BankAccount(b)),
        }));

/*Bank Transactions*/
export const getBankTransactions = async (
    query: GetBankTransactionParams,
    expandOpts?: BankTransactionExpandOpts,
    pagination?: IPaginationFields,
): Promise<{
    bankTransactions: BankTransaction[];
    counter: number;
}> =>
    getClient('basecamp')
        .get('/finance/bank-transactions', { params: { query, expandOpts, pagination } })
        .then(({ data }) => ({
            counter: data.counter,
            bankTransactions: (data.bankTransactions as IBankTransaction[]).map(bt => new BankTransaction(bt)),
        }));

export const fetchBankAccountBalance = async (bankAccountId: number): Promise<number | null> =>
    getClient('basecamp')
        .get(`/finance/bank-accounts/${bankAccountId}/balance`)
        .then(({ data }) => data.balance);

export const removeBankTransaction = async (bankTransactionId: number): Promise<void> =>
    getClient('basecamp').delete(`/finance/bank-transactions/${bankTransactionId}`);

export const saveBankTransactions = async (bankTransaction: Partial<IBankTransaction>, additionalData: IAddBankTransactionData): Promise<BankTransaction> =>
    getClient('basecamp')
        .post('/finance/bank-transactions', {
            bankTransaction,
            additionalData,
        })
        .then(({ data }) => new BankTransaction(data.bankTransaction));

export const updateBankTransaction = async (params: {
    bankTransactionId: number;
    bankTransaction: Partial<IBankTransaction> & {
        estateId?: number;
    };
    deleteAssociatedRule?: boolean;
}) => {
    return getClient('basecamp')
        .patch(`/finance/bank-transactions/${params.bankTransactionId}`, params.bankTransaction, {
            params: {
                deleteAssociatedRule: params.deleteAssociatedRule,
            },
        })
        .then(({ data }) => new BankTransaction(data));
};

export const getBankTransaction = async (bankTransactionId: number, expandOpts?: BankTransactionExpandOpts): Promise<BankTransaction> =>
    getClient('basecamp')
        .get(`/finance/bank-transactions/${bankTransactionId}`, { params: { expandOpts } })
        .then(({ data }) => new BankTransaction(data.bankTransaction));

export const fetchLedgerAccount = async (query: Partial<IGeneralLedger>, expandOpts?: GeneralLedgerExpandOpts): Promise<GeneralLedger> =>
    getClient('basecamp')
        .get('/finance/general-ledger', { params: { ...query, expandOpts } })
        .then(({ data }) => LedgerFactory.factory(data.generalLedger));

export const fetchLedgerEntries = async (
    ledgerQuery: Partial<Pick<IGeneralLedger, 'generalLedgerId' | 'loanAccountId' | 'bankAccountId' | 'bookKeepingId' | 'generalLedgerType'>>,
    entriesQuery?: Partial<Pick<IBaseLedgerEntry, 'entryGroup' | 'entryGroupType' | 'generalLedgerId' | 'investorInfoId'>> & { year?: number },
    expandOpts?: LedgerEntryExpandOpts,
    pagination?: IPaginationFields,
): Promise<{ generalLedger: GeneralLedger | null }> => {
    const { data } = await getClient('basecamp').get('/finance/ledger-entries', { params: { ledgerQuery, entriesQuery, expandOpts, pagination } });
    return {
        generalLedger: data.generalLedger ? LedgerFactory.factory(data.generalLedger) : null,
    };
};

export const recalculateLedgerBalanceRecords = async (generalLedgerId: number): Promise<void> => {
    await getClient('basecamp').post(`/finance/general-ledger/${generalLedgerId}/recalculate-balance-records`);
};

export const fetchLedgerBalanceRecords = async (generalLedgerId: number, pagination?: IPaginationFields): Promise<BalanceRecord[]> =>
    getClient('basecamp')
        .get(`/finance/general-ledger/${generalLedgerId}/balance-records`, { params: { pagination } })
        .then(({ data }) => data.balanceRecords.map(e => new BalanceRecord(e)));

export const createLedgerEntry = async (generalLedgerId: number, data: Partial<Omit<ILedgerEntry, 'generalLedgerId'>>): Promise<void> => {
    return getClient('basecamp').post(`/finance/general-ledger/${generalLedgerId}/entries`, data);
};

export const correctLedgerEntry = async (generalLedgerId: number, ledgerEntryId: number): Promise<void> =>
    getClient('basecamp').post(`/finance/general-ledger/${generalLedgerId}/correct-entry`, { ledgerEntryId });

export const saveBankAccount = async (data: Partial<IBankAccount>): Promise<BankAccount> =>
    getClient('basecamp')
        .post('/finance/bank-accounts', data)
        .then(({ data }) => new BankAccount(data.bankAccount));

export const getBankAccount = async (bankAccountIdOrIban: number | string, expandOpts?: BankAccountExpandOpts): Promise<BankAccount> =>
    getClient('basecamp')
        .get(`/finance/bank-accounts/${bankAccountIdOrIban}`, { params: { expandOpts } })
        .then(({ data }) => new BankAccount(data.bankAccount));

export const getBankAccountIfExists = async (bankAccountIdOrIban: number | string, expandOpts?: BankAccountExpandOpts): Promise<BankAccount | null> =>
    getClient('basecamp')
        .get(`/finance/bank-accounts/${bankAccountIdOrIban}`, {
            params: { expandOpts },
            // include 404 status to skip response interceptor catch behavior
            validateStatus: status => (status >= 200 && status < 300) || status === 404,
        })
        .then(({ data }) => (data.bankAccount ? new BankAccount(data.bankAccount) : null));

export const getNamedBankAccount = async (name: string, expandOpts?: BankAccountExpandOpts): Promise<BankAccount> =>
    getClient('basecamp')
        .get(`/finance/named-bank-accounts/${name}`, { params: { expandOpts } })
        .then(({ data }) => new BankAccount(data.bankAccount));

export const deleteBankAccount = async (bankAccountId: number): Promise<void> => getClient('basecamp').delete(`/finance/bank-accounts/${bankAccountId}`);

export const deleteLoanAccount = async (loanAccountId: number): Promise<void> => getClient('basecamp').delete(`/finance/loan-accounts/${loanAccountId}`);

export const matchIbanToBank = async (params: IMatchIbanToBankParams): Promise<IMatchIbanToBankResponse> =>
    getClient('basecamp')
        .get('/finance/banks/match', { params })
        .then(({ data }) => data);

export const findBICForIBAN = async (iban: string, requestOpts?: { signal?: AbortSignal; skipErrorHandler?: boolean }): Promise<string | null> =>
    getClient('basecamp')
        .get('/finance/banks/bic', { params: { iban }, signal: requestOpts?.signal, skipErrorHandler: requestOpts?.skipErrorHandler } as AxiosRequestConfig)
        .then(({ data }) => data)
        .catch(err => {
            if (!axios.isCancel(err)) {
                throw err;
            }
        });

export const saveAccountingInfo = async (req: IAccounting): Promise<IAccounting> =>
    getClient('basecamp')
        .post('/accounting', req)
        .then(({ data }) => data.accounting);

export const getAccountingInfo = async (params: Partial<IAccounting> & { name?: string }): Promise<IAccounting[]> =>
    getClient('basecamp')
        .get('/accounting', { params })
        .then(({ data }) => data.accounting);

export const getOneAccountingRecord = async (accountingId: number): Promise<IAccounting> =>
    getClient('basecamp')
        .get(`/accounting/${accountingId}`)
        .then(({ data }) => data.accounting);

export const generateInspectionSheet = async (portfolioId: number): Promise<string> =>
    getClient('basecamp')
        .post(`/portfolio/${portfolioId}/inspection-sheet`)
        .then(r => r.data.inspectionSheetLink);

//@ts-ignore
export const exportPaymentOrder = async (paymentOrder: IPaymentOrder, estate: IEstate): Promise<Buffer> => {
    const { data } = await getClient('basecamp').get(`/finance/payment-orders/${paymentOrder.paymentOrderId}/`, {
        headers: { Accept: 'text/xml' },
        responseType: 'blob',
    });

    if (data.size === 0) {
        showErrorMessage({ message: 'Unknown error while downloading payment-order xml, please try again later' });
        return;
    }

    const paymentOrderName = new PaymentOrder(paymentOrder).getDefaultFileName(estate);
    const url = window.URL.createObjectURL(new Blob([data]));
    openDownloadLink(url, `${paymentOrderName}.xml`);
};

export const recalculateRentBalanceForInvestor = async (investorInfoId: number) =>
    getClient('propx')
        .recalculateRentBalanceForInvestorInfo({ investorInfoId })
        .then(data => data.investorInfo);

export const removeRentableUnitCase = async (rentableUnitCaseId: number): Promise<void> =>
    getClient('basecamp').delete(`/rentable-unit-case/${rentableUnitCaseId}`);

export const maintenanceSeedTransactions = async (estateId: number): Promise<void> => {
    return getClient('basecampMaintenance').post(`/maintenance/estates/${estateId}/seed-transactions`);
};

export const maintenanceSeedTransactionRules = async (estateId: number): Promise<void> => {
    return getClient('basecampMaintenance').post(`/maintenance/estates/${estateId}/seed-transaction-rules`);
};

export const generateInvestorsLedgerStatements = async (month: Date, investorInfoId: number): Promise<void> => {
    await getClient('basecamp').post('/finance/investor-ledger-statements', { month: extractDate(month), investorInfoId });
};

export const getInvestorLedgerStatements = async (investorInfoId: number, year?: number): Promise<InvestorLedgerStatement[]> => {
    return getClient('basecamp')
        .get<IInvestorLedgerStatement[]>('/finance/investor-ledger-statements', { params: { year, investorInfoId } })
        .then(({ data }) => data.map(s => new InvestorLedgerStatement(s)));
};
